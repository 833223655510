import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { take } from 'rxjs/internal/operators/take';
import { Observable, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { CoreService } from '../../../core/core.service';
import { registrationInfo, User } from 'src/app/interfaces/interfaces';
// import { UploadComponent } from 'src/app/upload/upload.component';
import { UploadCanvasComponent } from 'src/app/components/upload-canvas/upload-canvas.component';
import { ApiClient } from 'src/app/services/api-client.service';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// Error when the parent is invalid
// class CrossFieldErrorMatcher implements ErrorStateMatcher {
//   isErrorState(control, form): boolean {
//     // console.log(form.form.controls['passwordGroup']);
//     return control.touched && form.form.controls['passwordGroup'].invalid;
//   }
// }

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @ViewChild('textarea') textarea: ElementRef;
  charCount: number = 0
  maxChars: number = 200
  maxLines: number = 3
  maxFileSizeMB = 25 // MB
  maxFileSize = this.maxFileSizeMB * 1024 * 1024 // bytes

  form: UntypedFormGroup;
  // errorMatcher = new CrossFieldErrorMatcher();
  fc: any

  today = new Date()
  startDate = new Date(1996, 0, 1);
  minDate: Date
  maxDate: Date
  loading = false;
  submissionLoading = false

  showPass:boolean = false
  serverMessage: string;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  registrationMessage: string

  constructor(private afAuth: AngularFireAuth, private afStorage: AngularFireStorage,
    public auth: AuthenticationService, private fns: AngularFireFunctions, private fb: UntypedFormBuilder,
    public afs: AngularFirestore, public dialog: MatDialog, private core: CoreService,
    public apiClient: ApiClient, private activatedRoute: ActivatedRoute, private router: Router) {
      // console.log(this.activatedRoute.snapshot.queryParamMap.get("invitation"));
      
    }

  form0: UntypedFormGroup;
  form1: UntypedFormGroup;
  form2: UntypedFormGroup;
  form3: UntypedFormGroup;
  form4: UntypedFormGroup;
  form5: UntypedFormGroup;

  ngOnInit() {
    this.form0 = this.fb.group({
      invitation: [this.activatedRoute.snapshot.queryParamMap.get("invitation"), [
        Validators.required,Validators.minLength(6)
      ]]
    })
    this.form1 = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        this.validateEmail.bind(this)]
      ],
      password: ['', [
        Validators.minLength(8),
        Validators.required,
        // Validators.pattern(this.core.regex.passwords)
      ]],
    });
    this.form2 = this.fb.group({
      userName: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(16),
        Validators.pattern(this.core.regex.names),
        this.validateUsername.bind(this)]
      ],
      couple: [null, [Validators.required]],
      location: ['', [Validators.required]],
    });
    this.form3 = this.fb.group({
      sex: ['', [Validators.required]],
      orientation: ['', [Validators.required]],
      birthdate: ['', [Validators.required]]
    });
    this.form4 = this.fb.group({
      sex2: ['', [Validators.required]],
      orientation2: ['', [Validators.required]],
      birthdate2: ['', [Validators.required]]
    });
    this.form5 = this.fb.group({
      photoInput: ['', [Validators.required]],
      title: ['', [Validators.maxLength(this.maxChars)]],

      tos: [false, [Validators.requiredTrue]],
      avatar: [{value:true, disabled:true}, [Validators.requiredTrue]],
    });

    this.dateValidation(this.today)
  }

  // Returns min and max datepicker dates
  dateValidation(td: Date) {
    const y = td.getFullYear()
    const m = td.getMonth()
    const d = td.getDate()
    this.minDate = new Date(y - 100, m, d)
    this.maxDate = new Date(y - 18, m, d)
  }

  invitationValid:boolean = null
  checkInvitation(stepper: MatStepper) {   
    console.log("Stepper Index:", stepper.selectedIndex);

    this.loading = true
    const invitation:string = this.form0.get('invitation').value

    // test registration flow
    if (invitation == "penis1" && !environment.production) {
      this.invitationValid = true
      console.warn("INVITATION FOR TESTING PURPOSES ONLY")
      setTimeout(() => {
        stepper.next()
      }, 300);      
      this.loading = false
      // @ts-ignore
      umami.track(`Invitation !! TEST !!`, {step: "invitation", ref: invitation})
      return
    }

    this.apiClient.invitationStatus(invitation)
    .subscribe(
      res => {
        if (res.invitationStatus == "VALID") {
          this.invitationValid = true
          this.loading = false
          // show the green tick before moving too fast to next step
          setTimeout(() => {
            stepper.next()
          }, 300);
        } else if (res.invitationStatus == "INVALID") {
          this.invitationValid = false
          this.form0.controls['invitation'].setErrors({ invalid: true })
        } else {
          this.invitationValid = false
          this.form0.controls['invitation'].setErrors({ used: true })
        }
        this.loading = false
        // @ts-ignore
        umami.track(`Invitation ${res.invitationStatus}`, {step: "invitation", ref: invitation, status: res.invitationStatus})
        console.log('Invitation number:', invitation, "\nStatus:", res.invitationStatus);
      }
    )
  }

  stepperNext(stepper: MatStepper,) {
    console.log("Stepper Index:", stepper.selectedIndex);
    stepper.next()
    // @ts-ignore
    umami.track(`Registration ${stepper.selectedIndex}`, {step: stepper.selectedIndex})
  }

  validationTimer: any

  // checks already registered users
  // pending users are checked at createUser cloud function (to save one extra read per query)
  validateUsername(uname: AbstractControl) {
    let name = uname.value.toLocaleLowerCase().trim()
    name ? console.log(name) : null
    
    clearTimeout(this.validationTimer)
    this.loading = false
    // console.log("name length:", name.length);
    // console.log("errors", this.form2.controls['userName'].hasError('pattern'));
    
    if ((name.length >= 3)
    && (name.length <= 16)
    // && !(this.form2.controls['userName'].hasError('pattern'))
    ) {
    // console.log("errorrs", this.form2.controls['userName'].errors);
      
      // console.log(this.form2.controls['userName']);
      this.loading = true

      this.validationTimer = setTimeout(() => {
        
        this.apiClient.usernameAvailable(name)
          .subscribe((available: boolean) => {
            if (available) {
              this.loading = false
              return null
            } else {
              this.form2.controls['userName'].markAsTouched()
              this.loading = false
              return this.form2.controls['userName'].setErrors(
                { nameTaken: true }
              )
            }
          },
          // err => {
          //   this.loading = false
          //   if (err == "auth/network-request-failed") {
          //     return this.form2.setErrors({ offline: true })
          //   }
          // }
        )
      }, 600);
    }
  }

  validateEmail(email: AbstractControl) {
    let mail = email.value
    mail ? console.log(mail) : null

    clearTimeout(this.validationTimer)
    this.loading = false

    if (
      (mail.length >= 5)
      && !(this.form1.controls['email'].hasError('email'))
      // && !(this.form1.controls['email'].hasError('pattern'))
    ) {
      console.log(this.form1.controls['email'].errors);
      this.loading = true

      this.validationTimer = setTimeout(() => {
        // Cross-check email with firebase registered users        
        this.afAuth.fetchSignInMethodsForEmail(mail)
        .then(res => {
          console.log(res)
          if (res.length) {
            this.form1.controls['email'].markAsTouched()
            this.loading = false
            return this.form1.controls['email'].setErrors(
              { emailExists: true }
            )
          } else {
            this.loading = false
            return null
          }
        })
        .catch(err => {
          this.loading = false
          if (err.code == "auth/network-request-failed") {
            this.form1.setErrors({ offline: true })
          }
        })
      }, 600);
    }
  }

  isCouple() {
    console.log(this.fc.couple.value);

    if (this.fc.couple.value) {
      this.fc['sex2'].setValidators([Validators.required]);
      this.fc['birthdate2'].setValidators([Validators.required]);
      this.fc['sex2'].updateValueAndValidity();
      this.fc['birthdate2'].updateValueAndValidity();
    } else {
      this.fc['sex2'].clearValidators();
      this.fc['birthdate2'].clearValidators();
      this.fc['sex2'].updateValueAndValidity();
      this.fc['birthdate2'].updateValueAndValidity();
    }
  }

  get invitation() {
    return this.form0.get('invitation').value;
  }
  get email() {
    return this.form1.get('email').value;
  }
  get password() {
    return this.form1.get('password').value;
  }
  get couple() {
    return this.form2.get('couple').value
  }
  get userName() {
    return this.form2.get('userName').value.toLowerCase();
  }
  get location() {
    return this.form2.get('location').value;
  }
  get sex() {
    return this.form3.get('sex').value;
  }
  get orientation() {
    return this.form3.get('orientation').value;
  }
  get birthdate() {
    return this.core.dateToLocaleDateString(this.form3.get('birthdate').value)
  }
  get sex2() {
    return this.form4.get('sex2').value;
  }
  get orientation2() {
    return this.form4.get('orientation2').value;
  }
  get birthdate2() {
    return this.core.dateToLocaleDateString(this.form4.get('birthdate2').value)
  }
  get title() {
    return this.form5.get('title').value;
  }

  stepBack(stepper: MatStepper){
    if (stepper.selectedIndex === 0) {
      // If invited, go to home (to see what the site's about), otherwise go to login (where you probably came from)
      if (this.activatedRoute.snapshot.queryParamMap.get("invitation")) this.router.navigate(['/'])
      else this.router.navigate(['/login'], { skipLocationChange: true}) // skipLocationChange to prevent back button from register/login loop
    } else {
      stepper.previous();
    }
  }

  // Submit form
  async onSubmit() {

    // // Check if all forms are valid (stepper already checks each step before allowing you to continue, but just in case)
    // let allFormsValid = this.form1.valid && this.form2.valid && this.form3.valid && this.form5.valid
    // if (this.couple) allFormsValid = allFormsValid && this.form4.valid
    // if (!allFormsValid) return

    this.submissionLoading = true;
    this.registrationMessage = "Εξέταση στοιχείων"

    const userData = {
      email: this.email,
      displayName: this.userName,
      password: this.password,
      // emailVerified: false, // is default
      // disabled: false, // is default
    }

    console.log("0. Initiated - Creating User:", userData);

    // 1. Create firebase account
    const user = (await this.afAuth.createUserWithEmailAndPassword(this.email, this.password)).user
    // Store IdToken for Upload Photo API call below - no need anymore // TODO: delete it
    // localStorage.setItem('user', JSON.stringify({idToken: await user.getIdToken()}))
    console.log("1. USER", user);
    user.updateProfile({displayName: this.userName})
    // console.log("USER", user); // displayName already exists in previous log, no clue how.

    // 2. Upload photo
    const attachment = await this.uploadCanvas.uploadImage()
    console.log("2. Photo ATTACHMENT", attachment);
    user.updateProfile({photoURL: attachment})

    // 3. Send user_request to server
    const registrationInfo:registrationInfo = {
      username: this.userName,
      location: this.location,
      sex: this.couple ? "c" : this.sex,
      birthdate: this.birthdate,
      orientation: this.orientation,
      sex1: this.couple ? this.sex : null,
      sex2: this.couple ? this.sex2 : null,
      orientation2: this.couple ? this.orientation2 : null,
      birthdate2: this.couple ? this.birthdate2 : null,
      info: "",
      postAttachment: attachment,
      postTitle: this.title,
      invitationRef: this.invitation
    }

    console.log(registrationInfo);

    // TODO: if image <600kb, upload original - WEB IMAGE NOTIFICATION!!! - save original image size.
    // submissionLoading text over "submit" - prog bar with steps
    // redirect to email verification page - show in user_request
    // redirect - show notification (wait for authorization)
    // send approval and reset pass to email (too much, maybe it's ok to use one use email...)

    this.apiClient.registerUser(registrationInfo)
    .subscribe(
      async (res) => {
        if (!res || !res.register) {
          this.registrationMessage = "Υπήρξε κάποιο πρόβλημα. Δοκιμάστε ξανά σε λίγα λεπτά ή ειδοποιήστε μας"
          this.auth.flushUserLocally()
          user.delete().then(res => console.log("user dropped from Auth"))
          // @ts-ignore
          umami.track('Registration ERROR', {registrationInfo: registrationInfo, response: res})
          return
        }
        console.log("User request sent", res);
        await user.sendEmailVerification()
        this.core.snackBarNotification("Σας έχει σταλεί email ενεργοποίησης.", ['/login'], 10000)
        this.submissionLoading = false;
        // @ts-ignore
        umami.track(`Registration Submitted @${this.userName}`, {step: "submitted", user: this.userName})
      }
    )

  }

  preview() {
    console.log({
      // invitation: this.invitation,
      name: this.userName,
      username: this.userName,
      // couple: this.couple,
      location: this.location,
      sex: this.couple ? "c" : this.sex,
      birthdate: this.birthdate,
      orientation: this.orientation,
      sex1: this.couple ? this.sex : null,
      orientation2: this.couple ? this.orientation2 : null,
      birthdate2: this.couple ? this.birthdate2 : null,
      info: "",
      postAttachment: "N/A in preview",
      postTitle: this.title
    })
  }

  imageLarge = false
  imageWrongExt = false
  imgURI: any = ''

  @ViewChild(UploadCanvasComponent) uploadCanvas:UploadCanvasComponent
  // When new image is selected by user, this runs
  async onFileSelected(event) {
    // console.log(event);
    if (event.target.files[0]) {
      const file = event.target.files[0]
      const ext = file.name.split('.').pop().toLowerCase()
      const validExt = ['jpeg', 'jpg', 'png']
      console.log(file.name);
      console.log(`File size: ${file.size / 1000 + ' kB'}`);

      this.imageLarge = false
      this.imageWrongExt = false

      if (!validExt.includes(ext)) {
        this.imageWrongExt = true
        this.form5.controls['photoInput'].setErrors({ 'incorrect': true })
      } else if (file.size > this.maxFileSize) {
        this.imageLarge = true
        this.form5.controls['photoInput'].setErrors({ 'incorrect': true })
      }

      // Preview image
      if (file && validExt.includes(ext)) {
        this.imgURI = await this.uploadCanvas.setImage(event)
      } else {
        this.imgURI = ''
      }
    }
  }

  // Limit bio max lines and consecutive newlines
  lineBreakLimits(event) {
    let title: string = this.form5.get('title').value
    // Allow only two consecutive newlines
    title = title.replace(/(\r\n|\r|\n)\1+/gm, "\n\n")
    // Limit text lines to maxLines (25)
    const lines: string[] = title.split(/\r\n|\r|\n/);
    // Update text value
    this.form5.get('title').setValue(lines.slice(0, this.maxLines).join("\n"));
    // Count characters
    this.charCount = title.length
    // --- Adjust textarea height ---
    let element = this.textarea.nativeElement
    element.style.height = 0
    element.style.height = element.scrollHeight - 4 + "px"
  }

  avatarNotification() {
    this.core.snackBarNotification(
      "H πρώτη σας ανάρτηση γίνεται αυτόματα η φωτογραφία προφίλ σας, μέχρι να την αλλάξετε.",
      null,
      5000
    )
  }

  visibilityNotification(key) {
    if (key != 'public') {
      this.core.snackBarNotification(
        "Η φωτογραφια προφίλ σας δεν μπορεί να έχει περιορισμένη ορατότητα.",
        null,
        3000
      )
    }
  }

}

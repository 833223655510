<div *ngIf="!core.hasTouchScreen" class="controls">
  <button [style.display]="scrolled" class="left" mat-icon-button (click)="scrollTo('left')">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button class="right" mat-icon-button (click)="scrollTo('right')">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<!-- <ng-container *ngIf="tag == 'top' then tags else posts"></ng-container> -->

<!-- PLACEHOLDER -->
<section #horizontal *ngIf="tags==null && posts==null" class="horizontal">
  <div class="tile animated-placeholder" *ngFor="let n of [1,2,3,4,5]">
    <img src='./assets/hashtag.svg' class="scale">
  </div>
</section>
<!-- /PLACEHOLDER -->

<section #horizontal *ngIf="tag == 'top'" class="horizontal">
  <div class="tile" *ngFor="let tag of tags" [routerLink]="['/tag/', tag.name]">
    <img [src]="tag.avatar[320]" class="animated-placeholder" (load)="removePlaceholder($event)" 
      srcset="{{tag.avatar[320]}} 320w, {{tag.avatar[640]}} 640w" 
      sizes="140px" onError="this.onerror=null;this.srcset='./assets/hashtag.svg'">
    <div class="text">
      <span>#{{tag.name}}</span>
    </div>
  </div>
</section>

<section #horizontal *ngIf="tag != 'top'" class="horizontal">
  <div class="tile" *ngFor="let post of posts; index as idx" [routerLink]='["/p/", post.ref ]' [state]='{
    data: posts,
    index: idx,
    query: post.ref,
    endReached: "true"
    }'>
    <img [src]="post.image[320]" class="animated-placeholder" (load)="removePlaceholder($event)"
      srcset="{{post.image[320]}} 320w, {{post.image[640]}} 640w" 
      sizes="140px" (error)="core.onImgError($event)">
    <div class="text">
      <span class="user">@{{post.author.username}}</span>
    </div>
  </div>
  <div>
    <!-- <div class="tile last-post" [style.background-image]="'url(' + posts[posts.length - 1].image[320] + ')'"> -->
    <div class="tile last-post" [routerLink]="['/tag/', tag.name]">
      <div>
        <mat-icon>chevron_right</mat-icon>
        <div>#{{tag.name}}</div>
      </div>
    </div>
  </div>
</section>

<mat-menu #menu="matMenu" xPosition="before">
    <div >
        <div *ngIf="targetType == 'post' && mentioned">
            <button mat-menu-item (click)="approveMention()">
                <mat-icon>portrait_outlined</mat-icon>
                <span> {{this.mentioned == "approved" ? 'Αφαίρεση απο το προφίλ μου' : 'Εμφάνιση στο προφίλ μου'}}</span>
            </button>
            <mat-divider></mat-divider>
        </div>

        <button mat-menu-item (click)="copy" [cdkCopyToClipboard]="url">
            <mat-icon>link</mat-icon>
            <span>Αντιγραφή συνδέσμου</span>
        </button>
        
        <!-- <button mat-menu-item *ngIf="core.hasTouchScreen" (click)="share()">
            <mat-icon>share</mat-icon>
            <span>Share with</span>
        </button> -->

        <button *ngIf="targetType == 'user' && !self && auth.userObject.username" class="warn" mat-menu-item (click)="blockUnblock()">
            <mat-icon>block</mat-icon>
            <span> {{!this.blocked ? 'Μπλοκάριμσα' : 'Ξεμπλοκάρισμα'}}</span>
        </button>
        <button *ngIf="!self" mat-menu-item class="warn" (click)="report()">
            <mat-icon class="material-icons-outlined">flag</mat-icon>
            <span>Αναφορά</span>
        </button>


        <div *ngIf="targetType == 'post' && auth.userObject && (self || auth.userObject.role == 'admin') && targetRef">
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="updatePost(targetRef)">
                <mat-icon class="material-icons-outlined">edit_outlined</mat-icon>
                <span>Επεξεργασία</span>
            </button>
            <button mat-menu-item *ngIf="auth.userObject.avatar != targetRef" (click)="core.updateAvatar(targetRef)">
                <mat-icon class="material-icons-outlined">add_a_photo</mat-icon>
                <span>Να γίνει φωτογραφία προφίλ</span>
            </button>
            <button mat-menu-item class="warn" (click)="deletePost(targetRef)">
                <mat-icon class="material-icons-outlined" style="margin-bottom: 3px">delete_forever_outlined</mat-icon>
                <span>Διαγραφή</span>
            </button>
        </div>
    </div>
</mat-menu>
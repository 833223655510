<div mat-dialog-content>
    <h2 [ngSwitch]="data.targetType">
        Αναφορά 
        <span *ngSwitchCase="'post'">
            Φωτογραφίας
        </span>
        <span *ngSwitchCase="'comment'">
            Σχολίου
        </span>
        <span *ngSwitchCase="'user'">
            Μέλους
        </span>
        <span *ngSwitchCase="'bug'">
            Προβλήματος / Πρόταση
        </span>
    </h2>
    <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <mat-radio-group class="report" formControlName="reason" required>
                    <mat-radio-button class="report-item" *ngFor="let reason of reasons[data.targetType]" [value]="reason">
                        {{reason}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="firstFormGroup.touched && firstFormGroup.invalid">Επιλέξτε ένα απο τα παραπάνω</mat-error>
                <div class="buttons">
                    <button mat-stroked-button (click)="onNoClick()">Άκυρο</button>
                    <button mat-stroked-button matStepperNext (click)="firstFormGroup.markAllAsTouched()">Επόμενο</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <mat-form-field hideRequiredMarker>
                    <mat-label>Λεπτομέρειες</mat-label>
                    <textarea #textarea matInput formControlName="description" cdkTextareaAutosize
                        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="3" type="text" autocomplete="off"
                        required></textarea>
                    <mat-error *ngIf="secondFormGroup.controls['description'].hasError('required')">Η περιγραφή δεν μπορεί να είναι κενή</mat-error>
                    <mat-error *ngIf="secondFormGroup.controls['description'].hasError('maxlength')">Η περιγραφή μπορεί να είναι έως 1000 χαρακτήρες</mat-error>
                </mat-form-field>
                <!-- <mat-form-field *ngIf="!data.uid">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" autocomplete="off" [required]="!data.uid">
                </mat-form-field> -->
                <div class="buttons">
                    <button mat-button matStepperPrevious>Πίσω</button>
                    <button mat-flat-button color="accent" [disabled]="secondFormGroup.invalid"
                        (click)="submitReport()">Αποστολή</button>
                </div>
            </form>
        </mat-step>
    </mat-horizontal-stepper>
</div>
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationStart, RouteReuseStrategy } from '@angular/router';
import { CoreService } from '../../core/core.service';
import { User, Profile, Post } from '../../interfaces/interfaces'
import { Location } from '@angular/common';
import { OnAttach, OnDetach } from '../../app-route-strategy';
import { first, retry } from 'rxjs/operators';
import { ApiClient } from '../../services/api-client.service';
import {AuthenticationService} from '../../services/authentication.service';
import { MatDrawer } from '@angular/material/sidenav';
import { MatMenuTrigger } from '@angular/material/menu';
import { A2hsService } from 'src/app/services/pwa-install.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements AfterViewInit, OnAttach, OnDetach {

  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
    this.setScrollListener()
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.changeDetectorRef.detectChanges()
  }
  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.scrollDisabled = true
    this.setScrollListener()
    this.router.routeReuseStrategy.shouldReuseRoute = this.mainRouteReuse
    this.drawer && this.drawer.opened ? this.toggleDrawer() : null
    this.trigger ? this.trigger.closeMenu() : null
    this.changeDetectorRef.detectChanges()
  }

  constructor(private activatedRoute: ActivatedRoute, public core: CoreService, private router: Router,
    public auth: AuthenticationService, public location: Location, private changeDetectorRef: ChangeDetectorRef,
    public apiClient: ApiClient, public a2hs: A2hsService) {       
      this.mainRouteReuse = this.router.routeReuseStrategy.shouldReuseRoute
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  @ViewChild('tabSection') tabSection: ElementRef;
  @ViewChild('bio') bio: ElementRef;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  
  sticky = false
  user: User = null
  pageSize = 12
  username: string
  own: boolean = false
  following: boolean
  blocked: boolean = false
  navMenu: "options" | "moreOptions" = "options"
  
  mainRouteReuse: any
  bioLong = false

  scrollDisabled: boolean = false
  mainScrollDisabled: boolean = false
  taggedScrollDisabled: boolean = true
  taggedVisited = false
  drawerOpen = false
  loading: boolean = true

  onWindowScroll: any

  is404 = false

  currentApplicationVersion = environment.appVersion;

  async ngAfterViewInit() {
    // We have to bind(this) on another function for Listener attach/detach to work
    this.onWindowScroll = this.windowScrollFunction.bind(this)

    // No need to unsubscribe from ActivatedRoute observables (from docs)    
    this.activatedRoute.params
    .subscribe(async res => {
      this.loading = true
      this.username = res.username.toLowerCase()
      this.apiClient.getProfile(this.username, this.pageSize, 0)
      .subscribe({
        next : (user) => {
          this.user = user
          // User available
          if (user.posts.data) {
            this.setScrollListener()
            setTimeout(() => {
              this.bioHeightCheck()
            });
          } 
          // User blocked/deactivated (posts.data = null)
          else {
            this.apiClient.myBlocks()
            .subscribe(blocks => {
              // if username in blocked users array
              console.log(blocks);
              if (blocks.find(o => o.username == user.username)) {
                this.blocked = true
              }
            })
          }
          this.loading = false
        },
        error: (error) => {
          console.error("404 - user does not exist");
          this.loading = false
          this.is404 = true
        },
      }
      )
    })
  }

  setScrollListener() {
    if (!this.scrollDisabled) {    
      window.addEventListener("scroll", this.onWindowScroll)
    } else {     
      window.removeEventListener("scroll", this.onWindowScroll)
    }
  }

  windowScrollFunction(e) {        
    if (this.tabSection.nativeElement.getBoundingClientRect().top < 60) {
      this.gridHeaderSticky(true)
    } else {
      this.gridHeaderSticky(false)
    }
  }

  gridHeaderSticky(value:boolean) {
    let element = document.getElementsByTagName('mat-tab-header');
    if (value) {
      element[0].classList.add('sticky-tab-header');
      this.sticky = true
    } else {
      element[0].classList.remove('sticky-tab-header');
      this.sticky = false
    }
  }

  bioHeightCheck() {
    this.bio.nativeElement.offsetHeight > 112 ? this.bioLong = true : this.bioLong = false
    console.log(this.bio.nativeElement.offsetHeight );
  }

  age(person) {
    if (person == 1) return this.user.profile.birthdate
    if (person == 2) return this.user.profile.birthdate2
  }

  orientation(person) {
    if (person == 1) return this.user.profile.orientation
    if (person == 2) return this.user.profile.orientation2
  }

  sex(person) {
    if (person == 1) return this.user.profile.sex1 ? this.user.profile.sex1 : this.user.profile.sex
    if (person == 2) return this.user.profile.sex2
  }

  pushState(username, request) {
    console.log(username, request);
    this.router.navigate([this.router.url, request],
      {
        state: { username: username, request: request }
      })
  }

  followUnfollow(username) {
    // Guest returns snackbar notification
    if (!this.auth.userObject.username) return this.core.snackBarNotification("Δεν μπορείτε να ακολουθήσετε ως επισκέπτης.")
    // Follow/Unfollow for members
    this.apiClient.followUnfollow(username)
      .subscribe((res) => {
        console.log(`Following: ${res.data.follow}`);
        if (res) {
          // Update "Follow" button
          this.user.followingThem = res.data.follow
          // If user follows, increase followers count, else decrease
          res.data.follow ? this.user.followers.count++ : this.user.followers.count--
        }
      })
  }

  tabChange(index: number) {
    if (index === 1) {
      this.taggedVisited = true
      this.taggedScrollDisabled = false
      this.mainScrollDisabled = true
    } else {
      this.taggedScrollDisabled = true
      this.mainScrollDisabled = false
    }
  }

  createInvitation() {
    this.apiClient.createInvitation()
    .subscribe(res => {
      if (res) {
        window.alert(`Ο κωδικός πρόσκλησης είναι: ${res.ref}`)
        console.log(res);
      } else {
        window.alert("Δεν έχετε ακόμα δικαίωμα πρόσκλησης μελών.")
      }
      
    })
  }

  toggleDrawer() {
    if (!this.drawer.opened) {
      this.navMenu = 'options'
      this.drawer.open().then()
      this.drawerOpen = true
      // change icon on close (even on backdrop click)
      this.drawer.closedStart
      .pipe(
        first()
      )
      .subscribe(() => this.drawerOpen = false)
    } else {
      this.drawer.close().then()
    }
  }

  unblock() {
    this.apiClient.blockUnblock(this.user.username)
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.onWindowScroll)
  }
}

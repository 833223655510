<div class="header">
  <mat-icon class="material-icons-outlined" (click)="location.back()">close</mat-icon>
</div>
<section class="qr-code">
  <qr-code #qrCode
    [qrData]="qrData"
    [shape]="shape"
    [width]="width"
    [height]="height"
    [margin]="margin"
    [imageUrl]="imageUrl"
    [dotsType]="dotsType"
    [dotsColor]="dotsColor"
    [dotsGradient]="dotsGradient"
    [dotsStartColor]="dotsStartColor"
    [dotsEndColor]="dotsEndColor"
    [dotsGradientType]="dotsGradientType"
    [dotsGradientRotation]="dotsGradientRotation"
    [cornerSquareType]="cornerSquareType"
    [cornerSquareColor]="cornerSquareColor"
    [cornerSquareGradient]="cornerSquareGradient"
    [cornerSquareStartColor]="cornerSquareStartColor"
    [cornerSquareEndColor]="cornerSquareEndColor"
    [cornerSquareGradientType]="cornerSquareGradientType"
    [cornerSquareGradientRotation]="cornerSquareGradientRotation"
    [cornerDotType]="cornerDotType"
    [cornerDotColor]="cornerDotColor"
    [cornerDotGradient]="cornerDotGradient"
    [cornerDotStartColor]="cornerDotStartColor"
    [cornerDotEndColor]="cornerDotEndColor"
    [cornerDotGradientType]="cornerDotGradientType"
    [cornerDotGradientRotation]="cornerDotGradientRotation"
    [backgroundColor]="backgroundColor"
    [backgroundGradient]="backgroundGradient"
    [backgroundStartColor]="backgroundStartColor"
    [backgroundEndColor]="backgroundEndColor"
    [backgroundGradientType]="backgroundGradientType"
    [backgroundGradientRotation]="cornerDotGradientRotation"
    [imageSize]="imageSize"
    [imageMargin]="imageMargin"
    [hideImageBackgroundDots]="hideImageBackgroundDots"
    [errorCorrectionLevel]="errorCorrectionLevel"
  ></qr-code>
  <div class="qr-title">
    <span>{{ auth.qrData ? auth.qrData.title : auth.userObject.username }}</span>
  </div>
  <div class="qr-copy" (click)="copy(qrData)">      
    <mat-icon class="material-icons-outlined">link</mat-icon>
    <p>Αντιγραφή συνδέσμου</p>
  </div>
</section>
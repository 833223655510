<section>
  <div class="top">
    <h3>Προσκαλέστε τους sex-positive φίλους σας!</h3>
  </div>
  
  <div class="bottom">
    <p class="inv-requirements">
      Δικαίωμα πρόσκλησης αποκτούν τα μέλη που είναι ενεργά στην κοινότητα
      για κάποιο διάστημα και έχουν θετικές αλληλεπιδράσεις με τα υπόλοιπα μέλη.
    </p>
    <p>Σας απομένουν <strong>{{auth.userObject.availableInvitations | number: '1.'}}</strong> προσκλήσεις</p>
    <button mat-flat-button color="accent" [disabled]="!auth.userObject.availableInvitations"
      (click)="createInvitation()">Νεα Πρόσκληση</button>
  </div>
  
  <mat-spinner *ngIf="loading" color="accent" [diameter]="30"></mat-spinner>
  
  <div *ngIf="invitations && invitations.count && !loading">
    <table #table mat-table [dataSource]="invitations.data">
  
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
      <!-- Index Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> # </th>
        <td mat-cell *matCellDef="let invitation; let i = index; let c = count;"> {{ c - i }} </td>
      </ng-container>
  
      <!-- Ref Column -->
      <ng-container matColumnDef="ref">
        <th mat-header-cell *matHeaderCellDef> Πρόσκληση </th>
        <td mat-cell *matCellDef="let invitation" [style.text-decoration]="invitation.invitee ? 'line-through' : 'auto'"
          (click)="!invitation.invitee && showQRCode(invitation.ref)">
          {{invitation.ref}}
          <!-- <mat-icon class="material-icons-outlined">qr_code_outlined</mat-icon> -->
        </td>
      </ng-container>
  
      <!-- Availability Column -->
      <ng-container matColumnDef="used">
        <th mat-header-cell *matHeaderCellDef> Κατάσταση </th>
        <td mat-cell *matCellDef="let invitation">
          {{
          invitation.invitee ? "Χρησιμο/θηκε" : "Διαθέσιμη"
          }}
        </td>
      </ng-container>
  
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Δημ/γία </th>
        <td mat-cell *matCellDef="let invitation">
          {{ invitation.created | timePassed }}
        </td>
      </ng-container>
  
      <!-- Copy Column -->
      <ng-container matColumnDef="copy">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let invitation">
          <mat-icon *ngIf="!invitation.invitee" (click)="!invitation.invitee && showQRCode(invitation.ref)">content_copy_outlined</mat-icon>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
  </div>
</section>

<div class="top-row">
  <div *ngIf="route == 'requests'" class="status">
    <mat-button-toggle-group value="pending" (change)="getUserRequests($event.value)">
      <mat-button-toggle [matBadge]="requests ? requests.count : 0" value="pending">Pending</mat-button-toggle>
      <mat-button-toggle value="accepted">Accepted</mat-button-toggle>
      <mat-button-toggle value="rejected">Rejected</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <button mat-icon-button (click)="sort()">
    <mat-icon>swap_vert_outlined</mat-icon>
  </button>
</div>

<ng-container [ngSwitch]="route">

  <ng-container *ngSwitchCase="'requests'">
    <div *ngFor="let r of requests.data; let i = index ">
      <mat-card>
        <mat-card-header>
          <mat-card-title [routerLink]="[ '/@' + r.username ]">@{{r.username}}</mat-card-title>
          <!-- <mat-card-subtitle>{{r.created | timePassed}}</mat-card-subtitle> -->
          <mat-card-subtitle>
            <span>{{r.created | timePassed}}</span>
            <button mat-button (click)="checkMailVerifications()">
              <mat-icon inline=true>refresh</mat-icon>
              <span [ngClass]="r.emailVerified ? 'accept' : 'warn'">
                Email: {{ r.emailVerified ? '✓' : '✗' }}
              </span>
            </button>
            <div><span>Invited by: </span> <a  [routerLink]="[ '/@' + r?.invitation?.inviter?.username ]">@{{r?.invitation?.inviter?.username}}</a></div>
          </mat-card-subtitle>
        </mat-card-header>
        <img *ngIf="r.attachment.includes('.')" [src]="'/api/users/registration-avatar/' + r.username + '/750'" width="100%">
        <!-- <img *ngIf="r.attachment.includes('.')" [src]="'/uploads/' + r.attachment" width="100%"> -->
        <mat-card-content>
          <p>{{ r.title ? r.title : 'NULL TITLE' }}</p>
          <ul>
            <li>⚥ {{ apiClient.genders[r.profile.sex] }}, {{ apiClient.genders[r?.profile.sex1] }}/{{ apiClient.genders[r?.profile.sex2] }}</li>
            <li>🎂 {{ r.profile.birthdate | age }}/{{ r?.profile.birthdate2 | age }}</li>
            <li>❤️ {{ r.profile.orientation }}/{{ r?.profile.orientation2 }}</li>
            <li>📍 {{ r.profile.location }}</li>
          </ul>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button class="accept" (click)="acceptUser(r.username, true, i)">Accept</button>
          <button mat-stroked-button (click)="rejectionWarning(r.username, [''])">Warn</button>
          <button mat-stroked-button class="warn" (click)="acceptUser(r.username, false, i)">Reject</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'reports'">
    <div *ngFor="let r of reports; let i = index">
      <mat-card>
        <mat-card-header>
          <mat-card-title>@{{r.reporter}}</mat-card-title>
          <mat-card-subtitle>{{r.date | timePassed}}</mat-card-subtitle>
        </mat-card-header>
        <!-- <img *ngIf="r.attachment.includes('.')" [src]="'/uploads/' + r.attachment" width="100%"> -->
        <mat-card-content>
          <p>{{r.reason}}</p>
          <p>{{r.description}}</p>
          <p>Target: {{r.targettype}} => {{r.targetref}}</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button>Αποδοχή</button>
          <button mat-button>Απόρριψη</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
</ng-container>
<header class="left-title">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <!-- Start -->
      <!-- Back Button -->
      <button id="back" mat-button mat-icon-button (click)="leavePage()">
        <mat-icon>close</mat-icon>
      </button>
      <!-- Profile Avatar + Name -->
      <div *ngIf="user" class="nav">
        <div [ngClass]="['avatar', 'avatar-32', 'sex-' + user.profile.sex]">
          <img [src]="apiClient.userAvatar(user.username, user.avatar, 90)"
            (error)="core.onImgError($event)">
          </div>
        <h3 class="user">@{{user.profile.name}}</h3>
      </div>
      <span class="spacer"></span>
      <!-- End -->
      <button mat-icon-button (click)="onSubmit()">
        <mat-icon class="accept check">check</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<section class="main" *ngIf="user">
  <div class="top" (click)="core.changeAvatar()">
    <div>
      <div [ngClass]="['avatar', 'avatar-100', 'sex-' + user.profile.sex]">
        <img [src]="apiClient.userAvatar(user.username, user.avatar, 150)"
          (error)="core.onImgError($event)">
      </div>
      <p>Αλλαγή Φωτογραφίας Προφίλ</p>
    </div>
  </div>

  <form [formGroup]="form">
    <mat-form-field>
      <textarea rows="4" #textarea matInput formControlName="info" type="text" placeholder="Μερικά λόγια για εσάς" autocomplete="off"
        (input)="lineBreakLimits($event)">
      </textarea>
      <mat-hint [style.color]="charCount > maxChars ? 'red' : 'inherit' " align="end">
        {{ charCount }}/{{ maxChars }}
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="userName" type="text" placeholder="Όνομα χρήστη" autocomplete="off" />
      <span class="check" *ngIf="!form.controls['userName'].errors && !loading && !form.controls['userName'].pristine"
        matSuffix mat-icon-button aria-label="done">
        <mat-icon>done</mat-icon>
      </span>

      <mat-error
        *ngIf="form.controls['userName'].hasError('maxlength') || form.controls['userName'].hasError('minlength')">
        Username must be between 3 and 16 characters long
      </mat-error>
      <mat-error *ngIf="form.controls['userName'].hasError('pattern')">
        Username can contain latin characters, numbers, periods & unrescores
      </mat-error>
      <mat-error *ngIf="form.controls['userName'].hasError('nameTaken')">
        Usename alsready exists
      </mat-error>
    </mat-form-field>

    <div class="custom-label">
      <mat-icon class="mat-custom">place</mat-icon>
      <span>Τοποθεσία</span>
    </div>
    <mat-form-field>
      <!-- <mat-label>Τοποθεσία</mat-label> -->
      <mat-select formControlName="location">
        <mat-option *ngFor='let location of apiClient.locations | keyvalue: apiClient.sortFunct' [value]="location.key">
          {{location.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="custom-label">
      <mat-icon class="mat-custom">radio_button_unchecked</mat-icon>
      <span>Είδος λογαριασμού</span>
    </div>
    <div class="couple">
      <div class="cpl-button" [ngClass]="{'selected': form.controls.sex.value != 'c'}" (click)="isCouple(false)">
        <button mat-mini-fab color="accent" type="button"> <!-- type="button", don't remove bevause default is type="submit" -->
          <mat-icon>radio_button_unchecked</mat-icon>
        </button>
        <span>Άτομο</span>
      </div>
      <div class="cpl-button" [disabled]="true" [ngClass]="{'selected': form.controls.sex.value == 'c'}" (click)="isCouple(true)">
        <button mat-mini-fab color="accent" type="button"> <!-- type="button", don't remove because default is type="submit" -->
          <mat-icon>radio_button_unchecked</mat-icon>
          <mat-icon class="second_circle">radio_button_unchecked</mat-icon>
        </button>
        <span>Ζευγάρι</span>
      </div>
    </div>

    <div class="custom-label">
      <mat-icon class="mat-custom" style="font-size: 16px;" fontSet="fa" fontSet="fa" fontIcon="fa-venus-mars"></mat-icon>
      <span>Φύλο</span>
    </div>
    <div class="columns">
      <mat-form-field>
        <mat-select *ngIf="form.controls.sex.value != 'c'" formControlName="sex">
          <mat-option *ngFor='let sex of apiClient.genders | keyvalue: apiClient.sortFunct' [value]="sex.key">
            {{ apiClient.genders[sex.key] }}
          </mat-option>
        </mat-select>
        <mat-select *ngIf="form.controls.sex.value == 'c'" formControlName="sex1">
          <mat-option *ngFor='let sex of apiClient.genders | keyvalue: apiClient.sortFunct' [value]="sex.key">
            {{ apiClient.genders[sex.key] }}
          </mat-option>
        </mat-select>
        <mat-error>
          Συμπληρώστε το πεδίο
        </mat-error>
      </mat-form-field>
      <mat-form-field class="person-2" *ngIf="form.controls.sex.value == 'c'">
        <mat-select formControlName="sex2">
          <mat-option *ngFor='let sex of apiClient.genders | keyvalue: apiClient.sortFunct' [value]="sex.key">
            {{ apiClient.genders[sex.key] }}
          </mat-option>
        </mat-select>
        <mat-error>
          Συμπληρώστε το πεδίο
        </mat-error>
      </mat-form-field>
    </div>

    <div class="custom-label">
      <mat-icon class="mat-custom">fork_right</mat-icon>
      <span>Σεξουαλικός προσανατολισμός</span>
    </div>
    <div class="columns">
      <mat-form-field>
        <mat-select formControlName="orientation">
          <mat-option *ngFor='let or of apiClient.orientations | keyvalue: apiClient.sortFunct' [value]="or.key">
            {{ apiClient.orientations[or.key] }}
          </mat-option>
        </mat-select>
        <mat-error>
          Συμπληρώστε το πεδίο
        </mat-error>
      </mat-form-field>
      <mat-form-field class="person-2" *ngIf="form.controls.sex.value == 'c'">
        <mat-select formControlName="orientation2">
          <mat-option *ngFor='let or of apiClient.orientations | keyvalue: apiClient.sortFunct' [value]="or.key">
            {{ apiClient.orientations[or.key] }}
          </mat-option>
        </mat-select>
        <mat-error>
          Συμπληρώστε το πεδίο
        </mat-error>
      </mat-form-field>
    </div>

    <div class="custom-label">
      <mat-icon class="mat-custom">cake</mat-icon>
      <span>Ημερομηνία γέννησης</span>
    </div>
    <div class="columns">
      <mat-form-field>
        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="birthdate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi startView="multi-year" [startAt]="startDate"></mat-datepicker>
        <mat-error>
          Πρέπει να είστε άνω των 18 ετών.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="person-2" *ngIf="form.controls.sex.value == 'c'">
        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="birthdate2">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi startView="multi-year" [startAt]="startDate"></mat-datepicker>
        <mat-error>
          Πρέπει να είστε άνω των 18 ετών.
        </mat-error>
      </mat-form-field>
    </div>

    <h4 class="warn">Προσωπικα Στοιχεία</h4>

    <mat-form-field>
      <input matInput formControlName="email" type="text" placeholder="Email" autocomplete="off" />
      <span class="check" *ngIf="!form.controls['email'].errors && !loading && !form.controls['email'].pristine"
        matSuffix mat-icon-button aria-label="done">
        <mat-icon>done</mat-icon>
      </span>

      <mat-error *ngIf="form.controls['email'].hasError('email') || form.controls['email'].hasError('pattern')">
        Η διεύθυνση email σας πρέπει να είναι σωστή.
      </mat-error>
      <mat-error *ngIf="form.controls['email'].hasError('emailExists')">
        Παρουσιάστηκε πρόβλημα με την παραπάνω διεύθυνση. Ελέγξτε την ορθογραφία της ή δοκιμάστε μια
        διαφορετική.
      </mat-error>
    </mat-form-field>
  </form>

  <div class="bottom-buttons">
    <!-- <button id="change-pass" mat-stroked-button [routerLink]="'/change-pass'">
      Αλλαγή Κωδικού Πρόσβασης
    </button> -->
    <button id="delete" mat-icon-button class="warn" aria-label="delete">
      <mat-icon>heart_broken</mat-icon> Διαγραφή Προφίλ
    </button>
  </div>
</section>

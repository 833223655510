import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportInput } from 'src/app/interfaces/interfaces';
import { ApiClient } from 'src/app/services/api-client.service';
import { CoreService } from '../../core/core.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  reportReason = ''
  // reasons = [
  //   'Επιθετικό ή απειλητικό περιεχόμενο.',
  //   'Το περιεχόμενο παραβαίνει τους όρους χρήσης.',
  //   'Πιστεύω πως ο χρήστης δεν έχει άδεια χρήσης της φωτογραφίας / την έχει βρει στο διαδίκτυο.',
  //   'Εμφανίζομαι στην φωτογραφία χωρίς να έχω δώσει άδεια.',
  //   'Είμαι ο κάτοχος πνευματικών δικαιωμάτων της φωτογραφίας και επιθυμώ την απόσυρση της.',
  // ]
  reasons = {
    post: [
      'Επιθετικό ή απειλητικό περιεχόμενο.',
      'Το περιεχόμενο παραβαίνει τους όρους χρήσης.',
      'Πιστεύω πως ο χρήστης δεν έχει άδεια χρήσης της φωτογραφίας / την έχει βρει στο διαδίκτυο.',
      'Εμφανίζομαι στην φωτογραφία χωρίς να έχω δώσει άδεια.',
      'Είμαι ο κάτοχος πνευματικών δικαιωμάτων της φωτογραφίας και επιθυμώ την απόσυρση της.',
    ],
    comment: [
      "Προσωπική Λεκτική Επίθεση ή Απειλή.",
      "Ρητορική μίσους.",
      "Το περιεχόμενο παραβαίνει τους όρους χρήσης.",
      "Spam ή απάτη."
    ],
    user: [
      "Αυτό το άτομο με εκφοβίζει ή με παρενοχλεί.",
      "Αυτό το προφίλ είναι ψεύτικο.",
      "Αυτό το άτομο είναι spammer ή απατεώνας.",
      "Γνωρίζω ότι αυτό το άτομο είναι ανήλικο.",
    ],
    bug: [
      "Αναφορά προβλήματος",
      "Ιδέα / πρόταση"
    ]
  }

  constructor(
    private core: CoreService,
    private apiClient: ApiClient,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReportComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      targetType: string,
      targetRef: string,
    }
  ) { }

  ngOnInit(): void {
    console.log(this.data);

    this.firstFormGroup = this.formBuilder.group({
      reason: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      description: ['', [
        Validators.required,
        Validators.maxLength(1000)
      ]],
      // email: ['', [
      //   Validators.email,
      //   Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      // ]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitReport() {
    if (this.secondFormGroup.invalid) {
      return
    }

    // const email = this.secondFormGroup.get('email').value
    // if (email) {
      //   payload['email'] = email
      // }

    const reportInput: ReportInput = {
      ...this.data,
      reason: this.firstFormGroup.get('reason').value,
      description: this.secondFormGroup.get('description').value
    }

    this.apiClient.report(reportInput)
    .subscribe({
      next: res => {
        if (res) {
          console.log(res);
          this.dialogRef.close();
          this.core.snackBarNotification('Η αναφορά σας καταχωρήθηκε. Σας ευχαριστούμε.', null, 4000)
        } else {
          this.core.snackBarNotification("⚠️ Υπήρξε κάποιο σφάλμα, δοκιμάστε αργότερα ή στείλτε μας email στο reports@naked-greece.com", null, 10000)
        }
      },
      error: (err:Error) => {
        console.log(err.name);
        console.log(err.message);
        if (err.message.includes("Already reported")) {
          this.core.snackBarNotification("⚠️ Έχετε ήδη κάνει αναφορά σε αυτό το περιεχόμενο, η οποία βρίσκεται σε εξέλιξη.", null, 10000)
        } else if (err.message.includes("Target not found")) {
          this.core.snackBarNotification("⚠️ Το περιεχόμενο έχει ήδη αφαιρεθεί. Ανανεώστε την σελίδα και αν σας είναι ακόμα ορατό, στείλτε μας email στο reports@naked-greece.com", null, 10000)
        }
      }
    })
  }
}

<header class="center-title">
    <mat-toolbar class="toolbar">
      <mat-toolbar-row>
        <!-- Back -->
        <button mat-button mat-icon-button (click)="back()">
          <mat-icon id="back">arrow_back_ios</mat-icon>
        </button>
        <mat-form-field id="search-bar" [floatLabel]="'never'">
          <mat-icon matPrefix class="prefix">search</mat-icon>
          <input matInput type="text" placeholder="Αναζήτηση" [(ngModel)]="searchQuery" autocomplete="off" (focus)="queryPage()">
        </mat-form-field>
      </mat-toolbar-row>
    </mat-toolbar>
</header>

<section class="margin">
  <mat-tab-group color="accent" [disableRipple]="true" (selectedTabChange)="tabChange($event)"
  [disablePagination]="true">
    <!-- POSTS TAB -->
    <mat-tab label="posts">
      <ng-template mat-tab-label>
        <mat-icon>photo_library</mat-icon>
      </ng-template>
      <app-timeline *ngIf="results" postsSource="search" [scrollDisabled]="scrollDisabled || tabConfing['posts'].tabScrollDisabled" 
      [query]="searchQuery" [pageSize]="pageSize" [pageIndex]="tabConfing.posts.pageIndex" [firstPosts]="results.posts">
      </app-timeline>
      <p class="padded-text" *ngIf="results && results.posts.data.length == 0">Δεν υπάρχουν αναρτήσεις για 
        <br>
        "{{searchQuery}}"
      </p>
    </mat-tab>
    <!-- USERS TAB -->
    <mat-tab label="users">
      <ng-template mat-tab-label>
        <mat-icon>group</mat-icon>
      </ng-template>
      <mat-list *ngIf="results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="scrollDisabled || tabConfing['users'].tabScrollDisabled" (scrolled)="onScroll()">
        <mat-list-item *ngFor="let res of results.users.data; let i = index" class="pointer">
          <div mat-list-icon [ngClass]="['avatar', 'avatar-54', 'sex-' + res.profile.sex]">
            <img [routerLink]="['/@' + res.username]" src={{apiClient.userAvatar(res.username,res.profile.avatar,90)}}>
          </div>
          <div mat-line [routerLink]="['/@' + res.username]" id="title">@{{res.username}}</div>
          <div mat-line [routerLink]="['/@' + res.username]" id="subtitle">
              {{res.profile.birthdate | age}} 
              <span *ngIf="res.profile.birthdate2">| {{res.profile?.birthdate2 | age}}</span> 
              {{res.profile.location}}
          </div>
          <div *ngIf="!res.self" class="follow-button">
            <button *ngIf="!(res.followingThem)" mat-flat-button color="accent"
            (click)="followUnfollow(res.username, i)">{{res.followingMe ? "Ακολ/\στε Πίσω" : "Ακολουθήστε"}}</button>
            <button *ngIf="res.followingThem" mat-stroked-button color="accent"
            (click)="followUnfollow(res.username, i)">{{res.followingMe ? "Φίλοι!" : "Ακολουθείτε"}}</button>
        </div>
        </mat-list-item>
        <p class="padded-text" *ngIf="results.users.data.length == 0">Δεν υπάρχουν μέλη για 
          <br>
          "{{searchQuery}}"
        </p>
      </mat-list>
    </mat-tab>
    <!-- HASHTAGS TAB -->
    <mat-tab label="hashtags">
      <ng-template mat-tab-label>
        <mat-icon>tag</mat-icon>
      </ng-template>
      <mat-list *ngIf="results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="scrollDisabled || tabConfing['hashtags'].tabScrollDisabled" (scrolled)="onScroll()">
        <mat-list-item *ngFor="let res of results.hashtags.data; let i = index" class="pointer">
          <div mat-list-icon [ngClass]="['avatar', 'avatar-54', 'hashtag-avatar']">
            <img [routerLink]="['/tag/' + res.text]" src={{res.avatar}}>
          </div>         
          <div mat-line [routerLink]="['/tag/' + res.text]" id="title">#{{res.text}}</div>
          <div mat-line [routerLink]="['/tag/' + res.text]" id="subtitle">
              {{res.posts.count | number: '1.'}} αναρτήσεις
          </div>
        </mat-list-item>
        <p class="padded-text" *ngIf="results.hashtags.data.length == 0">Δεν υπάρχουν hashtags για 
          <br>
          "{{searchQuery}}"
        </p>
      </mat-list>
    </mat-tab>
    <!-- LOCATIONS TAB -->
    <mat-tab label="locations">
      <ng-template mat-tab-label>
        <mat-icon>place</mat-icon>
      </ng-template>
      <mat-list *ngIf="results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="scrollDisabled || tabConfing['locations'].tabScrollDisabled" (scrolled)="onScroll()">
        <mat-list-item *ngFor="let res of results.locations.data; let i = index" class="pointer">
          <div mat-list-icon [ngClass]="['avatar', 'avatar-54', 'location-avatar']">
            <img [routerLink]="['/l/' + res.name]" class="avatar-svg" src="./assets/location.svg">
          </div>
          <div mat-line [routerLink]="['/l/' + res.name]" id="title">{{apiClient.locations[res.name]}}</div>
          <div mat-line [routerLink]="['/l/' + res.name]" id="subtitle">
              <!-- {{res.posts.count | number: '1.'}} αναρτήσεις -->
          </div>
        </mat-list-item>
        <p class="padded-text" *ngIf="results.locations.data.length == 0">Δεν υπάρχουν τοποθεσίες για 
          <br>
          "{{searchQuery}}"
        </p>
      </mat-list>
    </mat-tab>
  </mat-tab-group>
  <mat-spinner *ngIf="loading" color="accent" [diameter]="30"></mat-spinner>

</section>
import { BrowserModule } from '@angular/platform-browser';
import { isDevMode, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEl from '@angular/common/locales/el';
registerLocaleData(localeEl);

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, REGION, ORIGIN as FUNCTIONS_ORIGIN } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { NewPostComponent } from './pages/new-post/new-post.component';
import { GridComponent } from './components/grid/grid.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SearchComponent } from './pages/search/search.component';
import { HorizontalComponent } from './components/horizontal/horizontal.component';
import { SinglePostComponent } from './pages/single-post/single-post.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';

// Pipes
import { AgePipe } from './custom-pipes/age.pipe';
import { TimePassedPipe } from './custom-pipes/time-passed.pipe';
import { LinkedTextPipe } from './custom-pipes/linked-text.pipe';

// Upload & Crop modal -> move to Lazy Module
import { UploadComponent } from './components/upload/upload.component';
import { FileUploadModule } from 'ng2-file-upload';

// Modal Listing likes, comments, followers
import { ListComponent } from './components/list/list.component';

// Route Reuse Strategy
import { AppRouterOutletDirective, CustomRouteReuseStategy } from './app-route-strategy';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
import { SearchResultsComponent } from './pages/search/search-results/search-results.component';
import { MoreMenuComponent } from './core/nav-bar/more-menu/more-menu.component';
import { ReportComponent } from './components/report/report.component';
import { MasonryComponent } from './components/masonry/masonry.component';
import { JwtModule } from './jwt/jwt.module';
import { AuthenticationService } from './services/authentication.service';
import { ApiClient } from './services/api-client.service';
import { HttpClientModule } from '@angular/common/http';
import { UploadCanvasComponent } from './components/upload-canvas/upload-canvas.component';
import { SearchQueryComponent } from './pages/search/search-query/search-query.component';
import { SearchBarComponent } from './pages/search/search-bar/search-bar.component';
import { HashtagLocationComponent } from './pages/hashtag-location/hashtag-location.component';
import { HrefNavigationDirective} from './directives/href-navigation.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Page404Component } from './components/page404/page404.component';
import { GuestPageComponent } from './components/guest-page/guest-page.component';
import { AdminToolsComponent } from './pages/admin-tools/admin-tools.component';
import { MailActionHandlerComponent } from './pages/mail-action-handler/mail-action-handler.component';
import { InvitationsComponent } from './pages/invitations/invitations.component';
import { PwaComponent } from './pages/pwa/pwa.component';
import { QrCodeComponent } from './pages/qr-code/qr-code.component';

// Firebase Config (replaced with .prod.ts with --prod flag on 'serve' or 'build')
const firebaseConfig = environment.firebaseConfig

@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        HrefNavigationDirective,
        LoginComponent,
        NewPostComponent,
        GridComponent,
        TimelineComponent,
        ProfileComponent,
        SearchComponent,
        ListComponent,
        HomeComponent,
        AgePipe,
        TimePassedPipe,
        LinkedTextPipe,
        HorizontalComponent,
        EditProfileComponent,
        ConfirmationDialogComponent,
        ChangePassComponent,
        SearchResultsComponent,
        MoreMenuComponent,
        ReportComponent,
        AppRouterOutletDirective,
        MasonryComponent,
        SinglePostComponent,
        UploadCanvasComponent,
        SearchQueryComponent,
        SearchBarComponent,
        HashtagLocationComponent,
        Page404Component,
        GuestPageComponent,
        AdminToolsComponent,
        MailActionHandlerComponent,
        InvitationsComponent,
        PwaComponent,
        QrCodeComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule // firestore
        // .enablePersistence({ synchronizeTabs: true }) // enabled offline persistence
        ,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        CoreModule,
        RouterModule,
        FormsModule,
        FileUploadModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: AuthenticationService.tokenGetter,
                whitelistedDomains: [environment.baseHref]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        ApiClient,
        { provide: LOCALE_ID, useValue: 'el' },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },
        { provide: REGION, useValue: 'europe-west3' },
        { provide: FUNCTIONS_ORIGIN, useFactory: () => isDevMode() ? undefined : location.origin },
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }

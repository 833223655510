import {Directive, HostListener} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[hrefNavigation]'
})
export class HrefNavigationDirective {
  constructor(private router: Router) {
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    console.log('hrefNavigation');
    if (event.target) {
      const href = event.target['getAttribute']('href');
      if (href) {
        const url = new URL(href, window.location.href);
        if (window.location.host === url.host) {
          event.preventDefault();
          this.router.navigateByUrl(href);
        }
      }
    }
  }
}

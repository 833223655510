<ng-container *ngIf="postsSource != 'private'; else private">

  <section class="masonry">
    <ngx-masonry [options]="masonryOptions" [ordered]="true" infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="scrollDisabled" [immediateCheck]="true"
      (itemsLoaded)="loading = false" (scrolled)="onScroll()">
      <div id="item" class="pointer" ngxMasonryItem *ngFor="let post of posts; index as idx" [routerLink]="['/p/', post.ref]" 
        [state]='{
          data: posts,
          index: idx,
          postsSource: postsSource,
          query: query,
          endReached: endReached
          }'
        >
        <img class="brick img-placeholder" [src]="post.image[320]" srcset="{{post.image[320]}} 320w,
          {{post.image[640]}} 640w" sizes="(min-width: 500px) 193px, 49vw">
        <div class="info">
          <span class="user">@{{post.author.username}}</span>
          <span *ngIf="auth.userObject && auth.userObject.username && post.title" class="title">{{post.title}}</span>
        </div>
      </div>
    </ngx-masonry>
  </section>

  <div *ngIf="loading">
    <div class="placeholder-grid">
      <div class="placeholder-tile">
        <div class="animated-placeholder" style="height: 260px;"></div>
        <div class="animated-placeholder" style="height: 190px;"></div>
      </div>
      <div class="placeholder-tile">
        <div class="animated-placeholder" style="height: 200px;"></div>
        <div class="animated-placeholder" style="height: 260px;"></div>
      </div>
      <div class="placeholder-tile">
        <div class="animated-placeholder" style="height: 240px;"></div>
        <div class="animated-placeholder" style="height: 200px;"></div>
      </div>
    </div>
    <mat-spinner color="accent" [diameter]="30"></mat-spinner>
  </div>

</ng-container>

<ng-template #private>

  <div>
    <div class="placeholder-grid">
        <div class="placeholder-tile">
          <div *ngFor="let h of [260, 190, 260, 190]" class="animated-placeholder private" [style.height]="h + 'px'">
            <mat-icon class="material-icons-outlined">visibility_off_outlined</mat-icon>
          </div>
      </div>
      <div class="placeholder-tile">
        <div *ngFor="let h of [200, 260, 200, 260]" class="animated-placeholder private" [style.height]="h + 'px'">
          <mat-icon class="material-icons-outlined">visibility_off_outlined</mat-icon>
        </div>
      </div>
      <div class="placeholder-tile">
        <div *ngFor="let h of [240, 200, 240, 200]" class="animated-placeholder private" [style.height]="h + 'px'">
          <mat-icon class="material-icons-outlined">visibility_off_outlined</mat-icon>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<!-- <div *ngIf="endReached" style="text-align: center; padding: 20px;">
  <mat-icon class="material-icons-outlined">all_inclusive_outlined</mat-icon>
</div> -->
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CoreService } from '../../core/core.service';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ActivityItem, authUser, Comment, Hashtag, Post, searchResults, User } from '../../interfaces/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ReportComponent } from '../report/report.component';
import { ApiClient } from '../../services/api-client.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OnAttach, OnDetach } from 'src/app/app-route-strategy';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})


export class ListComponent implements OnInit, OnAttach, OnDetach {

  // focus on comment Input on 'reply' pressed
  @ViewChild('commentInput') commentInput: ElementRef;

  response: any[] = []
  params: {postRef?:string, username?:string, request:any}
  request: 'likes' | 'comments' | 'followers' | 'following' | "blocked" | 'activity'
  commentText = ""
  activeComment = ""
  replyingTo: Comment = null
  activityPostRef: string = null
  loading: boolean = false
  uploadingComment: boolean = false
  timeTrigger = 0
  commentReached = false

  pageSize = 20 // make sure the new items are enough to extend the div.list height (so to extend the view)
  pageIndex = 0
  endReached = false

  scrollDisabled = false
  mainRouteReuse: any

  wording(user, action) {
    switch (action) {
      case "like":
        return `Στο μέλος @${user} άρεσε η φωτογραφία σας!`
      case "commentLike":
        return `Στο μέλος @${user} άρεσε το σχόλιο σας:`
      case "comment":
        return `Το μέλος @${user} σχολίασε:`
      case "reply":
        return `Το μέλος @${user} σας απάντησε:`
      case "follow":
        return `Το μέλος @${user} σας ακολούθησε!`
      case "mention":
        return `Το μέλος @${user} σας ανέφερε σε σχόλιό:`
      case "postMention":
        return `Το μέλος @${user} σας ανέφερε στην ανάρτηση του:`
    }
  }
  
  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.changeDetectorRef.detectChanges()
  }
  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.cancelReply()
    this.scrollDisabled = true
    this.router.routeReuseStrategy.shouldReuseRoute = this.mainRouteReuse
    this.changeDetectorRef.detectChanges()
  }

  constructor(public core: CoreService, private viewportScroller: ViewportScroller,
    public location: Location, public router: Router, private activatedRoute: ActivatedRoute,
    public auth: AuthenticationService, private dialog: MatDialog, public apiClient: ApiClient,
    private changeDetectorRef: ChangeDetectorRef) {

    this.mainRouteReuse = this.router.routeReuseStrategy.shouldReuseRoute
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // Get state (if any)
    // console.log("route params", this.activatedRoute.snapshot.paramMap['params']);
    // console.log(this.route.snapshot.firstChild.data); 
    this.params = this.activatedRoute.snapshot.paramMap['params']
    // this.state = this.router.getCurrentNavigation().extras.state
    // console.log(this.state);
    this.request = Object.keys(this.params).length ? this.params.request : this.activatedRoute.snapshot.routeConfig.path
    console.log(this.request);
  }

  ngOnInit(): void {

    this.loading = true

    if (this.request == "comments") {
      this.apiClient.getPostComments(this.params.postRef, this.pageSize, this.pageIndex)
      .subscribe({
        next: comments => {
          console.log(comments);
          this.response = [...this.response, ...comments.data]
          this.endReached = comments.data.length < this.pageSize            
          console.log("end:", this.endReached);

          // Scroll to comment (or to bottom of no comment is anchored)
          this.activeComment = this.activatedRoute.snapshot.fragment
          if (this.activeComment && !this.commentReached) { // stop scrolling to fragment on every onScroll()
            this.scrollToComment(this.activeComment)
            // remove comment highlight when navigated there so it is not confusing with reply highlight
            setTimeout(() => {
              document.getElementById(this.activeComment).classList.add("navigated-comment")
            }, 10);
            setTimeout(() => {
              // ? to skip error log if user navigated away before 2s (classList not found)
              document.getElementById(this.activeComment)?.classList.remove("navigated-comment")
            }, 2000
        )
          }
          this.loading = false
          this.core.showNavBar(false)
        },
        error: () => this.redirectToParent()
      })
    } else if (this.request == "likes") {
        this.apiClient.getPostLikes(this.params.postRef, this.pageSize, this.pageIndex)
          .subscribe({
            next: likes => {
              this.response = [...this.response, ...likes.data]
              this.endReached = likes.data.length < this.pageSize            
              console.log("end:", this.endReached);
              this.loading = false
            },
            // if user does not exist, redirect to home
            error: error => this.redirectToParent()
          })
    } else if (this.request == "followers") {
        this.apiClient.getFollowers(this.params.username, this.pageSize, this.pageIndex)
          .subscribe({
            next: follows => {
              this.response = [...this.response, ...follows.data]
              this.endReached = follows.data.length < this.pageSize            
              console.log("end:", this.endReached);
              this.loading = false
            },
            // if user does not exist, redirect to home
            error: error => this.redirectToParent()
          })
    } else if (this.request == "following") {
        this.apiClient.getFollowing(this.params.username, this.pageSize, this.pageIndex)
          .subscribe({
            next: follows => {
              this.response = [...this.response, ...follows.data]
              this.endReached = follows.data.length < this.pageSize            
              console.log("end:", this.endReached);
              this.loading = false
            },
            // if user does not exist, redirect to home
            error: error => this.redirectToParent()
        })
    } else if (this.request == "blocked") {
        this.apiClient.myBlocks()
          .subscribe({
            next: blockedUsers => {
              console.log(blockedUsers);
              
              this.response = [...this.response, ...blockedUsers]
              this.loading = false
            },
            // if user does not exist, redirect to home
            error: error => this.redirectToParent()
        })
    } else if (this.request == "activity") {
      this.apiClient.getActivity(this.pageSize, this.pageIndex)
        .subscribe(activity => {
          this.response = [...this.response, ...activity.data]
          this.endReached = activity.data.length == 0 // merges in replies/mentions often create data.length < pageSize
          console.log(activity);
          console.log("end:", this.endReached);
          this.apiClient.activityCounterReset(this.core.javaTimeDate).subscribe()
          this.loading = false
        })
    }
      // if request is gibberish, redirect to parent @user or /post
      else if (this.request) {
        this.redirectToParent()
    } 

    // Refresh timestamps by pinging ViewChange every 60 seconds
    setInterval(() => this.timeTrigger = Math.random(), 60*1000)
  }

  redirectToParent() {
    if (this.params.username) {
      this.router.navigate(['/', '@' + this.params.username], { replaceUrl: true })
    } else if (this.params.postRef) {
      this.router.navigate(['/', 'p', this.params.postRef], { replaceUrl: true })
    } else {
      this.router.navigate(['/404'], { replaceUrl: true })
    }
  }

  reply(comment:Comment, activityIndex = null) {
    console.log(comment, activityIndex);

    this.scrollToComment(activityIndex !== null ? `item-${activityIndex}` : comment.ref)
    if (this.request == "activity") {
      this.core.showNavBar(false)
      this.activityPostRef = this.response[activityIndex].post.ref
    }
    this.replyingTo = comment
    this.activeComment = ''
    this.commentText = '@' + comment.author.username + ' '
    // wait for *ngIf to display the comment input - 0 is enough, 10ms just in case
    setTimeout(() => {
      this.commentInput.nativeElement.style.height = "20px"
    }, 10);
  }

  cancelReply() {
    this.replyingTo = null
    this.commentText = null
    if (this.request == "activity") {
      this.core.showNavBar(true)
      this.activityPostRef = null
    }
  }

  comment() {
    // Activity replies has no "params", we get postRef
    const postRef = this.request != "activity" ? this.params.postRef : this.activityPostRef // TODO: activityPostRef could be skipped (becomes null on the backend) - don't bother though
    const text = this.commentText.replace(/(\r\n|\r|\n)/gm, "") // removes all newlines
    const replyRef = this.replyingTo ? this.replyingTo.ref : null

    // No empty comments
    if (text.length == 0) {
      return
    }

    this.uploadingComment = true

    // Rstrtict number of @ to 5
    const mentions = text.split("@").length - 1
    if (mentions > 5) {
      return this.core.snackBarNotification(
        "Μπορείτε να κάνετε tag εως 5 χρήστες",
        null,
        5000
      ), this.uploadingComment = false
    }

    this.apiClient.createComment(postRef, text, replyRef)
    .subscribe({
      next: (res:Comment) => {
        console.log("New comment", res);

        if (res) {
          // Show comment under parent (Activity page reply)
          if (replyRef && this.request == "activity" ) {
            this.response.forEach((item, index) => {
              if (item.comment && item.comment.ref == replyRef) {
                // this.response[parentIndex].comments.data.push(res)
                this.response[index].comment.comments = {data: res}
              }
            });
          }
          // Show comment under parent (Comments page reply)
          else if (replyRef) {
            const parentIndex = this.response.findIndex(item => item.ref === res.parent.ref);
            this.response[parentIndex].comments.data.push(res)
            this.response[parentIndex].comments.count += 1
          }
          // Show comment at end of list (not a reply)
          else {
            this.response.push(res)
          }
          console.log("Comments", this.response);

          this.cancelReply()
          this.commentText = ''
          this.activeComment = res.ref
          this.uploadingComment = false

          // Scroll to comment - NOT when replying from Activity page (it's confusing)
          // if (this.request != "activity") {
            this.scrollToComment(res.ref)
          // }
        }
      },
      error: err => this.core.snackBarNotification("Κάτι πήγε στραβά, δοκιμάστε αργότερα")
    })
  }

  deleteComment(commentRef, index, nestedIndex=null) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { type: 'deleteConfirmation' }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result == "delete") {
        this.apiClient.deleteComment(commentRef)
          .subscribe(
            res => {
            console.log(res);
            if (res) {
              if (nestedIndex != null) {
                this.response[index].comments.data.splice(nestedIndex, 1)
              } else {
                this.response.splice(index, 1)
              }
            }
            },
            // TODO: Handle the errors somewhere central. How many errors are there? Different dialogs for each.
            err => {
              console.log('HTTP Error', err)
              alert("Error! Check console for details")
            }
          )
      }
    });
  }

  followUnfollow(username, index) {
    this.apiClient.followUnfollow(username)
      .subscribe((res) => {
        console.log(`Following @${username}: ${res.data.follow}`);
        if (res) {
          // Updates follow button in view
          this.response[index].user.followingThem = res.data.follow
        }
      })
  }

  // Scroll to anchor #id
  scrollToComment(fragment: string) {
    setTimeout(() => {
        if (fragment) {
        const cmnt = document.getElementById(fragment);


        // If comment is out of reach, load more until you find it.
        if (!cmnt && !this.endReached) {
          this.onScroll()
          return
        }

        // Scroll to comment
        cmnt.scrollIntoView({ behavior: 'smooth',  block: "center" });

        this.commentReached = true
        } else {
          // Scroll to bottom if no fragment selected -> navigated form post
          // window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        }
      }, 50);
  }

  onScroll() {
    if (!this.endReached) {
      this.pageIndex += 1
      this.ngOnInit()
    }
  }

  ngOnDestroy(): void {
  }

  hashtagMentionConfig = {
    items: [],
    triggerChar: '#',
    returnTrigger: true,
    disableSearch: true,
    labelKey: 'text',
    disableSort: true,
    dropUp: true
  };

  userMentionConfig = {
    items: [],
    triggerChar: '@',
    returnTrigger: true,
    disableSearch: true,
    labelKey: 'username',
    disableSort: true,
    dropUp: true
  };

  mentionConfig = {
    mentions: [this.hashtagMentionConfig, this.userMentionConfig]
  };

  search(term: string) {
    const trigger = term[0]
    this.getMentions(term).subscribe(terms=>{
      if (trigger === '@') {
        this.userMentionConfig.items = terms
      } else {
        this.hashtagMentionConfig.items = terms
      }      
      this.mentionConfig = {
        mentions: [this.hashtagMentionConfig, this.userMentionConfig]
      };
    })
  }

  getMentions(term:string) : Observable<any> {
    const trigger = term[0]
    const query = term.slice(1).toLowerCase()
    // if (query.length < 1) {
    //   return of([])
    // } else 
    if (trigger == "@") {
      return this.apiClient.search(query, "users", undefined, 5, 0)
        .pipe(map(res => res.users.data))
    } else {
      return this.apiClient.search(query, "hashtags", undefined, 5, 0)
        .pipe(map(res => res.hashtags.data))
    }
  }

  // Adjust textarea height
  lineBreakLimits(event) {
    let commentEl = this.commentInput.nativeElement;
    // Change the nativeElement.value so that the textarea height updates
    commentEl.value = commentEl.value.replace(/(\r\n|\r|\n)/gm, "")
    // commentText needs to be updated, otherwise an "Enter" is a valid comment ["commentText == true"]
    this.commentText = commentEl.value 
    // --- Adjust commentInput height ---
    commentEl.style.height = 0;
    commentEl.style.height = commentEl.scrollHeight - 4 + 'px';
  }

  likeUnlike(ref, like, commentIndex=null, childIndex=null, activity=false) {
    this.apiClient.likeUnlike(ref,like)
    .subscribe(res => {
      console.log(`Like comment "${ref}": ${res.like} - commentIndex: ${commentIndex} - childIndex: ${childIndex} - ActivityIndex: ${activity}`);
      // Handle comment like form activity, otherwise handle comment like from comments
      if (activity) {
        // Update activity response
        this.response[commentIndex].comment.liked = like
        return
      }
      // Handle comment like from comment page (parent/child comment)
      if (childIndex == null) {
        console.log("AAAA");
        
        this.response[commentIndex].liked = like
      } else {
        console.log("ASFSAF");
        
        this.response[commentIndex].comments.data[childIndex].liked = like
      }
      console.log(this.response[commentIndex].comments.data[childIndex]);

      // let count = this.response[postIndex].likes
      // this.response[postIndex].likes = like ? count += 1 : count -= 1
    })
  }

}

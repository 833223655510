import { Component } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gimni-ellada';

  constructor(private swUpdate: SwUpdate) {
    // Remove Splash Screen after App is loaded
    const splashScreen: HTMLElement = document.getElementById('splash-screen');
    if (splashScreen) {
      splashScreen.remove();
    }

    this.checkForUpdates();
  }

  checkForUpdates() {
    console.warn('Service Worker is enabled', this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY')
        )
        .subscribe(event => {
          console.log(`Update available: current version is ${event.currentVersion} available version is ${event.latestVersion}`);
          // if (confirm('Υπάρχει διαθέσιμη νέα έκδοση της εφαρμογής. Θέλετε να την εγκαταστήσετε;')) {
          //  this.swUpdate.activateUpdate().then(() => this.location.reload());
          // }
          this.swUpdate.activateUpdate().then(() => location.reload());
        });
    }
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CoreService } from '../../core/core.service';
import { Hashtag, Post } from '../../interfaces/interfaces';
import { Location } from '@angular/common';
import { ApiClient } from '../../services/api-client.service';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { OnAttach, OnDetach } from '../../app-route-strategy';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnAttach, OnDetach {

  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
    this.changeDetectorRef.detectChanges()
  }
  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.scrollDisabled = true
    this.changeDetectorRef.detectChanges()
  }
  
  scrollDisabled: boolean = false

  constructor(
    public core: CoreService, public apiClient: ApiClient, 
    public location: Location, public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) 
  {
    // monitor urls
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url)
        if (event.url == "/search") {
          this.clearSearch()
          this.inputFocused = false
        } else {
          // event.url.includes("/query") ? this.subPage = "liveSearch" : this.subPage = "searchResults"
          this.inputFocused = true
        }
        // console.log(event.state.root.firstChild.params); // can also access "fragment" (#) instead of "params"
      }      
    })
  }

  routerSubscription: Subscription

  inputFocused: boolean

  posts: Post[]
  tags: any //Hashtag[]

  ngOnInit(): void {
    this.apiClient.getPopularHashtags(5, undefined, 6)
    .subscribe(tags => {
      this.tags = tags.data.map(tag => ({
        name: tag.text,
        count: tag.count,
        avatar: tag.avatar ? this.apiClient.imgToScrset(tag.avatar.split(":")[0] + "/", tag.avatar.split(":")[1]) : "null:null",
        posts: tag.posts
      }));
      console.log(this.tags);
    });
  }

  search_term
  loading

  fullSearch() {
    if (this.search_term) {
      this.router.navigate([`/search/results`], {queryParams: { q: this.search_term } } )
    }
  }

  clearSearch() {
    this.search_term = ''
    this.loading = false
  }

  back() {
    this.location.back()
  }

  queryPage() {
    this.router.navigate(['/search/query'])
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe()
  }

}

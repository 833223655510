import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { invitations } from 'src/app/interfaces/interfaces';
import { ApiClient } from 'src/app/services/api-client.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {

  @ViewChild('table', { read: ElementRef }) table:ElementRef<HTMLTableElement>;

  constructor(public apiClient:ApiClient, public auth:AuthenticationService, private router: Router) { }

  invitations: invitations
  loading = true

  ngOnInit(): void {
    this.apiClient.getInvitations()
      .subscribe(invitations => {
        console.log(invitations);
        this.invitations = invitations
        this.loading = false
      })
  }

  displayedColumns: string[] = ['index', 'ref', 'used', 'date', 'copy'];

  createInvitation() {
    this.apiClient.createInvitation()
      .subscribe(invitation => {
        // update invitations in view
        this.invitations.count += 1
        this.invitations.data = [invitation, ...this.invitations.data]
        // add class to table to trigger animation
        this.table.nativeElement.classList.add("new-inv")
        setTimeout(() => {
          this.table.nativeElement.classList.remove("new-inv")
          }, 100
        )
        // subtract one invitation from userObject
        this.auth.userObject.availableInvitations = this.auth.userObject.availableInvitations - 1
      })
    
  }

  showQRCode(ref) {
    this.auth.qrData = {
      title: ref,
      string: "https://naked-greece.com/register?invitation=" + ref,
    }
    this.router.navigate(["qr"])
  }

}

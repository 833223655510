import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxAngularQrcodeComponent } from 'ngx-angular-qrcode';
import { CoreService } from 'src/app/core/core.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  constructor(public location: Location, public auth: AuthenticationService, private router: Router, private core: CoreService, ) { }
  
  ngOnInit(): void {
    // Don't allow this page to reload, go back to invitations
    // If the page loads, the next time we navigate to it, the old QR code will be generated
    if (!this.auth.qrData) {
      this.router.navigate(['/invitations']);
    }
  }

  copy(invitationUrl) {
    // window.location.href
    // navigator.clipboard.writeText(invitationRef);
    navigator.clipboard.writeText(
      `Η πρόσκλησή σας για το Naked Greece είναι: ${invitationUrl.split("=")[1]}\n\nΑκολουθήστε τον σύνδεσμο:\n${invitationUrl}`)
    return setTimeout(() => {
      this.core.snackBarNotification('Η πρόσκλησή σας αντιγράφηκε.')
    }, 200);
  }

  // QR Code Options
  // @ViewChild(NgxAngularQrcodeComponent, { static: true }) qrCode!: NgxAngularQrcodeComponent;

  qrData = this.auth.qrData ? this.auth.qrData.string : 'https://naked-greece.com'+this.auth.userObject.username;
  shape = 'square';
  width = 500;
  height = 500;
  margin = 20;

  imageUrl = "./assets/icons/icon-192x192.png";

  // Dots Options
  dotsType = 'extra-rounded';
  dotsGradient = true;
  dotsColor!: string;
  dotsStartColor = '#09ceff';
  dotsEndColor = '#ff10a3';
  dotsGradientType = 'linear';
  dotsGradientRotation = 280;

  // Corner Square Options
  cornerSquareType = 'extra-rounded';
  cornerSquareGradient = false;
  cornerSquareColor: "black";
  cornerSquareStartColor = '#ff12ff';
  cornerSquareEndColor = '#E09515';
  cornerSquareGradientType = 'linear';
  cornerSquareGradientRotation = 0;

  // Corner Dot Options
  cornerDotType = 'extra-rounded';
  cornerDotGradient = false;
  cornerDotColor!: string;
  cornerDotStartColor = '#ffff00';
  cornerDotEndColor = '#333333';
  cornerDotGradientType = 'radial';
  cornerDotGradientRotation = 0;

  // Background Options
  backgroundType = 'Rounded';
  backgroundGradient = false;
  backgroundColor = '#2f2f2f'
  backgroundStartColor = '#ffffff';
  backgroundEndColor = '#B7C2E1';
  backgroundGradientType = 'radial';
  backgroundGradientRotation = 0;

  // Image Options
  imageSize = 0.4;
  imageMargin = 0;
  hideImageBackgroundDots = true;

  errorCorrectionLevel = 'Q';

  fileExtension = 'png';

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import firebase from 'firebase/compat/app';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { CoreService } from '../../core/core.service';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';

// Error when the parent is invalid
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control, form): boolean {
    // console.log(form.form.controls['passwordGroup']);
    return control.touched && form.form.controls['passwordGroup'].invalid;
  }
}

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder, private location: Location, 
    public dialog: MatDialog, private core: CoreService,
    private auth: AuthenticationService) { }

  user: firebase.User
  form: UntypedFormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  loading = false;

  ngOnInit() {
    this.form = this.fb.group({
      passwordGroup: this.fb.group({
        password: ['', [
          Validators.minLength(8), 
          Validators.required,
          // Validators.pattern(this.core.regex.passwords)
        ]],
        passwordConfirm: ['', [Validators.required]]
      },
        { validator: this.passwordValidator }
      )
    });

    // this.fc = this.form.controls
  }

  // Password Matching Validator
  passwordValidator(form: UntypedFormGroup) {
    const condition = form.get('password').value !== form.get('passwordConfirm').value;
    return condition ? { passwordsDoNotMatch: true } : null;
  }

  confirmPass() {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { type: 'passwordConfirmation' }
      })
      dialogRef.afterClosed().subscribe(result => {
        resolve(result)
      });
    })
  }

  onSubmit() {
    this.loading = true
    console.log(this.form);
    var newPassword = this.form.get('passwordGroup.password').value

    this.auth.firebaseUser
    .pipe(first())
    .subscribe(user => {
      user.updatePassword(newPassword)
      .then(() => {
        this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            type: 'info',
            message: 'Ο κωδικός σας άλλαξε επιτυχώς.'
          }
        })
          .afterClosed().subscribe(() => {
            this.location.back()
          })
      })
      .catch(error => {
        if (error.code != "auth/requires-recent-login") {
          throw error
        } else {
          this.confirmPass().then((pass: string) => {
            console.log("got password:", pass)
            const credential = firebase.auth.EmailAuthProvider.credential(
              user.email,
              pass
            );
            // Use mail and pass credential to reauthenticate
            user.reauthenticateWithCredential(credential)
              .then(() => {
                this.onSubmit()
              })
              // if pass is wrong, throw dialog to retry
              .catch((error) => {
                if (error.code == "auth/wrong-password") {
                  this.dialog.open(ConfirmationDialogComponent, {
                    data: { type: 'wrongPass' }
                  })
                  throw error
                }
              })
          })
        }
      })
      .finally(() => {
        this.loading = false
      });
    })
  }

}

import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { NewPostComponent } from './pages/new-post/new-post.component'
import { RegisterComponent } from './pages/auth/register/register.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SearchComponent } from './pages/search/search.component';
import { SearchResultsComponent } from './pages/search/search-results/search-results.component';
import { SearchQueryComponent } from './pages/search/search-query/search-query.component';
import { ListComponent } from './components/list/list.component';
import { SinglePostComponent } from './pages/single-post/single-post.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
import { HashtagLocationComponent } from './pages/hashtag-location/hashtag-location.component';
import { Page404Component } from './components/page404/page404.component';
import { AuthGuard } from './services/guards/auth.guard';
import { MailActionHandlerComponent } from './pages/mail-action-handler/mail-action-handler.component';
import { InvitationsComponent } from './pages/invitations/invitations.component';
import { UnAuthGuard } from './services/guards/unauth.guard';
import { PwaComponent } from './pages/pwa/pwa.component';
import { AdminToolsComponent } from './pages/admin-tools/admin-tools.component';
import { AdminGuard } from './services/guards/admin.guard';
import { QrCodeComponent } from './pages/qr-code/qr-code.component';


@Injectable({providedIn: "root"})
export class ResolveChildAtTitle implements Resolve<string> {
  resolve(route: ActivatedRouteSnapshot): string {
    let param = ""
    if (route.paramMap.keys.includes('postRef')) {
      param = `Ανάρτηση ${route.paramMap.get('postRef')} ${suffix}`
    } else if (route.paramMap.keys.includes('username')) {
      param = `Μέλος @${route.paramMap.get('username')} ${suffix}`
    } else if (route.paramMap.keys.includes('hashtag')) {
      param = `#${route.paramMap.get('hashtag')} ${suffix}`
    } else if (route.paramMap.keys.includes('query')) {
      param = `Αναζήτηση "${route.paramMap.get('query')}" ${suffix}`
    } else if (route.paramMap.keys.includes('location')) {
      param = `${route.paramMap.get('location')} ${suffix}`
    }
    return param
  }
}
export function usernameMatcher(url: UrlSegment[]) {
  if (url.length === 1 && url[0].path.match(/^@.+/gm)) {
    return {
      consumed: url,
      posParams: {
        username: new UrlSegment(url[0].path.slice(1), {})
      }
    };
  }
  return null;
}
export function usernameChildMatcher(url: UrlSegment[]) {
  if (url.length === 2 && url[0].path.match(/^@.+/gm)) {    
    return {
      consumed: url,
      posParams: {
        username: new UrlSegment(url[0].path.slice(1), {}),
        request: new UrlSegment(url[1].path, {})
      }
    };
  }
  return null;
}

const suffix = "• Naked Greece"

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', title: `Naked Greece`, data: { shouldReuse: true } },
  { path: 'edit', component: EditProfileComponent, pathMatch: 'full', title: `Επεξεργασία προφίλ ${suffix}`, data: { shouldReuse: true } },
  { path: 'change-pass', component: ChangePassComponent, pathMatch: 'full', title: `Αλλαγή κωδικού πρόσβασης ${suffix}` },
  { path: 'search', component: SearchComponent, pathMatch: 'full', title: `Αναζήτηση ${suffix}`, data: { shouldReuse: true } },
  { path: 'search/query', component: SearchQueryComponent, title: `Αναζήτηση ${suffix}`, data: { shouldReuse: true } },
  { path: 'search/results/:query', component: SearchResultsComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { path: 'new-post', component: NewPostComponent, pathMatch: 'full', title: `Νεα ανάρτηση ${suffix}` },
  { path: 'activity', component: ListComponent, pathMatch: 'full', title: `Δραστηριότητα ${suffix}`, data: { shouldReuse: true } },
  { path: 'invitations', component: InvitationsComponent, pathMatch: 'full', title: `Προσκλήσεις ${suffix}`, data: { shouldReuse: true }, canActivate: [UnAuthGuard] },
  { path: 'qr', component: QrCodeComponent, pathMatch: 'full', title: `Προσκλήσεις ${suffix}`, data: { shouldReuse: true }, canActivate: [UnAuthGuard] },
  { path: 'blocked', component: ListComponent, pathMatch: 'full', title: `Μπροκαρισμένα μέλη ${suffix}`, canActivate: [UnAuthGuard] },
  { path: 'login', component: LoginComponent, pathMatch: 'full', title: `Συνδεθείτε ${suffix}`, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent, title: `Εγγραφή ${suffix}` , pathMatch: 'full', canActivate: [AuthGuard]  },
  { path: 'pwa', component: PwaComponent, title: `PWA status ${suffix}` , pathMatch: 'full' },
  { path: 'reports', component: AdminToolsComponent, title: `Reports ${suffix}` , pathMatch: 'full', data: { shouldReuse: true }, canActivate: [AdminGuard]},
  { path: 'user-requests', component: AdminToolsComponent, title: `User Requests ${suffix}` , pathMatch: 'full', data: { shouldReuse: true }, canActivate: [AdminGuard]},
  { path: 'tag/:hashtag', component: HashtagLocationComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { path: 'l/:location', component: HashtagLocationComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { path: 'p/:postRef', component: SinglePostComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { path: 'p/:postRef/:request', component: ListComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { matcher: usernameMatcher, component: ProfileComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { matcher: usernameChildMatcher, component: ListComponent, pathMatch: 'full', title: ResolveChildAtTitle, data: { shouldReuse: true } },
  { path: '__/auth/action', component: MailActionHandlerComponent, pathMatch: 'full', title: ResolveChildAtTitle},
  { path: '**', component: Page404Component, pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      // onSameUrlNavigation: 'reload', // no need due to RouteReuseStrategy (I think)
      relativeLinkResolution: 'legacy'
    }
  )],
  exports: [RouterModule],
  providers: [AuthGuard, UnAuthGuard, AdminGuard]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/core.service';
import { report, reports, userRequest, userRequests } from 'src/app/interfaces/interfaces';
import { ApiClient } from 'src/app/services/api-client.service';

@Component({
  selector: 'app-admin-tools',
  templateUrl: './admin-tools.component.html',
  styleUrls: ['./admin-tools.component.scss']
})
export class AdminToolsComponent implements OnInit {

  constructor(private router: Router, private apiClient: ApiClient, private core: CoreService) { }

  route: "reports" | "requests"
  reports: report[]
  requests: userRequests

  ngOnInit(): void {
    // Handle reports url
    if (this.router.url.includes('reports')) {
      this.getReports()
    } 
    // Handle requests url
    else {
      this.getUserRequests("pending")
    }
  }

  getReports() {
    this.apiClient.getReports()
    .subscribe((reports: any) => {
      console.log(reports);
      this.reports = reports.data;
      this.route = "reports"
    });
  }

  getUserRequests(status:userRequest['status']) {
    this.apiClient.getUserRequests(status)
    .subscribe(req => {
      this.requests = req
      this.route = "requests"
      console.log(req);
      this.requests = req
      this.route = "requests"
    });
  }

  acceptUser(username:string, accept:boolean, index) {
    // Confirm action
    if(confirm(`Are you sure you want to ${accept ? 'ACCEPT' : 'REJECT'} @${username}?`)) {

      this.apiClient.acceptUser(username, accept)
        .subscribe({
          next: res => {
            console.log(res);
            // Remove request from list
            this.requests.data.splice(index, 1);
            this.core.snackBarNotification(`User ${accept ? 'accepted' : 'rejected'}`)
          },
          error: err => {
            this.core.snackBarNotification("Error accepting user")
          }
        })
    }    
  }

  sort() {
    if (this.reports) {
      this.reports.reverse()
    } else if (this.requests) {
      this.requests.data.reverse()
    }
  }

  checkMailVerifications() {
    this.apiClient.updateEmailVerifications()
      .subscribe(res => {
        if (res) {
          this.ngOnInit()
          this.core.snackBarNotification("Email verifications checked")
        } else {
          this.core.snackBarNotification("Error 🪲")
        }
      })
  }

}

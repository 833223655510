<div *ngIf="tag">
  <header class="left-title">
    <mat-toolbar class="toolbar">
      <mat-toolbar-row>
        <!-- Start -->
        <!-- Back Button -->
        <button mat-button mat-icon-button (click)="location.back()">
          <mat-icon id="back">arrow_back_ios</mat-icon>
        </button>
        <!-- Profile Avatar + Name -->
        <div class="nav">
          <h3 class="user">#{{ tag.text }}</h3>
        </div>
        <!-- End -->
        <div>
          <button mat-icon-button id="more" class="pointer">
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- <button mat-icon-button id="more" class="pointer" [matMenuTriggerFor]="menu.menu" >
            <mat-icon>more_vert</mat-icon>
          </button>
          <app-more-menu #menu='moreMenu' targetType="user" [targetRef]="user.username" [blocked]="blocked" [self]="user.self">
          </app-more-menu> -->
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </header>

  <main>
    <div class="top">
      <div [ngClass]="['avatar', 'avatar-80', 'hashtag-avatar']">
        <img [src]="tag.avatar"
          onError="this.src='./assets/hashtag.svg'">
      </div>
      <section class="details">
        <h2 class="user">#{{ tag.text }}</h2>
        <p>{{ tag.count | number: '1.' }} αναρτήσεις</p>
      </section>
    </div>
  </main>

  <section #tabSection class="posts" [class.show]="requestParam == 'hashtag'">
    <mat-tab-group color="accent" *ngIf="tag.posts.data" [style.min-height.px]="core.windowHeight - 110" [disablePagination]="true" [disableRipple]="true" (selectedTabChange)="tabChange($event)">
      <mat-tab label="topPosts">
        <ng-template mat-tab-label>
          <mat-icon>whatshot_outlined</mat-icon>
          <span class="icon-text-space">Κορυφαίες</span>
        </ng-template>
        <div class="grid-spacer" [ngClass]="{'grid-spacer-sticky': sticky}"></div>
        <app-grid [postsSource]=requestParam [query]="tag.text" sorting="Score" [firstPosts]="tag.posts.data" [pageSize]="pageSize"
        [scrollDisabled]="scrollDisabled || this.tabConfing[this.activeTab].scrollDisabled">
        </app-grid>
      </mat-tab>
      <mat-tab label="newPosts">
        <ng-template mat-tab-label>
          <mat-icon>schedule_outlined</mat-icon> 
          <span class="icon-text-space">Πρόσφατες</span>
        </ng-template>
        <div class="grid-spacer" [ngClass]="{'grid-spacer-sticky': sticky}"></div>
        <app-grid *ngIf="tab2Visited" [postsSource]=requestParam [query]="tag.text" sorting="Freshness" [pageSize]="pageSize"
        [scrollDisabled]="scrollDisabled || this.tabConfing[this.activeTab].scrollDisabled">
        </app-grid>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

<div *ngIf="loc">
  <header class="left-title">
    <mat-toolbar class="toolbar">
      <mat-toolbar-row>
        <!-- Start -->
        <!-- Back Button -->
        <button mat-button mat-icon-button (click)="location.back()">
          <mat-icon id="back">arrow_back_ios</mat-icon>
        </button>
        <!-- Profile Avatar + Name -->
        <div class="nav">
          <h3 class="user">{{ apiClient.locations[loc.name] }}</h3>
        </div>
        <!-- End -->
        <div>
          <button mat-icon-button id="more" class="pointer">
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- <button mat-icon-button id="more" class="pointer" [matMenuTriggerFor]="menu.menu" >
            <mat-icon>more_vert</mat-icon>
          </button>
          <app-more-menu #menu='moreMenu' targetType="user" [targetRef]="user.username" [blocked]="blocked" [self]="user.self">
          </app-more-menu> -->
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </header>

  <main>
    <div class="top">
      <div [ngClass]="['avatar', 'avatar-80', 'location-avatar']">
        <img class="avatar-svg" src="./assets/location.svg">
      </div>
      <section class="details">
        <h2 class="user">{{ apiClient.locations[loc.name] }}</h2>
        <p>{{ loc.users.count | number: '1.' }} μέλη</p>
        <p>{{ loc.posts.count | number: '1.' }} αναρτήσεις</p>
      </section>
    </div>
  </main>

  <section #tabSection class="posts" [class.show]="requestParam == 'location'">
    <mat-tab-group color="accent" *ngIf="loc.posts.data" [style.min-height.px]="core.windowHeight - 110" [disablePagination]="true" [disableRipple]="true" (selectedTabChange)="tabChange($event)">
      <mat-tab label="topPosts">
        <ng-template mat-tab-label>
          <mat-icon>whatshot_outlined</mat-icon>
          <span class="icon-text-space">{{ loc.posts.count | number: '1.' }}</span>
        </ng-template>
        <div class="grid-spacer" [ngClass]="{'grid-spacer-sticky': sticky}"></div>
        <app-grid [postsSource]=requestParam [query]="loc.name" [firstPosts]="loc.posts.data" [pageSize]="pageSize"
        [scrollDisabled]="scrollDisabled || this.tabConfing[this.activeTab].scrollDisabled">
        </app-grid>
      </mat-tab>
      <mat-tab label="users">
      <ng-template mat-tab-label>
        <mat-icon>group</mat-icon>
        <span class="icon-text-space">{{ loc.users.count | number: '1.' }}</span>
      </ng-template>
      <mat-list infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="scrollDisabled || tabConfing['users'].tabScrollDisabled" (scrolled)="onScroll()">
        <mat-list-item *ngFor="let res of loc.users.data; let i = index" class="pointer">
          <div mat-list-icon [ngClass]="['avatar', 'avatar-54', 'sex-' + res.profile.sex]">
            <img [routerLink]="['/@' + res.username]" src={{apiClient.userAvatar(res.username,res.profile.avatar,90)}}>
          </div>
          <div mat-line [routerLink]="['/@' + res.username]" id="title">@{{res.username}}</div>
          <div mat-line [routerLink]="['/@' + res.username]" id="subtitle">
              {{res.profile.birthdate | age}} 
              <span *ngIf="res.profile.birthdate2">| {{res.profile?.birthdate2 | age}}</span> 
              {{apiClient.locations[res.profile.location]}}
          </div>
          <div *ngIf="!res.self" class="follow-button">
            <button *ngIf="!(res.followingThem)" mat-flat-button color="accent"
            (click)="followUnfollow(res.username, i)">{{res.followingMe ? "Ακολ/\στε Πίσω" : "Ακολουθήστε"}}</button>
            <button *ngIf="res.followingThem" mat-stroked-button color="accent"
            (click)="followUnfollow(res.username, i)">{{res.followingMe ? "Φίλοι!" : "Ακολουθείτε"}}</button>
        </div>
        </mat-list-item>
        <p class="padded-text" *ngIf="loc.users.count == 0">Δεν υπάρχουν μέλη για 
          <br>
          "{{searchQuery}}"
        </p>
      </mat-list>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

<app-page404 *ngIf="is404"></app-page404>
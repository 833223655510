import { Pipe, PipeTransform } from '@angular/core';
import { CoreService } from '../core/core.service';
import { AuthenticationService } from '../services/authentication.service';

@Pipe({
  name: 'linkedText'
})

export class LinkedTextPipe implements PipeTransform {
  constructor(private core: CoreService, private auth: AuthenticationService) {}

  transform(text: string, noDecoration: "no-decoration" | null): string {

    const tags = text.replace(this.core.regex.hashtags, 
      (match) => `<a class="underlined" href="/tag/${match.replace('#','').toLocaleLowerCase()}">${match}</a>`)

    const me = this.auth.userObject.username ? `@${this.auth.userObject.username}` : null
    const tagsMentions = tags.replace(this.core.regex.ats, 
      (match) => `<a ${noDecoration ? '' : 'class="underlined' + (match.toLocaleLowerCase() == me ? ' self-mention"' : '"')} href="/${match.toLocaleLowerCase()}">${match}</a>`)
    // console.warn(tagsMentions);
    
    return tagsMentions
  }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timePassed'
})
export class TimePassedPipe implements PipeTransform {

    transform(date: any, args?: any): any {
        const postDate = new Date(date)
        const nowDate = new Date()
        // console.log(postDate);
        const diffMs = Math.abs(nowDate.getTime() - postDate.getTime())
        const sec = Math.floor(diffMs / 1000) + 1 // add a second so new comments don't have 0 (it doesn't show)
        const min = Math.floor(sec / 60)
        const hours = Math.floor(min / 60)
        const days = Math.floor(hours / 24)
        const weeks = Math.floor(days / 7)
        // const months = Math.floor(weeks / 4.34)
        // const year = Math.floor(months / 12)
        // If >1 week ago (A/B test, what feels more current?), return date (e.g. 17 Oct)
        // Add year (e.g. 17 Oct 2020) if != currentYear
        if (weeks > 1) {
            const date = postDate.toLocaleString("el-GR", { day:'numeric', month: 'short'})
            const postYear = postDate.getFullYear()
            const year = postYear !== nowDate.getFullYear() ? postYear : ""
            return date + " " + year
        } else if (weeks) {
            return weeks + " εβδ."
        } else if (days) {
            return days + " ημ."
        } else if (hours) {
            return hours + " ω."
        } else if (min) {
            return min + " λ."
        } else if (sec) {
            return sec + " δ."
        }
    }
}
<!-- 
  To DEMO those pages: 

  http://localhost:4201/__/auth/action?mode=verifyEmail&oobCode=code 
  
  just change "mode"
  and promise.catch to "false" on the .ts file
-->

<header>
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <img id="logo" src="assets/naked-greece-h.png">
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<div *ngIf="!loading;else spinner">
  <section [ngSwitch]="params.mode">

    <div *ngSwitchCase="'resetPassword'">
      <h2>Αλλαγή κωδικού πρόσβασης</h2>
      <div *ngIf="expiredOrUsed;else showForm">
        <p>Αυτός ο σύνδεσμος έχει λήξει ή εχει ήδη χρησιμοποιηθεί.</p>
      </div>
      <ng-template #showForm>
        <form [formGroup]="form" (ngSubmit)="handleResetPassword()">
          <mat-form-field hideRequiredMarker>
            <input matInput formControlName="password" [type]="showPass ? 'text' : 'password'" placeholder="Νέος κωδικός πρόσβασης" autocomplete="off"/>
            <mat-icon matSuffix (click)="showPass = !showPass">{{showPass ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-error>
              Χρησιμοποιήστε 8 ή περισσότερους χαρακτήρες.
              <!-- με έναν συνδυασμό γραμμάτων και αριθμών. -->
            </mat-error>
          </mat-form-field>
          <mat-error>{{ serverError }}</mat-error>

          <button class="submit warn" mat-raised-button type="submit" [disabled]="formLoading || form.invalid || formLoading">
            Αλλαγή κωδικού
          </button>
      </form>
      </ng-template>
    </div>

    <div *ngSwitchCase="'recoverEmail'">
      <h2>Επαναφορά διεύθυνσης email</h2>
      <div *ngIf="expiredOrUsed;else showRecover">
        <p>Αυτός ο σύνδεσμος έχει λήξει ή εχει ήδη χρησιμοποιηθεί.</p>
      </div>
      <ng-template #showRecover>
        <mat-icon class="accept">check</mat-icon>
        <p>Η διεύθυνση <span style="font-weight: 600;">{{ mail }}</span> έχει επανέλθει ως η διεύθυνση διαχείρισης του λογαριασμού σας.</p>
        <hr>
        <p>Αν υποψιάζεστε οτι κάποιος συνδέθηκε με τον κωδικό σας, καλύτερα να κάνετε αλλαγή κωδικού πρόσβασης.</p>
        <button class="warn" mat-raised-button [disabled]="disabledButton" (click)="changePass()">
          Αλλαγή κωδικού πρόσβασης
        </button>
        <p class="warn">{{ serverError }}</p>
      </ng-template>
    </div>

    <div *ngSwitchCase="'verifyEmail'">
      <div *ngIf="expiredOrUsed;else showVerify">
        <h2>Επιβεβαίωση διεύθυνσης email</h2>
        <p>Αυτός ο σύνδεσμος έχει λήξει ή εχει ήδη χρησιμοποιηθεί.</p>
      </div>
      <ng-template #showVerify>
        <h2>Το email σας επιβεβαιώθηκε</h2>
        <mat-icon class="accept">check</mat-icon>
        <!-- <p>Η διεύθυνση σας <span style="font-weight: 600;">{{ mail }}</span> επαληθεύτηκε.</p> -->
        <!-- <p>Σύντομα κάποιος διαχειριστής θα εξετάσει τον λογαριασμό σας.</p> -->
        <p>Θα ενημερωθείτε εντός 24ων ωρών για την αποδοχή ή την απόρριψη του λογαριασμού σας, απο κάποιο μέλος της ομάδας μας.</p>
        <br>
        <!-- <a [routerLink]="[ '/login' ]" class="accent">Επιστροφή στο Naked Greece</a> -->
      </ng-template>
    </div>

    <div *ngSwitchDefault>
      <h2>Βεβαιωθείτε οτι το link είναι σωστό.</h2>
    </div>

  </section>
</div>

<ng-template #spinner>
  <mat-spinner *ngIf="loading" color="accent" [diameter]="30"></mat-spinner>
</ng-template>

<header class="center-title">
    <mat-toolbar class="toolbar">
      <mat-toolbar-row>
        <!-- Back -->
        <button *ngIf="inputFocused" mat-button mat-icon-button (click)="back()">
          <mat-icon id="back">arrow_back_ios</mat-icon>
        </button>
        <!-- input -->
        <mat-form-field id="search-bar" [floatLabel]="'never'">
          <mat-icon matPrefix class="prefix">search</mat-icon>
          <input matInput type="search" placeholder="Αναζήτηση" (focus)="queryPage()">
          <button *ngIf="inputFocused" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <!-- search button -->
        <button mat-button color="accent" *ngIf="inputFocused" class="searchBtn" [disableRipple]="true" (click)="fullSearch()">Αναζήτηση</button>
      </mat-toolbar-row>
    </mat-toolbar>
</header>

<section>
    <div class="competition">
      <div>
        Εδω θα εμφανίζονται οι Φωτογραφικές προκλήσεις του μήνα.
      </div>
    </div>
    <div class="title">Πιο ενεργά hashtags της ημέρας</div>
    <div class='row' *ngFor="let tag of tags">
        <div class="hash" [routerLink]="['/tag/', tag.name]">
          <span class="name">#{{tag.name}}</span>
          <div>
            {{tag.count | number: '1.'}}
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
        <app-horizontal [tag]="tag"></app-horizontal>
    </div>
</section>

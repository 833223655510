import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { CoreService } from 'src/app/core/core.service';
import { ApiClient } from 'src/app/services/api-client.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  dialogType: string = this.data.type
  password = '';

  form: UntypedFormGroup;
  fc: any;
  avatar: string
  @ViewChild('textarea') textarea: ElementRef;
  charCount: number = 0;
  maxChars: number = 200;
  maxLines: number = 3;
  
  constructor(
    public core: CoreService,
    private apiClient: ApiClient,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: "passwordConfirmation" | "wrongPass" | "info" | 
      'deleteConfirmation' | "blockConfirmation" | "saveBeforeAction" | 
      "firstVisit" | "updatePost" | "tos" | "iOSHow2Install",
      message: string,
      block: boolean,
      user: string,
      visibility:string,
      isAvatar: boolean,
      postRef: string
    }
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    
    if (this.data && this.data.type == "updatePost") {
      this.form = this.fb.group({
        title: [this.data.message, [Validators.maxLength(this.maxChars)]],
        visibility: [this.data.visibility, [Validators.required]]
      });
      this.lineBreakLimits(null)
      this.fc = this.form.controls;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get title() {
    return this.form.get('title').value;
  }

  hashtagMentionConfig = {
    items: [],
    triggerChar: '#',
    returnTrigger: true,
    disableSearch: true,
    disableSort: true,
    labelKey: 'text',
  };

  userMentionConfig = {
    items: [],
    triggerChar: '@',
    returnTrigger: true,
    disableSearch: true,
    labelKey: 'username',
    disableSort: true,
  };

  mentionConfig = {
    mentions: [this.hashtagMentionConfig, this.userMentionConfig]
  };

  search(term: string) {
    const trigger = term[0]
    this.getMentions(term).subscribe(terms=>{
      if (trigger === '@') {
        this.userMentionConfig.items = terms
      } else {
        this.hashtagMentionConfig.items = terms
      }      
      this.mentionConfig = {
        mentions: [this.hashtagMentionConfig, this.userMentionConfig]
      };
    })
  }

  getMentions(term:string) : Observable<any> {
    const trigger = term[0]
    const query = term.slice(1).toLowerCase()
    if (trigger == "@") {
      return this.apiClient.search(query, "users", undefined, 5, 0)
        .pipe(map(res => res.users.data))
    } else {
      return this.apiClient.search(query, "hashtags", undefined, 5, 0)
        .pipe(map(res => res.hashtags.data))
    }
  }

  oldTitle = ''
  // Limit title max lines and consecutive newlines
  lineBreakLimits(event) {
    let title: string = this.title;
    // Escape error for accents on Mac
    if (title == this.oldTitle) {
      return
    }
    // Allow only one consecutive newline
    title = title.replace(/(\r\n|\r|\n)\1+/gm, '\n');
    // Limit text lines to maxLines (25)
    const lines: string[] = title.split(/\r\n|\r|\n/);

    // Update values
    const newTitle = lines.slice(0, this.maxLines).join("\n")
    this.form.get('title').setValue(newTitle);
    this.oldTitle = newTitle
    // Count characters
    this.charCount = title.length;
    // --- Adjust textarea height ---
    setTimeout(() => {
      let element = this.textarea.nativeElement;
      element.style.height = 0;
      element.style.height = element.scrollHeight - 4 + 'px';
    }, 10);
  }

  visibilityNotification(key) {
    if (this.data.isAvatar && key != 'public') {
      this.core.snackBarNotification(
        'Η φωτογραφια προφίλ σας δεν μπορεί να έχει περιορισμένη ορατότητα.',
        null,
        4000
      );
    }
  }

  postUpdated() {
    this.apiClient.updatePost(this.data.postRef, this.title, this.form.get('visibility').value)
    .subscribe({
      next: res => {
        if (res.data) {
          this.core.reloadCurrentRoute()
          this.dialogRef.close()
        }
        else this.core.snackBarNotification("Υπήρξε κάποιο σφάλμα, δοκιμάστε ξανά αργότερα.")
      },
      error: () => this.core.snackBarNotification("Υπήρξε κάποιο σφάλμα, δοκιμάστε ξανά αργότερα.")
    })
  }


}

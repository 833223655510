<div class="background-blur" [style.background-image]="'url(' + imgURI + ')'"></div>

<ng-container *ngIf="(auth.authState | async).status == 'verified';else unverified">
  <section class="main">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class='padbot'>
        <mat-label>Επιλέξτε μια φωτογραφία σας:</mat-label>

        <button class='imageBtn' type="button" color="accent" mat-flat-button (click)="photoInput.click()">Επιλογή</button>
        <!-- <input type="file" ng2FileSelect [uploader]="uploader" /> -->
        <input hidden formControlName="photoInput" (change)="onFileSelected($event)" #photoInput type="file" id="file"
          accept="image/png,image/jpeg" >

        <p class="error" *ngIf="form.controls['photoInput'].hasError('required') && form.controls['photoInput'].touched">
          Ξεχάσατε να επιλέξετε φωτογραφία!
        </p>
        <p class="error" *ngIf="imageLarge">
          Το αρχείο πρέπει να έχει μέγεθος λιγότερο απο {{ maxFileSizeMB }}MB.
        </p>
        <p class="error" *ngIf="imageWrongExt">
          Το αρχείο πρέπει να είναι της μορφής .jpg/jpeg ή .png.
        </p>
      </div>
      <!-- <img *ngIf="imgURI" class="padbot preview" [src]="imgURI" height="200">
      <canvas #myCanvas width=200 height=200></canvas> -->
      <app-upload-canvas></app-upload-canvas>

      <mat-form-field>
        <textarea #textarea matInput formControlName="title" type="text" placeholder="Κείμενο φωτογραφίας"
          [mentionConfig]="mentionConfig" (searchTerm)="search($event)" autocomplete="off" 
          (input)="lineBreakLimits($event)" [mentionListTemplate]="mentionTemplate">
        </textarea>
        <mat-hint [style.color]="charCount > maxChars ? 'red' : 'inherit' " align="end">
          {{ charCount }}/{{ maxChars }}
        </mat-hint>
        <mat-hint align="start">Πληκτρολογηστε # για hashtags και @ για αναφορά σε μέλος.</mat-hint>
      </mat-form-field>

      <div class="checkboxes padbot" *ngIf="imgURI">
        <mat-checkbox formControlName="avatar">Να γίνει φωτογραφία προφίλ</mat-checkbox>

        <mat-checkbox formControlName="tos">
          Επιβεβαιώνω οτι η φωτογραφία απεικονίζει εμένα ή οτι την έχω τραβήξει εγώ, και την κοινοποιώ με συναίνεση των ατόμων που απεικονίζονται,
          όλοι εκ των οποίων ήταν τουλάχιστον 18 ετών κατά την λήψη της.
        </mat-checkbox>

        <div class="post-visibility">
          <div matPrefix>
            <mat-icon>visibility</mat-icon>
            <span>Ορατότητα:</span>
          </div>
          <mat-select formControlName="visibility" (click)="visibilityNotification()">
            <mat-option *ngFor='let v of visibility' [value]="v.id">
              {{v.full}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <mat-error class="server-error">{{ serverMessage }}</mat-error>

      <div *ngIf="imgURI && uploadCanvas.uploader.progress as progress" class="padbot progress-bar">
        <mat-progress-bar color="accent" [value]="progress"></mat-progress-bar>
        {{ progress | number }}%
      </div>

      <button mat-flat-button color="accent" type="submit" [disabled]="loading || form.invalid">
        Κοινοποίηση
      </button>
    </form>

  </section>

  <ng-template #mentionTemplate let-item="item">
      <div *ngIf="item.username">
          <div [ngClass]="['avatar avatar-26', 'sex-' + item.profile.sex]">
            <img [src]="apiClient.userAvatar(item.username, item.profile.avatar, 90)">
          </div>
          &nbsp;
          <span>@{{ item.username }}</span>
      </div>
      <div *ngIf="item.text">
        <div class="avatar avatar-26 hashtag-avatar">
          <img class="avatar-svg" src="./assets/hashtag.svg">
        </div>
        &nbsp;
        <div class="flex-apart">
          <span>#{{ item.text }}</span>
          <!-- <mat-progress-bar class="mention-tag-count hashtag-bar" mode="determinate" [value]="(item.count / 10)*100"></mat-progress-bar> -->
          <span class="mention-tag-count">{{ item.count | number: '1.' }}</span>
        </div>
      </div>
  </ng-template>
</ng-container>

<ng-template  #unverified>
  <app-guest-page></app-guest-page>
</ng-template>

<div [ngSwitch]="dialogType">

    <ng-template [ngSwitchCase]="'passwordConfirmation'">
        <!-- <h1 mat-dialog-title>WARNING!</h1> -->
        <div mat-dialog-content>
            <p>
                Επαληθεύστε τον τρέχων κωδικό πρόσβασης σας:
            </p>
            <mat-form-field>
                <input matInput type="password" placeholder="Κωδικός" [(ngModel)]="password">
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">Άκυρο</button>
            <button mat-stroked-button [mat-dialog-close]="password">OK</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'wrongPass'">
        <p mat-dialog-content>
            Ο κωδικός που εισάγατε ήταν λάθος. <br>
            Δοκιμάστε ξανά.
        </p>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">ΟK</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'info'">
        <mat-dialog-content>
            <div  [innerHtml]="data.message">
                <!-- {{ data.message }} -->
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">ΟK</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'iOSHow2Install'">
        <h1 mat-dialog-title>Εγκατάσταση σε iOS</h1>
        <mat-dialog-content>
            <div class="safari-how2">
                <strong>Για να προσθέσετε το Αpp στο iPhone/iPad σας:</strong>
                <ol>
                    <li *ngIf="data.message == 'notSafari'">
                        Επισκεφθείτε το naked-greece.com απο το Safari
                        <img class="nudgeImage" height="22" alt="Share Button Image" src="/assets/safari-icon.png"> 
                    </li>
                    <li>
                        Πατήστε στην «Κοινή χρήση»
                        <img class="nudgeImage" height="22" alt="Share Button Image" src="/assets/safari-share-icon.png"> 
                    </li>
                    <li>
                        Κάντε scroll και επιλέξτε «Προσθήκη στην οθόνη Αφετηρίας»
                        <img class="nudgeImage" height="22" alt="Share Button Image" src="/assets/safari-add-to-home.png"> 
                    </li>
                </ol>
              </div>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">ΟK</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'deleteConfirmation'">
        <!-- <h1 mat-dialog-title>WARNING!</h1> -->
        <div mat-dialog-content>
            <p>
                Είστε σίγουροι οτι θέλετε να γίνει διαγραφή?
            </p>
        </div>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">Άκυρο</button>
            <button mat-flat-button class="warn" mat-dialog-close="delete">Ναι</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'blockConfirmation'">
        <!-- <h1 mat-dialog-title>WARNING!</h1> -->
        <div mat-dialog-content *ngIf="data.block">
            <h3>
                Είστε σίγουροι οτι θέλετε να μπλοκάρετε τον/την @{{ data.user }}?
            </h3>
            <div>
                Μετά το μπλοκάρισμα δεν θα μπορείτε να:
                <ul class="flat-list">
                    <li>δείτε ο ένας το προφίλ του άλλου</li>
                    <li>δείτε ή αλληλεπιδράσετε ο ένας με τις αναρτήσεις του άλλου</li>
                    <li>στείλετε μηνύματα μεταξύ σας</li>
                </ul>
            </div>
        </div>
        <div mat-dialog-content *ngIf="!data.block">
            <h3>
                Είστε σίγουροι οτι θέλετε να ξεμπλοκάρετε τον/την @{{ data.user }}?
            </h3>
            <p style="text-align: justify;">
                Το μέλος θα μπορεί ξανά να αλληλεπιδράσει με το προφίλ σας και εσείς με το δικό του.
            </p>
        </div>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">Άκυρο</button>
            <button mat-flat-button class="warn" mat-dialog-close="ok">Ναι</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'saveBeforeAction'">
        <mat-dialog-content>
            <div  [innerHtml]="data.message">
                <!-- {{ data.message }} -->
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <button mat-stroked-button class="warn" mat-dialog-close="no">Οχι</button>
            <button mat-stroked-button mat-dialog-close="yes">Ναι</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'firstVisit'">
        <div class="first-visit" mat-dialog-content>
            <img src="./assets/naked-greece-v.png">
            <p>
                Καλωσορίσατε στο Naked Greece,
                <br>
            <!-- 
                μια sex-positive κοινότητα που δημιουργήθηκε για να μπορούμε να εκφράζουμε την σεξουαλικότητά μας.
                
                ενα sex-positive κοινωνικό δίκτυο, φτιαγμένο για να εκφράζετε/μοιράζεστε της σεξουαλική πλευρά της ζωής σας με ανθρώπους σαν κι εσάς.
                ενα sex-positive κοινωνικό δίκτυο που δημιουργήθηκε για να μπορείτε να εκφράζετε την σεξουαλικότητα σας.
            -->
                <!-- ενα sex-positive κοινωνικό δίκτυο που δημιουργήθηκε για να μπορείτε να εκφράζετε την σεξουαλική πτυχή του εαυτού σας. -->
                ενα sex-positive κοινωνικό δίκτυο που δημιουργήθηκε για να μπορείτε να εκφράζετε τις σεξουαλικές πτυχές του εαυτού σας.
            </p>
        </div>
        <div class="first-visit-actions" mat-dialog-actions>
            <p class="pointer" (click)="core.showTos()">Όροι χρήσης</p>
            <button mat-flat-button color="accent" (click)="onNoClick()">Είσοδος</button>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'updatePost'">
        <h1 mat-dialog-title>Επεξεργασία ανάρτησης</h1>
        <div class="update-post" mat-dialog-content>
            <form [formGroup]="form">
                <mat-form-field>
                    <textarea #textarea matInput formControlName="title" type="text" placeholder="Κείμενο φωτογραφίας"
                      [mentionConfig]="mentionConfig" (searchTerm)="search($event)" autocomplete="off" 
                      (input)="lineBreakLimits($event)" [mentionListTemplate]="mentionTemplate">
                    </textarea>
                    <mat-hint [style.color]="charCount > maxChars ? 'red' : 'inherit' " align="end">
                      {{ charCount }}/{{ maxChars }}
                    </mat-hint>
                    <mat-hint align="start">Πληκτρολογηστε # για hashtags και @ για αναφορά σε μέλος.</mat-hint>
                  </mat-form-field>

                  <div class="post-visibility">
                    <div matPrefix>
                      <mat-icon>visibility</mat-icon>
                      <span>Ορατότητα:</span>
                    </div>
                    <mat-select formControlName="visibility">
                      <mat-option *ngFor='let v of apiClient.visibility | keyvalue: apiClient.sortFunct' (click)="visibilityNotification(v.key)"
                        [value]="v.key" [disabled]="data.isAvatar && v.key != 'public'">
                        {{ apiClient.visibility[v.key] }}
                      </mat-option>
                    </mat-select>
                  </div>
            </form>
        </div>
        <div mat-dialog-actions>
            <button mat-button class="warn" mat-dialog-close="yes">Άκυρο</button>
            <button mat-stroked-button (click)="postUpdated()">Αποθήκευση</button>
        </div>
    </ng-template>


    <ng-template #mentionTemplate let-item="item">
        <div *ngIf="item.username">
            <div [ngClass]="['avatar avatar-26', 'sex-' + item.profile.sex]">
            <img [src]="apiClient.userAvatar(item.username, item.profile.avatar, 90)">
            </div>
            &nbsp;
            <span>@{{ item.username }}</span>
        </div>
        <div *ngIf="item.text">
        <div class="avatar avatar-26 hashtag-avatar">
            <img class="avatar-svg" src="./assets/hashtag.svg">
        </div>
        &nbsp;
        <div class="flex-apart">
            <span>#{{ item.text }}</span>
            <span class="mention-tag-count">{{ item.count | number: '1.' }}</span>
        </div>
        </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'tos'">
        <h1 mat-dialog-title>Όροι χρήσης</h1>
        <div class="tos" mat-dialog-content>
            <h2>Συνοπτικά</h2>
            <p>
                Το Naked Greece είναι μια sex-positive κοινότητα, με έμφαση στην έκφραση και την αποδοχή του έρωτα και της σεξουαλικότητας, είτε είναι vanilla, kinky, LGBT, είτε οτιδήποτε άλλο.
            </p>
            <p>
                Στόχος μας είναι η απενοχοποίηση του γυμνού και της ερωτικής ζωής, σε ένα ασφαλές μέσο έμπνευσης και έκφρασης. Για να υποστηρίξουμε αυτή την κοινότητα, χρειαζόμαστε τη βοήθειά σας. Πρέπει να δημοσιεύετε μόνο τις δικές σας φωτογραφίες και να φέρεστε με σεβασμό στα υπόλοιπα μέλη του Naked Greece.
            </p>
            <br>
            <h2>Πιο αναλυτικά</h2>
            <ul>
                <li>
                    Κοινοποιείτε μόνο φωτογραφίες που έχετε τραβήξει οι ίδιοι ή τις οποίες έχετε το δικαίωμα να κοινοποιείτε.
                </li>
                <p>
                    Το περιεχόμενο που δημοσιεύετε στο Naked Greece σάς ανήκει. Δεν επιτρέπεται να δημοσιεύετε περιεχόμενο που έχετε αντιγράψει ή συγκεντρώσει από το Internet και το οποίο δεν έχετε δικαίωμα να δημοσιεύσετε.
                </p>
            </ul>
            <ul>
                <li>
                    Οποιοδήποτε άτομο απεικονίζεται σε φωτογραφία που κοινοποιείτε, πρέπει να έχει συναινέσει στην κοινοποίηση της φωτογραφίας και να ήταν ενήλικο κατα την λήψη της.
                </li>
                <p>
                    Σε ό,τι αφορά την κοινοποίηση σεξουαλικού περιεχομένου που αφορά ανηλίκους και τις απειλές περί δημοσίευσης περιεχομένου που αφορά προσωπικές στιγμές τρίτων, το Naked Greece έχει μηδενική ανοχή.
                </p>
            </ul>
            <ul>
                <li>
                    Να σέβεστε τα υπόλοιπα μέλη της κοινότητας του Naked Greece.
                </li>
                <p>
                    Θέλουμε να ενθαρρύνουμε τη δημιουργία μιας θετικής και ποικιλόμορφης κοινότητας. Δεν επιτρέπουμε περιεχόμενο που περιλαμβάνει ρητορικές μίσους ή απειλές, το περιεχόμενο που στοχεύει μεμονωμένα άτομα με σκοπό να τα ταπεινώσει ή να τα εξευτελίσει, την δημοσίευση προσωπικών πληροφοριών (doxxing) που έχουν στόχο να εκβιάσουν ή να παρενοχλήσουν άλλους και τα επαναλαμβανόμενα ανεπιθύμητα μηνύματα.
                </p>
            </ul>
            <ul>
                <li>
                    Δεν επιτρέπεται η προώθηση επι πληρωμή περιεχομένου όπως συνδρομές σε πλατφόρμες (OnlyFans, MYM κλπ.), links μεταφοράς χρημάτων (PayPal, ApplePay κλπ.) ή λίστες δώρων (Amazon, Throne κλπ.).
                </li>
                <p>
                    Σεβόμαστε και υποστηρίζουμε τους ανθρώπους που επιθυμούν να επωφελούνται οικονομικά απο το ερωτικό τους περιεχόμενο. Στόχος του Naked Greece είναι η δημιουργία ενός χώρου για ανθρώπους που αγαπούν να εκφράζουν και να μοιράζονται την ερωτική τους πλευρά, με κύριο κίνητρο την διαδικασία καθεαυτή. Άτομα που απολαμβάνουν την δημιουργία ερωτικού περιεχομένου, ανεξάρτητα με το αν επωφελούνται και οικονομικά απο αυτήν, είναι πάντα ευπρόσδεκτα στο Νaked Greece - εφόσον το οικονομικό όφελος δεν καθιστά προϋπόθεση για αυτά.                </p>
            </ul>
            <ul>
                <li>
                    Δεν επιτρέπεται η προσφορά ή ζήτηση σεξουαλικών πράξεων για αντάλλαγμα.
                </li>
                <p>
                    Σεβόμαστε και υποστηρίζουμε τους/τις εργαζόμενους στη βιομηχανία του σεξ. Όμως, λόγω του κινδύνου που εγκυμονεί η εμπορική ανταλλαγή σεξουαλικών πράξεων, αφαιρούμε κάθε ανάρτηση στην οποία κάποιος προσφέρεται είτε να αγοράσει είτε να πουλήσει σεξουαλικές πράξεις για αντάλλαγμα.                </p>
            </ul>
            <p>
                Επαναλαμβανόμενες ή και μεμονωμένες (για πιο σοβαρά ζητήματα) παραβάσεις, μπορούν να οδηγήσουν σε αφαίρεση του μέλους απο την κοινότητα και τη διαγραφή του λογαριασμού του.
            </p>
        </div>
        <div mat-dialog-actions>
            <button mat-button (click)="onNoClick()">Κλείσιμο</button>
        </div>
    </ng-template>

</div>
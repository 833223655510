<section class="main">
    <p class="title">Εισάγετε τον νέο κωδικό πρόσβασης σας.</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <div formGroupName='passwordGroup'>
            <mat-form-field hideRequiredMarker>
                <input matInput formControlName="password" type="password" placeholder="Νέος κωδικός πρόσβασης"
                    autocomplete="new-password" name="pass"/>

                <mat-error>
                    Χρησιμοποιήστε 8 ή περισσότερους χαρακτήρες.
                    <!-- με έναν συνδυασμό γραμμάτων και αριθμών. -->
                </mat-error>
            </mat-form-field>

            <mat-form-field hideRequiredMarker>
                <input matInput formControlName="passwordConfirm" type="password" placeholder="Επανάληψη κωδικού πρόσβασης"
                    [errorStateMatcher]="errorMatcher" autocomplete="new-password" name="confPass"/>

                <mat-error *ngIf="form.controls['passwordGroup'].hasError('passwordsDoNotMatch')">
                    Οι κωδικοί δεν είναι ίδιοι.
                </mat-error>
            </mat-form-field>
        </div>

        <button class="submit warn" mat-raised-button type="submit" [disabled]="loading || form.invalid">
            Αλλαγή κωδικού
        </button>
    </form>

    <!-- TODO: Low priority - Activate forgot password here. Pass may be asked in this page. -->
    <!-- <button class="forgot" mat-button>
        Ξεχάσατε τον κωδικό σας;
    </button> -->
</section>
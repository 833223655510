import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { Scrset, Post, Posts, User, Profile, FollowUnfollow, LikeUnlike, registrationInfo, createPost, activityItems, activity, blockUnblock, registrationResponse, invitationStatus, postType, invitation, searchResults, searchType, Hashtags, Hashtag, LocationData, sortBy, reportType, ReportInput, visibility, invitations, reports, userRequests, userRequest } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiClient {

  private _popularTags;

  constructor(private httpClient: HttpClient) {}

  public createPost(title, attachment, visibility:visibility): Observable<createPost> {
    return this.httpClient.post<createPost>('/api/graphql', {
      query: `
        mutation CreatePost($postData: CreatePostInput) {
          createPost(postData: $postData) {
            ref
          }
        }`,
      variables: {
        postData: {
          title: title,
          attachment: attachment,
          visibility: visibility
        }
      }
    })
    .pipe(
      first()
    )
  }

  public updatePost(postRef, title, visibility: visibility): Observable<createPost> {
    return this.httpClient.post<createPost>('/api/graphql', {
      query: `
        mutation updatePost($updatePost: UpdatePostInput) {
          updatePost(updatePost: $updatePost)
        }`,
      variables: {
        updatePost: {
          ref: postRef,
          title: title,
          visibility: visibility
        }
      }
    })
    .pipe(
      catchError(val => {return EMPTY}),
      first()
    )
  }

  public getMe(): Observable<User> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `{
        me {
          username
          activitySubjectCountSince
          lastActivityCheck
          role
          posts {
            count
          }
          profile {
            location
            sex
            sex1
            sex2
            birthdate
            birthdate2
            orientation
            orientation2
            avatar
          }
          availableInvitations
          # activitySubject(pageSize:1, pageIndex:0) {
          #   count
          # }
        }
      }`
    })
    .pipe(
      first(),
      map(response => {
        return response.data.me
      })
    )
  }

  public getInvitations(): Observable<invitations> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `{
        me {
          invitations(pageIndex:0, pageSize:100){
            count
            data{
              ref
              created
              expiration
              invitee {
                username
              }
            }
          }
        }
      }`
    })
    .pipe(
      first(),
      map(response => {
        return response.data.me.invitations
      })
    )
  }

  public activityLiveCount(): Observable<User> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `{
        me {
          activitySubjectCountSince
          lastActivityCheck
        }
      }`
    })
    .pipe(
      // on network disconnect or other error, retry every 10 seconds instead of killing the subscription
      // retryWhen(errors => errors.pipe(delay(10000))),
      catchError(val => {return EMPTY}),
      map(response => {
        return response.data.me
      })
    )
  }

  public activityCounterReset(dateTime): Observable<boolean> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        mutation setLastActivityCheck($dateTime: ZonedDateTime) {
          setLastActivityCheck(dateTime:$dateTime)
        }`,
      variables: {
        dateTime: dateTime,
      }
    })
    .pipe(
      first()
    )
  }

  public getPopularHashtags(pageSize = 10, pageIndex = 0, returnPosts = 0): Observable<Hashtags> {
    let posts = `
      posts(pageSize:${returnPosts}, pageIndex:0) {
        data {
          ${this.singlePostDataBody}
        }
      }
    `
    if (!this._popularTags) {
      return this.httpClient.post<any>('/api/graphql', {
        query: `
          query popularHashtags($pageSize: Int, $pageIndex: Int) {
            popularHashtags(pageSize:$pageSize, pageIndex:$pageIndex) {
              count,
              data {
                count,
                text,
                avatar
                ${returnPosts ? posts : ''}
              }
            }
          }`,
        variables: {
          pageSize: pageSize,
          pageIndex: pageIndex,
        }
      }).pipe(
        first(),
        map(response => {
          console.log(response);

          if (returnPosts == 0) {
            return response.data.popularHashtags
          }

          let postsWithImages = response.data.popularHashtags.data.map(tag => { 
            return {
              ...tag,
              avatar: tag.avatar ? this.hashtagAvatar(tag.avatar) : "null:null", // add avatar token
              name: tag.text,
              posts: { data: tag.posts.data.map(post => {
                return this.postMapping(post)
              })}
            }
          })
          console.log(postsWithImages);
          
          return {
            count: response.data.popularHashtags.count, 
            data: postsWithImages
          }
        }),
        tap(x => {
          this._popularTags = x;
          console.warn("API CALL SAVED");
          setTimeout(() => {
            console.warn("API CALL DELETED");
            delete this._popularTags;
          }, 5); // TODO: Save some API calls
        })
      );
    } else {
      return of(this._popularTags);
    }
  }

  singlePostDataBody = `
    title
    ref
    created
    visibility
    token
    mentions {
      name
      status
    }
    author {
      username
      self
      profile {
        avatar
        sex
      }
    }
    likes {
      count
    }
    liked
    flatComments(pageSize:1, pageIndex:0) {
      count
      data{
        text
        ref
        author {
          username
          self
          profile {
            avatar
            sex
          }
        }
        created
      }
    }`


  public getFeed(pageSize = 10, pageIndex = 0, action = "post"): Observable<Posts> {
    return this.httpClient.post<activity>('/api/graphql', {
      query: `
        query activity($pageSize: Int, $pageIndex: Int, $action: String){
          me {
            activity(pageSize:$pageSize, pageIndex:$pageIndex, action:$action) {
              count
              data {
                action
                post {
                  ${this.singlePostDataBody}
                }
              }
            }
          }
        }`,
      variables: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        action: action
      }
    }).pipe(
      first(),
      map(response => {
        const activityItems = response.data.me.activity.data
        const posts = activityItems.map(activity => this.postMapping(activity.post))
        console.log(response.data.me.activity)
        console.log(posts)
        return {
          count: response.data.me.activity.count,
          data: posts
        }
      }),
    )
  }

  public getActivity(pageSize = 10, pageIndex = 0): Observable<activityItems> {
    return this.httpClient.post<activity>('/api/graphql', {
      query: `
        query activitySubject($pageSize: Int, $pageIndex: Int){
          me {
            activitySubject(pageSize:$pageSize, pageIndex:$pageIndex) {
              count
              data {
                action
                date
                actor{
                  username
                  profile{
                    sex
                    avatar
                  }
                  followingMe
                  followingThem
                }
                post {
                  ref
                  title
                  created
                  token
                  mentions {
                    name
                    status
                  }
                }
                comment{
                  ref
                  text
                  created
                  liked
                  author{
                    username
                  }
                  replyTo{
                    text
                    author{
                      self
                    }
                  }

                }
              }
            }
          }
        }`,
      variables: {
        pageSize: pageSize,
        pageIndex: pageIndex,
      }
    }).pipe(
      first(),
      map(res => {
        let activity = res.data.me.activitySubject
        console.log(JSON.parse(JSON.stringify(activity)));

        // filter out mentions that have a comment if that same comment activity exist
        // activity.data = activity.data.filter(a => {
        //   if (a.action !== "mention" || !a.comment || !activity.data.some(item => item.action === "comment" && item.comment.ref === a.comment.ref)) {
        //     return a
        //   }
        // })

        let filteredActivity = []
        // Filter our duplicate comments and also replace comments with mentions, where a mention exist
        activity.data.forEach(a => {
          const lastIndex = filteredActivity.length - 1
          if (lastIndex >= 0 && ['comment', 'mention'].includes(a.action) && filteredActivity[lastIndex]['date'] === a.date) {
            if (a.action === 'mention') {
              filteredActivity[lastIndex] = a
            }
            return
          }
          filteredActivity.push(a)
        })

        // "mention"s that have comment==null, are postMentions
        filteredActivity.map(activity => {
          if (activity.action == "mention" && !activity.comment) {
            activity.action = "postMention"
            // A list of usernames that are pending
            activity.post.mentionsPending =activity.post.mentions
              .filter(obj => obj.status === "pending")
              .map(obj => obj.name);
          }
        })

        // "likes" that have a comment, are commentLikes
        filteredActivity.map(activity => {
          if (activity.action == "like" && activity.comment) {
            activity.action = "commentLike"
          }
        })

        // rename "actor" with "user" because that list.component looks for "user"
        // "comment / mention" with "reply" on replyTo self
        filteredActivity.map(activity => {
          delete Object.assign(activity, {['user']: activity['actor'] })['actor'];
          if (activity.comment && activity.comment.replyTo && activity.comment.replyTo.author.self) {
            activity.action = "reply"
          }
        })
        
        return {count: filteredActivity.length, data: filteredActivity}
      })
    )
  }

  public getPosts(pageSize = 10, pageIndex = 0, sortBy:sortBy = "Score"): Observable<Posts> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query posts($pageSize: Int, $pageIndex: Int, $sortBy: SortBy){
          posts(pageSize:$pageSize, pageIndex:$pageIndex, sortBy:$sortBy) {
            count
            data {
              ${this.singlePostDataBody}
            }
          }
        }`,
      variables: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy
      }
    }).pipe(
      first(),
      map(response => {
        let posts: Post[] = response.data.posts.data
        response.data.posts.data = posts.map(post => this.postMapping(post))
        console.log(response.data.posts)
        return response.data.posts
      }),
    )
  }

  public getPost(postRef): Observable<Post> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query post($ref: String){
          post(ref:$ref) {
            ${this.singlePostDataBody}
          }
        }`,
      variables: {
        ref: postRef
      }
    }).pipe(
      map(response => {
        return this.postMapping(response.data.post)
      }),
    )
  }

  likeUnlike(postRef: string, like:boolean): Observable<LikeUnlike> {

    return this.httpClient.post<any>('/api/graphql', {
      query: `
        mutation like($postRef: String, $like: Boolean!){
          like(postRef:$postRef, like:$like)
        }`,
      variables: {
        postRef: postRef,
        like: like
      }
    })
    .pipe(
      first(),
      map(response => response.data)
    )
  }

  public getProfile(userName, pageSize = 3, pageIndex = 0): Observable<User> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          query profile($userName: String, $pageSize: Int, $pageIndex: Int) {
            user(username: $userName) {
              username
              self
              profile{
                name
                avatar
                birthdate
                sex
                orientation
                location
                info
                sex1
                sex2
                orientation2
                birthdate2
              }
              followers {
                count
              }
              following {
                count
              }
              followingMe
              followingThem
              posts(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data{
                  ${this.singlePostDataBody}
                }
              }
              taggedPosts(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data{
                  ${this.singlePostDataBody}
                }
              }
            }
          }`,
      variables: {
        userName: userName,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }).pipe(
      first(),
      map(response => {
        let user = response.data.user
        if (user.posts && user.posts.data) { // always returns {posts: {count: 1}} because of the public profile post
          user.posts.data = user.posts.data.map(post => this.postMapping(post))
        }
        if (user.taggedPosts && user.taggedPosts.data) {
          user.taggedPosts.data = user.taggedPosts.data.map(taggedPosts => this.postMapping(taggedPosts))
        }
        console.log(response.data.user)
        return response.data.user
      })
    )
  }

  public getUserPosts(userName, pageSize = 3, pageIndex = 0, postType: postType = "posts"): Observable<User> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          query profile($userName: String, $pageSize: Int, $pageIndex: Int) {
            user(username: $userName) {
              ${postType}(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data{
                  ${this.singlePostDataBody}
                }
              }
            }
          }`,
      variables: {
        userName: userName,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }).pipe(
      map(response => {
        let posts: Post[] = response.data.user[postType].data       
        response.data.user[postType].data = posts.map(post => this.postMapping(post))
        return response.data.user
      })
    )
  }

  public getPostLikes(postRef, pageSize = 10, pageIndex = 0): Observable<any> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          query postLikes($postRef: String, $pageSize: Int, $pageIndex: Int) {
            post(ref:$postRef) {
              likes(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data{
                  date
                  user{
                    username
                    self
                    followingThem
                    followingMe
                    profile{
                      sex
                      avatar
                    }
                  }
                }
              }
            }
          }`,
      variables: {
        postRef: postRef,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    })
    .pipe(
      map(response => {
        if (response.data == null) { throw new Error("Post does not exist") }
        return response.data.post.likes
      })
    )
  }

  public getPostComments(postRef, pageSize = 10, pageIndex = 0): Observable<any> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          query postComments($postRef: String, $pageSize: Int, $pageIndex: Int) {
            post(ref:$postRef) {
              comments(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data {
                  text
                  ref
                  created
                  liked
                  likes {
                    count
                  }
                  author{
                    username
                    self
                    profile {
                      sex
                      avatar
                    }
                  }
                  post{
                    author{
                      self
                    }
                  }
                  comments(pageSize:50, pageIndex:0) {
                    count
                    data {
                      text
                      ref
                      created
                      liked
                      likes {
                        count
                      }
                      author{
                        username
                        self
                        profile {
                          sex
                          avatar
                        }
                      }
                      post{
                        author{
                          self
                        }
                      }
                    }
                  }
                }
              }
            }
          }`,
      variables: {
        postRef: postRef,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    })
    .pipe(
      map(response => {
        if (response.data.post == null) { throw new Error("Post does not exist") }
        // FIXME: simulate comment likes => replace with actuall comment likes
        response.data.post.comments.data.forEach(comment => {
          comment.likes = {count: 5}
        });
        // const comments = {
        //   data: response.data.post.comments.data,
        // }
        console.log(response.data.post.comments);

        return response.data.post.comments
      }
    ))
  }

  public createComment(postRef, text, replyRef): Observable<any> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation post($commentData: CreateCommentInput) {
            createComment(commentData:$commentData){
              created
              text
              ref
              parent {
                ref
              }
              author {
                username
                self
                profile {
                  sex
                  avatar
                }
              }
              comments {
                count
                data{
                  ref
                }
              }
              post {
                author {
                  self
                }
              }
            }
          }`,
      variables: {
        commentData: {
          postRef: postRef,
          text: text,
          replyRef: replyRef
        }
      }
    })
    .pipe(
      map(response => {
        return response.data.createComment
      })
    )
  }

  // TODO: Add to api
  // public likeUnlikeComment(commentRef): Observable<LikeUnlike> {
  //   return this.httpClient.post<any>('/api/graphql', {
  //     query: `
  //         mutation likeComment($likeComment: LikeCommentInput) {
  //           likeComment(likeComment:$likeComment)
  //         }`,
  //     variables: {
  //       likeComment: {
  //         ref: commentRef
  //       }
  //     }
  //   })
  //   .pipe(
  //     first(),
  //     map(response => response.data)
  //   )
  // }

  public deleteComment(commentRef): Observable<boolean> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation deleteComment($deleteComment: DeleteCommentInput) {
            deleteComment(deleteComment:$deleteComment)
          }`,
      variables: {
        deleteComment: {
          ref: commentRef
        }
      }
    })
    .pipe(
      map(response => {
        return response.data.deleteComment
      })
    )
  }

  public deletePost(postRef): Observable<boolean> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation deletePost($deletePost: DeletePostInput) {
            deletePost(deletePost:$deletePost)
          }`,
      variables: {
        deletePost: {
          ref: postRef
        }
      }
    })
    .pipe(
      map(response => {
        return response.data.deletePost
      })
    )
  }

  public setAvatar(postRef): Observable<Profile> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation updateProfile($profile: ProfileInput){
            updateProfile(profile: $profile){
              profile{
                name
                avatar
              }
            }
          }`,
      variables: {
        profile: {
          avatar: postRef
        }
      }
    })
    .pipe(
      first()
    )
  }

  public updateProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation post($profile: ProfileInput){
            updateProfile(profile: $profile){
              profile{
                name
                avatar
                info
                sex
                sex1
                sex2
                orientation
                orientation2
                birthdate
                birthdate2
              }
            }
          }`,
      variables: {
        profile: profile
      }
    })
    .pipe(
      first()
    )
  }

  public getFollowers(userName, pageSize = 3, pageIndex = 0): Observable<any> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          query getFollowers($userName: String, $pageSize: Int, $pageIndex: Int) {
            user(username: $userName) {
              followers(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data{
                  username
                  sex
                  user {
                    username
                    self
                    followingThem
                    followingMe
                    profile {
                      sex
                      avatar
                    }
                  }
                }
              }
            }
          }`,
      variables: {
        userName: userName,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }).pipe(
      map(response => {
        if (response.data.user == null) { throw new Error("User does not exist") }
        console.log(response.data)
        return response.data.user.followers
      })
    )
  }

  public getFollowing(userName, pageSize = 3, pageIndex = 0): Observable<any> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          query getFollowing($userName: String, $pageSize: Int, $pageIndex: Int) {
            user(username: $userName) {
              following(pageSize:$pageSize, pageIndex:$pageIndex) {
                count
                data{
                  username
                  sex
                  user {
                    username
                    self
                    followingThem
                    followingMe
                    profile {
                      sex
                      avatar
                    }
                  }
                }
              }
            }
          }`,
      variables: {
        userName: userName,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }).pipe(
      map(response => {
        if (response.data.user == null) { throw new Error("User does not exist") }
        console.log(response.data)
        // return Array(20).fill(response.data.user.following.data[0])
        return response.data.user.following
      })
    )
  }

  public followUnfollow(username): Observable<FollowUnfollow> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation follow($username: String){
            follow(username: $username)
          }`,
      variables: {
        username: username
      }
    })
  }

  public registerUser(registrationInfo: registrationInfo): Observable<registrationResponse> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation registerUser($registrationInfo: RegistrationInfoInput) {
            register(registrationInfo:$registrationInfo)
          }`,
      variables: {
        registrationInfo: registrationInfo
      }
    })
    .pipe(
      first(),
      map(res => {
        return res.data
      })
    )
  }

  public acceptUser(username: string, accepted: boolean): Observable<boolean> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation acceptUser($username: String, $accepted: Boolean!) {
            acceptUser(username:$username, accepted:$accepted)
          }`,
      variables: {
        username: username,
        accepted: accepted
      }
    })
    .pipe(
      first(),
      map(res => {
        if (res.data.acceptUser == null) { throw new Error("Error") }
        return res.data.acceptUser
      })
    )
  }

  public blockUnblock(username: string): Observable<blockUnblock> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
          mutation block($username: String) {
            block(username:$username)
          }`,
      variables: {
        username: username
      }
    })
    .pipe(
      first()
    )
  }

  public myBlocks(): Observable<User[]> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query myBlocks {
          blocks {
            username
            profile{
              sex
              birthdate
            }
          }
        }`
    })
    .pipe(
      first(),
      map(response => {
        // add "user" key to each block
        return response.data.blocks.map(block => {
          return {user: block}
        })
        // return response.data.blocks
      })
    )
  }

  public invitationStatus(ref: string): Observable<invitationStatus> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query invitationStatus($ref: String) {
          invitationStatus(ref: $ref)
        }`,
      variables: {
        ref: ref
      }
    })
    .pipe(
      first(),
      map(response => {
        return response.data
      })
    )
  }
  
  public createInvitation(): Observable<invitation> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        mutation createInvitation {
          createInvitation {
            ref
            created
            expiration
          }
        }
      `
    })
    .pipe(
      first(),
      map(response => {
        return response.data.createInvitation
      })
    )
  }

  public search(query: string, type: searchType = null, likedWithinHours: number = null,
    pageSize = 18, pageIndex = 0): Observable<searchResults> {
      
      const paging = `{ pageSize: ${pageSize}, pageIndex: ${pageIndex} }`

      const postsData = `
        posts(paging:${paging}) {
          count
          data {
            ${this.singlePostDataBody}
          }
        }
      `
      const usersData = `
        users(paging:${paging}) {
          count
          data {
            username
            followingThem
            followingMe
            self
            profile{
              avatar
              location
              sex
              sex1
              sex2
              orientation
              orientation2
              birthdate
              birthdate2
            }
          }
        } 
      `
      const hashtagsData = `
        hashtags(paging:${paging}) {
          count
          data {
            count
            text
            avatar
            posts {
              count
            }
          }
        }
      `
    return this.httpClient.post<any>('/api/graphql', { 
      query: `
        query Search($query: String, $likedWithinHours: Int) {
          search(query: $query, likedWithinHours: $likedWithinHours) {
            query
            ${type == "posts" || type === null ? postsData : ""}
            ${type == "users" || type === null ? usersData : ""}
            ${type == "hashtags" || type === null ? hashtagsData : ""}
          }
        }
      `,
      variables: {
        query: query,
        likedWithinHours: likedWithinHours,
      }
    })
    .pipe(
      first(),
      map(response => {
        if (type == "posts" || type === null) {
          let posts: Post[] = response.data.search.posts.data
          response.data.search.posts.data = posts.map(post => this.postMapping(post))
        }
        if (type == "locations" || type === null) {
          response.data.search['locations'] = this.locationFullTextSearch(query)
        }
        if (type == "hashtags" || type === null) {
          response.data.search.hashtags.data.forEach(hash => {
            if (hash.avatar) {
              hash.avatar = this.hashtagAvatar(hash.avatar, 90)
            }
          })
        }
        console.log(response.data)
        return response.data.search
      }),
    )
  }

  public getHashtag(name: string, pageSize = 18, pageIndex = 0, sortBy:(sortBy) = "Score"): Observable<Hashtag> {
    return this.httpClient.post<any>('/api/graphql', { 
      query: `
        query hashtag($name: String, $pageSize: Int, $pageIndex: Int, $sortBy: SortBy) {
          hashtag(name: $name) {
            count
            text
            avatar
            posts(pageSize: $pageSize, pageIndex: $pageIndex, sortBy: $sortBy) {
              count
              data {
                ${this.singlePostDataBody}
              }
            }
          }
        }
      `,
      variables: {
        name: name,
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy
      }
    })
    .pipe(
      first(),
      map(response => {
        if (!response.data.hashtag.posts.count) { throw new Error(`${name} has no posts`) }

        let posts: Post[] = response.data.hashtag.posts.data
        response.data.hashtag.posts.data = posts.map(post => this.postMapping(post))
        response.data.hashtag.avatar = this.hashtagAvatar(response.data.hashtag.avatar)
        return response.data.hashtag
      }),
    )
  }

  public getLocation(name: string, pageSize = 18, type: "posts" | "users" | "both" = 'both', 
    pageIndex = 0, sortBy:(sortBy) = "Score"): Observable<LocationData> {
    const posts = `
      posts (pageSize: $pageSize, pageIndex: $pageIndex, sortBy: $sortBy){
        count
        data {
          ${this.singlePostDataBody}
        }
      }
    `
    const users = `
      users (pageSize: $pageSize, pageIndex: $pageIndex){
        count
        data {
          username
          self
          followingThem
          followingMe
          profile{
            sex
            avatar
            location
            birthdate
            birthdate2
          }
        }
      }
    `
    return this.httpClient.post<any>('/api/graphql', { 
      query: `
        query location($name: String, $pageSize: Int, $pageIndex: Int, ${type != "users" ? `$sortBy: SortBy` : ""}) {
          location(name: $name) {
            name
            ${type != "users" ? posts : ""}
            ${type != "posts" ? users : ""}
          }
        }
      `,
      variables: {
        name: name,
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy
      }
    })
    .pipe(
      first(),
      map(response => {
        // wrong location throws error
        if (!this.locationFullTextSearch(name)['count']) { throw new Error(`${name} does not exist`) }
        
        if (type != "users") {
          let posts: Post[] = response.data.location.posts.data
          response.data.location.posts.data = posts.map(post => this.postMapping(post))
        }
        return response.data.location
      }),
    )
  }

  public usernameAvailable(username): Observable<boolean> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query usernameAvailable($username: String) {
          usernameAvailable(username:$username)
        }`,
      variables: {
        username: username,
      }
    })
    .pipe(
      first(),
      map(res => res.data.usernameAvailable)
    )
  }

  public updateEmailVerifications(): Observable<boolean> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        mutation updateEmailVerifications {
          updateEmailVerifications
        }`
    })
    .pipe(
      first(),
      map(res => res.data)
    )
  }

  public getUserRequests(status:userRequest['status']="pending", pageSize = 30, pageIndex = 0): Observable<userRequests> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query getUserRequests($status: String, $pageSize: Int, $pageIndex: Int) {
          userRequests(status:$status, pageSize:$pageSize, pageIndex:$pageIndex) {
            count
            data {
              attachment
              created
              emailVerified
              invitation {
                inviter {
                  username
                  profile {
                    sex
                    avatar
                  }
                }
              }
              status
              title
              username
              profile {
                avatar
                birthdate
                birthdate2
                info
                location
                name
                orientation
                orientation2
                sex
                sex1
                sex2
              }
            }
          }
        }`,
      variables: {
        status: status,
        pageSize: pageSize,
        pageIndex: pageIndex,
      }
    })
    .pipe(
      first(),
      map(res => res.data.userRequests)
    )
  }

  public getReports(pageSize = 30, pageIndex = 0): Observable<reports> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        query getReports($pageSize: Int, $pageIndex: Int) {
          reports(pageSize:$pageSize, pageIndex:$pageIndex) {
            count
            data {
              adminComments
              created
              description
              reason
              reporter
              targetref
              targettype
            }
          }
        }`,
      variables: {
        pageSize: pageSize,
        pageIndex: pageIndex,
      }
    })
    .pipe(
      first(),
      map(res => res.data.reports)
    )
  }

  public report(reportInput: ReportInput): Observable<string> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        mutation report($report: ReportInput) {
          report(report: $report)
        }`,
      variables: {
        report: reportInput
      }
    })
    .pipe(
      first(),
      map(res => {
        if (res.errors) { throw new Error(res.errors[0].message) }
        return res.data.report
      })
    )
  }

  public approveMention(postRef: string, approve: boolean): Observable<any> {
    return this.httpClient.post<any>('/api/graphql', {
      query: `
        mutation approveMention($postRef: String, $approve: Boolean!) {
          approveMention(postRef:$postRef, approve:$approve)
        }`,
      variables: {
        postRef: postRef,
        approve: approve
      }
    })
    .pipe(
      first(),
      map(res => res.data.approveMention)
    )
  }


  // ======================= new core functions ========================

  // returns image from avatar ref
  refToImage(ref, size, token) {
    return `/api/posts/${ref}/image/${size}?token=${token}`
  }

  // returns image from avatar ref
  userAvatar(username, avatarPostRef, size) {
    return `/api/users/${username}/avatar/${size}?${avatarPostRef}`
  }

  hashtagAvatar(avatarString, resolution=320) {
    if (!avatarString) {
      return null
    }
    const [avatar, token] = avatarString.split(":")
    return this.refToImage(avatar, resolution, token)
  }

  // returns object of all img sizes
  imgToScrset(postRef: string, token: string): Scrset {
    let scrset: Scrset = {}
    for (const w of [150, 320, 640, 750, 1080]) {
      scrset[w] = `/api/posts/${postRef}/image/${w}?token=${token}`
    }
    return scrset
  }

  // Maps a post from the API to a usable post in the client
  postMapping(post): Post {
    return {
      author: post.author,
      title: post.title,
      created: post.created,
      postType: post.postType,
      image: this.imgToScrset(post.ref, post.token),
      ref: post.ref,
      likes: post.likes?.count,
      liked: post.liked,
      comments: post.flatComments?.count,
      lastComment: post.flatComments?.data[0],
      visibility: post.visibility,
      mentions: post.mentions
    }
  }

  // Remove duplicate posts when merging post arrays
  mergePosts(oldPosts: Post[], newPosts: Posts) {
    let posts = [...oldPosts, ...newPosts.data]
    return posts.filter((v, i, a) => a.findIndex(t => (t.ref === v.ref)) === i)
  }

  // Searches all values and returns matching objects
  locationFullTextSearch(query, obj = this.locations) {
    // lowercase and normalize tonations, e.g. "ά" is equal to "α".
    const search = query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    let results = []
    const tuples = Object.entries(obj).filter(([k,v]) => 
        k.toLowerCase().includes(search)
        || Object.entries(v).some(([k,v]) => 
          typeof v === 'string' 
          && v.toLowerCase().includes(search)
    ))
      for (const [key, val] of tuples) {
        results.push({'name': key})
    }
    return {
      count: results.length,
      data: results
    }
  }

  // ======================= Texts (Greek) ========================

  // https://en.wikipedia.org/wiki/Prefectures_of_Greece
  // https://el.wikipedia.org/wiki/Νομοί_της_Ελλάδας

  locations = {
    'Athens': 'Αθήνα',
    'Aetolia-Acarnania': 'Αιτωλοακαρνανία',
    'Argolis': 'Αργολίδα',
    'Arcadia': 'Αρκαδία',
    'Arta': 'Άρτα',
    'Attica': 'Αττική',
    'Achaea': 'Αχαΐα',
    'Boeotia': 'Βοιωτία',
    'Grevena': 'Γρεβενά',
    'Drama': 'Δράμα',
    'Dodecanese': 'Δωδεκάνησα',
    'Evros': 'Έβρος',
    'Euboea': 'Εύβοια',
    'Evrytania': 'Ευρυτανία',
    'Zakynthos': 'Ζάκυνθος',
    'Elis': 'Ηλεία',
    'Imathia': 'Ημαθία',
    'Heraklion': 'Ηράκλειο',
    'Thesprotia': 'Θεσπρωτία',
    'Thessaloniki': 'Θεσσαλονίκη',
    'Ioannina': 'Ιωάννινα',
    'Kavala': 'Καβάλα',
    'Karditsa': 'Καρδίτσα',
    'Kastoria': 'Καστοριά',
    'Corfu Kerkyra': 'Κέρκυρα',
    'Cephalonia': 'Κεφαλονιά',
    'Kilkis': 'Κιλκίς',
    'Kozani': 'Κοζάνη',
    'Corinthia': 'Κορινθία',
    'Cyclades': 'Κυκλάδες',
    'Larissa': 'Λάρισα',
    'Laconia': 'Λακωνία',
    'Lasithi': 'Λασίθι',
    'Lesbos': 'Λέσβος',
    'Lefkada': 'Λευκάδα',
    'Magnesia': 'Μαγνησία',
    'Messenia': 'Μεσσηνία',
    'Xanthi': 'Ξάνθη',
    'Pella': 'Πέλλα',
    'Pieria': 'Πιερία',
    'Preveza': 'Πρέβεζα',
    'Rethymno': 'Ρέθυμνο',
    'Rhodope': 'Ροδόπη',
    'Samos': 'Σάμος',
    'Serres': 'Σέρρες',
    'Trikala': 'Τρίκαλα',
    'Phthiotis': 'Φθιώτιδα',
    'Florina': 'Φλώρινα',
    'Phocis': 'Φωκίδα',
    'Chalkidiki': 'Χαλκιδική',
    'Chania': 'Χανιά',
    'Chios': 'Χίος',
  }

  sortFunct() {
    // DO NOT DELETE this empty sortFunct().
    // It's used for custom sorting in "| keyvalue" pipes.
  }

  genders = {
    m: "Άντρας",
    f: "Γυναίκα",
    tg: "Trans",
    // tgf: "Trans Γυναίκα",
    // tgm: "Trans Άντρας",
    nb: "Μη-δυαδικό",
    o: "Άλλο"
  }

  orientations = {
    straight: "Straight",// "Στρέιτ",
    bi: "Bisexual", //"Μπαϊσέξουαλ",
    gay: "Gay", // "Γκέι"
    // les: "Lesbian", // "Λεσβία"
    pan: "Pansexual" // Πανσέξουαλ
  }

  pages = {
    comments: "Σχόλια",
    likes: "Σε ποιους αρέσει",
    followers: "Ακόλουθοι",
    following: "Ακολουθεί",
    activity: "Δραστηριότητα",
    blocked: "Μπροκαρισμένα μέλη",
  }

  visibility = {
    public: 'Ορατή σε όλους',
    members: 'Μόνο μέλη του Naked Greece',
    friends: 'Σε μέλη που ακολουθώ και με ακολουθούν'
  };
}

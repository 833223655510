import { Component, OnInit, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CoreService } from '../../core/core.service';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Profile, User } from '../../interfaces/interfaces';
import { ApiClient } from '../../services/api-client.service';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

// Dialog - for confirmation
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component'

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  constructor(public afs: AngularFirestore, private auth: AuthenticationService, private fb: UntypedFormBuilder, 
    private afAuth: AngularFireAuth, private core: CoreService, private router: Router, 
    public location: Location, public dialog: MatDialog, public apiClient: ApiClient,
    private dom: DomSanitizer
  ) { }

  firebaseUser: firebase.User
  user: User
  userMail: string
  form: UntypedFormGroup;
  fc: any

  @ViewChild('textarea') textarea: ElementRef;
  charCount: number = 0
  maxChars: number = 2000
  maxLines: number = 25

  today = new Date()
  startDate = new Date(1992, 0, 1);
  minDate: Date
  maxDate: Date
  loading = false;

  serverMessage: string;

  ngOnInit() {
    this.form = this.fb.group({
      info: ['', [Validators.maxLength(this.maxChars)]],
      avatar: [''],
      userName: [{ value: '', disabled: true }, [
        Validators.minLength(3),
        Validators.maxLength(16),
        // Validators.pattern(this.core.regex.names),
        this.validateUsername.bind(this)]
      ],
      location: ['', [Validators.required]],
      birthdate: ['', [Validators.required]],
      birthdate2: ['', []],
      sex: ['', [Validators.required]],
      sex1: ['', []],
      sex2: ['', []],
      orientation: ['', [Validators.required]],
      orientation2: ['', []],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        this.validateEmail.bind(this)]
      ],
    });

    this.dateValidation(this.today)
    this.fc = this.form.controls

    this.populateForm()
  }

  populateForm() {

    this.apiClient.getProfile(this.auth.userObject.username)
      .subscribe(user => {
        this.user = user
        this.form.get("info").setValue(this.user.profile.info);
        // setTimeout hack to make it read the updated value
        setTimeout(() => this.lineBreakLimits(null));

        this.form.get("avatar").setValue(this.user.profile.avatar);
        this.form.get("userName").setValue(this.user.profile.name);
        this.form.get("location").setValue(this.user.profile.location);
        this.form.get("sex").setValue(this.user.profile.sex);
        this.form.get("sex1").setValue(this.user.profile.sex1);
        this.form.get("sex2").setValue(this.user.profile.sex2);
        this.form.get("orientation").setValue(this.user.profile.orientation);
        this.form.get("orientation2").setValue(this.user.profile.orientation2);
        this.form.get("birthdate").setValue(this.user.profile.birthdate);
        this.form.get("birthdate2").setValue(this.user.profile.birthdate2);

        this.coupleFormValidators(this.user.profile.sex == 'c')
      })
    
    // Fetch email if authState changed to !null
    this.auth.firebaseUser
      .pipe(first())
      .subscribe(user => {
        console.log("MAIL TRIGGERED", user.email);
        this.firebaseUser = user
        this.userMail = this.firebaseUser.email
        this.form.get("email").setValue(this.userMail)
      })
  }

  age(person) {
    if (person == 1) return this.user.profile.birthdate
    if (person == 2) return this.user.profile.birthdate2
  }

  orientation(person) {
    if (person == 1) return this.user.profile.orientation
    if (person == 2) return this.user.profile.orientation2
  }

  sex(person) {
    if (person == 1) return this.user.profile.sex1 ? this.user.profile.sex1 : this.user.profile.sex
    if (person == 2) return this.user.profile.sex2
  }

  coupleFormValidators(isCouple: boolean) {
    if (isCouple) {
      this.form.get('sex1').setValidators([Validators.required])
      this.form.get('sex2').setValidators([Validators.required])
      this.form.get('orientation2').setValidators([Validators.required])
      this.form.get('birthdate2').setValidators([Validators.required])
    } else {
      this.form.get('sex1').setValidators(null)
      this.form.get('sex2').setValidators(null)
      this.form.get('orientation2').setValidators(null)
      this.form.get('birthdate2').setValidators(null)
    }
  }

  isCouple(couple:boolean) {
    this.form.controls.sex.markAsDirty()
    this.coupleFormValidators(couple)
    if (couple) {
      this.form.get('sex1').setValue(this.form.controls.sex.value)
      this.form.get('sex').setValue('c')
    } else {
      this.form.get('sex').setValue(this.form.controls.sex1.value)
      // reset partner values
      this.form.get('sex1').setValue(null)
      this.form.get('sex2').setValue(null)
      this.form.get('orientation2').setValue(null)
      this.form.get('birthdate2').setValue(null)
    }
  }

  oldBio = ''
  // Limit info max lines and consecutive newlines
  lineBreakLimits(event) {
    let textBio: string = this.form.get('info').value
    // Escape error for accents on Mac
    if (textBio == this.oldBio) {
      return
    }
    // Allow only two consecutive newlines
    textBio = textBio.replace(/(\r\n|\r|\n)\1+/gm, "\n\n")
    // Limit text lines to maxLines (25)
    const lines: string[] = textBio.split(/\r\n|\r|\n/);
    // Update values
    const newBio = lines.slice(0, this.maxLines).join("\n")
    this.form.get('info').setValue(newBio);
    this.oldBio = newBio
    // Count characters
    this.charCount = textBio.length
    // --- Adjust textarea height ---
    
    let element = this.textarea.nativeElement
    element.style.height = 0
    element.style.height = element.scrollHeight - 4 + "px"
  }

  // Returns min and max datepicker dates
  dateValidation(td: Date) {
    const y = td.getFullYear()
    const m = td.getMonth()
    const d = td.getDate()
    this.minDate = new Date(y - 100, m, d)
    this.maxDate = new Date(y - 18, m, d)
  }

  validationTimer: any // timer for validat functions

  validateUsername(uname: AbstractControl) {
    let name = uname.value.toLocaleLowerCase()
    name ? console.log(name) : null

    clearTimeout(this.validationTimer)

    // copy same function from Registration form when enabled


  }

  validateEmail(email: AbstractControl) {
    let mail = email.value
    mail ? console.log(mail) : null

    clearTimeout(this.validationTimer)
    this.loading = false

    if (
      (mail.length >= 5)
      && (mail != this.userMail) //mail is not the original
      && !(this.form.controls['email'].hasError('email'))
      // && !(this.form.controls['email'].hasError('pattern'))
    ) {
      console.log(this.form.controls['email'].errors);
      this.loading = true

      this.validationTimer = setTimeout(() => {
        // Cross-check email with firebase registered users        
        this.afAuth.fetchSignInMethodsForEmail(mail)
        .then(res => {
          console.log(res)
          if (res.length) {
            this.form.controls['email'].markAsTouched()
            this.loading = false
            return this.form.controls['email'].setErrors(
              { emailExists: true }
            )
          } else {
            this.loading = false
            return null
          }
        })
        .catch(err => {
          this.loading = false
          if (err.code == "auth/network-request-failed") {
            this.form.setErrors({ offline: true })
          }
        })
      }, 600);
    }
  }

  confirmPass() {
    return new Promise((resolve) => {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { type: 'passwordConfirmation' }
      })

      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed. Pass was:', result);
        resolve(result)
      });

    })
  }

  // Change email to new string. Reauthenticate if needed.
  changeMail(email) {
    // TODO: verifyBeforeUpdateEmail - https://firebase.google.com/docs/reference/js/v8/firebase.User#verifybeforeupdateemail
    this.firebaseUser.updateEmail(email)
      .then(() => {
        console.info(`Replacing ${this.userMail} with ${email}`)
        // Email Verification
        // this.firebaseUser.sendEmailVerification().then(function () {
        //   console.log("auth mail sent");

        // }).catch(function (error) {
        //   console.error(error);

        // });
      })
      .catch(error => {
        if (error.code == "auth/email-already-in-use") {
          this.form.controls['email'].setErrors(
            { emailExists: true }
          )
          throw error
        } else if (error.code != "auth/requires-recent-login") {
          throw error
        } else {
          this.confirmPass().then((pass: string) => {
            // console.log("got password:", pass)
            const credential = firebase.auth.EmailAuthProvider.credential(
              this.userMail,
              pass
            );
            // Use mail and pass credential to reauthenticate
            this.firebaseUser.reauthenticateWithCredential(credential)
              .then(() => {
                this.changeMail(email)
              })
              // if pass is wrong, throw dialog to retry
              .catch((error) => {
                if (error.code == "auth/wrong-password") {

                  this.dialog.open(ConfirmationDialogComponent, {
                    data: { type: 'wrongPass' }
                  })
                  throw error
                }
              })
          })
        }
      });
  }

  leavePage() {
    if (this.form.dirty) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          type: 'saveBeforeAction',
          message: "Θέλετε να αποθηκεύσετε τις αλλαγές σας;"
        }
      })
      dialogRef.afterClosed().subscribe(async result => {
        console.log(result);
        if (result == "yes") {
          this.onSubmit()
        } else {
          this.location.back()
        }
      });
    } else {
      this.location.back()
    }
  }

  onSubmit() {
    // console.log(this.form.value)

    // 1. Unchanged Form navigates back
    if (this.form.pristine) {
      return this.location.back()
    }

    // 2. Invalid form shows the errors and not submitted
    if (this.form.invalid || this.loading) {
      this.form.markAllAsTouched()
      return;
    }

    // 3. Prepare the data
    const data: Profile = {
      info: this.form.get('info').value.trim(),
      location: this.form.get('location').value,
      sex: this.form.get('sex').value,
      sex1: this.form.get('sex').value == 'c' ? this.form.get('sex1').value : null,
      sex2: this.form.get('sex').value == 'c' ? this.form.get('sex2').value : null,
      orientation: this.form.get('orientation').value,
      orientation2: this.form.get('sex').value == 'c' ? this.form.get('orientation2').value : null,
      birthdate: this.core.dateToLocaleDateString(this.form.get('birthdate').value),
      birthdate2: this.form.get('sex').value == 'c' ? this.core.dateToLocaleDateString(this.form.get('birthdate2').value) : null,
    }
    console.log(data);
    console.log(this.userMail, this.form.get('email').value);
    
    // TODO: If username changed, also change it in firebaseAuth with 
    // this.firebaseUser.updateProfile({
    //   displayName: "Jane Q. User",
    //   photoURL: "https://example.com/jane-q-user/profile.jpg"
    // })
    // TODO: If username changed, save date and old name in DB

    // If mail is changed, update it
    const email = this.form.get('email').value
    if (this.userMail != email) {
      this.changeMail(email)
    }

    return this.apiClient.updateProfile(data)
      .subscribe({
        next: res => {
          if ('errors' in res) {
            console.error(res['errors'])
            this.dialog.open(ConfirmationDialogComponent, {
              disableClose: true,
              data: {
                type: 'info',
                message: '🛑 Κάτι πήγε στραβά. Δοκιμάστε αργότερα ή επικοινωνήστε μαζί μας.'
              }
            })
          } else {
          console.log(res)
          this.auth.updateUserObject(data)
          this.core.snackBarNotification("Οι αλλαγές αποθηκεύτηκαν!", ['/', '@' + this.user.username], undefined, false)
          }
        },
        error: err => console.error
      })
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { OnAttach, OnDetach } from '../../app-route-strategy';
import { CoreService } from '../../core/core.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnAttach, OnDetach {

  // this will be passed to the timeline child component
  scrollDisabled: boolean = false
  activeTab: "top" | "feed" | "fresh" | any = "top"
  feedVisited = false
  freshVisited = false

  // hashtags = this.core.getTopHashtags()

  constructor(public auth: AuthenticationService, private core: CoreService, 
    private changeDetectorRef: ChangeDetectorRef, private location: Location) { }

  async ngOnInit() {
    // this.hashtags = await this.core.getTopHashtags()
  }

  tabConfing = {
    top: {tabScrollDisabled: false},
    feed: {tabScrollDisabled: true, visited: false},
    fresh: {tabScrollDisabled: true, visited: false},
    private: {tabScrollDisabled: true, visited: false},
  }

  tabChange(event: MatTabChangeEvent) {
    this.tabConfing[this.activeTab].tabScrollDisabled = true
    this.activeTab = event.tab.textLabel
    // console.log(this.activeTab);
    this.tabConfing[this.activeTab].tabScrollDisabled = false
    this.tabConfing[this.activeTab].visited = true
    this.location.replaceState(`#${this.activeTab}`) // url tracked by analytics when replaceState is used
    // console.log(this.tabConfing);
  }

  // tabChange(index: number) {
  //   if (index === 1) {
  //     this.feedVisited = true
  //     this.feedScrollDisabled = false
  //     this.mainScrollDisabled = true
  //   } else {
  //     this.feedScrollDisabled = true
  //     this.mainScrollDisabled = false
  //   }
  // }

  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
  }
  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.scrollDisabled = true
    this.changeDetectorRef.detectChanges()
  }

}

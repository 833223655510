
<header class="center-title">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <button mat-button mat-icon-button [ngStyle]="{'visibility': submissionLoading ? 'hidden' : 'unset'}" (click)="stepBack(stepper)">
        <mat-icon id="back">arrow_back_ios</mat-icon>
      </button>
      <h3 class="title">Εγγραφή</h3>
      <span class="spacer"></span>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<mat-horizontal-stepper #stepper linear id="registration-stepper"> <!-- TODO: Add "linear" when complete-->

  <!-- Step 0 -->
  <mat-step [stepControl]="form0" [editable]="true">
    <form [formGroup]="form0">
      <p>Εισάγετε τον κωδικό της πρόσκλησής σας:</p>
      <mat-form-field hideRequiredMarker>
        <mat-label>Πρόσκληση</mat-label>
        <span matPrefix>
          <mat-icon>key</mat-icon>
        </span>
        <span matPrefix style="vertical-align: super;">
          -
        </span>

        <input matInput formControlName="invitation" type="invitation" placeholder="1Rj5s7" autocomplete="off" />
        <span class="check" *ngIf="invitationValid"
          matSuffix mat-icon-button aria-label="done">
          <mat-icon>done</mat-icon>
        </span>
        <!-- <mat-spinner matSuffix *ngIf="loading" class="inline" color="accent" [diameter]="22"></mat-spinner> -->
        
        <mat-error *ngIf="form0.controls['invitation'].hasError('invalid')">
          Ο πρόσκλησή σας δεν είναι έγκυρη.
        </mat-error>
        <mat-error *ngIf="form0.controls['invitation'].hasError('used')">
          Η πρόσκληση έχει ήδη χρησιμοποιηθεί.
        </mat-error>
      </mat-form-field>
      <div class="infobox">
        <mat-icon class="material-icons-outlined">info_outline</mat-icon>
        <!-- <mat-icon class="material-icons-outlined"> wallpaper_outlined</mat-icon> -->
        <!-- <div>
          <p>H διαδικασία εγγραφής περιλαμβάνει την ανάρτηση της πρώτης σας φωτογραφίας.</p>
          <p>Tο πρόσωπο σας μπορεί είτε να είναι εμφανές, είτε όχι.</p>
          <p>Αν δημιουργείτε προφίλ ως <strong>ζευγάρι</strong>, θα πρέπει να εμφανίζεστε και οι δύο στην φωτογραφία εγγραφής.</p>
        </div> -->
        <ul>
          <li>H διαδικασία εγγραφής περιλαμβάνει την ανάρτηση της πρώτης φωτογραφίας σας.</li>
          <li>Το πρόσωπό σας δεν χρειάζεται να είναι εμφανές.</li>
          <li>Αν δημιουργείτε προφίλ ως <strong class="gradient-text">ζευγάρι</strong>, θα πρέπει να εμφανίζεστε και οι δύο στην φωτογραφία εγγραφής.</li>
        </ul>
      </div>
      <div class="button-container">
        <button class="stepper" mat-flat-button color="accent" (click)="checkInvitation(stepper)">Συνέχεια</button>
      </div>
    </form>
  </mat-step>

  <!-- Step 1 -->
  <mat-step [stepControl]="form1" [editable]="true">
    <form [formGroup]="form1">
      <div class="form-desc">
        <h3 class="gradient-text">Η πρόσκλησή σας έγινε δεκτή!</h3>
        <p>Ας ξεκινήσουμε την εγγραφή με τα βασικά στοιχεία του λογαριασμού σας.</p>
      </div>
      <mat-form-field hideRequiredMarker>
        <span matPrefix>
          <mat-icon> mail_outline</mat-icon>
        </span>
        <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off" />
        <span class="check" *ngIf="!form1.controls['email'].errors && !loading && !form1.controls['email'].pristine"
          matSuffix mat-icon-button aria-label="done">
          <mat-icon>done</mat-icon>
        </span>
        <mat-spinner matSuffix *ngIf="loading" class="inline" color="accent" [diameter]="22"></mat-spinner>

        <mat-error *ngIf="form1.controls['email'].hasError('email') || form1.controls['email'].hasError('pattern')">
          Η διεύθυνση email σας πρέπει να είναι σωστή.
        </mat-error>
        <mat-error *ngIf="form1.controls['email'].hasError('emailExists')">
          Παρουσιάστηκε πρόβλημα με την παραπάνω διεύθυνση. Ελέγξτε την ορθογραφία της ή δοκιμάστε μια
          διαφορετική.
        </mat-error>
      </mat-form-field>

      <mat-form-field hideRequiredMarker>
        <span matPrefix>
          <mat-icon>lock_outline</mat-icon>
        </span>
        <input matInput formControlName="password" [type]="showPass ? 'text' : 'password'"
        type="password" placeholder="Password" autocomplete="new-password"/>
        <mat-icon matSuffix (click)="showPass = !showPass">{{showPass ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error>
          Χρησιμοποιήστε 8 ή περισσότερους χαρακτήρες. <br> <span style="text-decoration: underline;">Συνιστάται</span> ο συνδυασμός πεζών, κεφαλαίων, αριθμών και συμβόλων.
        </mat-error>
      </mat-form-field>

      <div class="button-container">
        <button class="stepper" mat-flat-button color="accent" (click)="stepperNext(stepper)">Συνέχεια</button>
      </div>
    </form>
  </mat-step>

<!-- Step 2 -->
  <mat-step [stepControl]="form2" [editable]="true">
    <form [formGroup]="form2">
      <!-- <ng-template matStepLabel>Fill out your address</ng-template> -->
      
      <div class="margin-bot">
        <p>Ο λογαριασμός σας είναι ατομικός ή είστε ζευγάρι;</p>
        <div class="couple">
          <div class="cpl-button" [ngClass]="{'selected': form2.controls.couple.value == false}" (click)="form2.get('couple').setValue(false)">
            <button mat-fab color="accent" type="button"> <!-- type="button", don't remove because default is type="submit" -->
              <mat-icon>radio_button_unchecked</mat-icon>
            </button>
            <span>Άτομο</span>
          </div>
          <div class="cpl-button" [ngClass]="{'selected': form2.controls.couple.value}" (click)="form2.get('couple').setValue(true)">
            <button mat-fab color="accent" type="button"> <!-- type="button", don't remove because default is type="submit" -->
              <mat-icon>radio_button_unchecked</mat-icon>
              <mat-icon class="second_circle">radio_button_unchecked</mat-icon>
            </button>
            <span>Ζευγάρι</span>
          </div>
        </div>
        <mat-error *ngIf="form2.controls['couple'].touched && form2.controls['couple'].invalid">
          Επιλέξετε αν είστε ζευγάρι ή άτομο
        </mat-error>
      </div>

      <mat-form-field hideRequiredMarker>
        <span matPrefix>
          <mat-icon>person_outline</mat-icon>
        </span>
        <input matInput formControlName="userName" type="text" placeholder="Επιλέξτε όνομα λογαριασμού" autocomplete="off" 
          [value]="form2.get('userName').value | lowercase"/>
        <span class="check" *ngIf="!form2.controls['userName'].errors && !loading && !form2.controls['userName'].pristine"
          matSuffix mat-icon-button aria-label="done">
          <mat-icon>done</mat-icon>
        </span>
        <mat-spinner matSuffix *ngIf="loading" class="inline" color="accent" [diameter]="22"></mat-spinner>
        
        <mat-error *ngIf="form2.controls['userName'].hasError('maxlength') || form2.controls['userName'].hasError('minlength')">
          Το όνομα πρέπει να είναι απο 3 έως 16 χαρακτήρες.
        </mat-error>
        <mat-error *ngIf="form2.controls['userName'].hasError('pattern')">
          Το όνομα μπορεί να περιλαμβάνει μόνο πεζά γράμματα, αριθμούς, τελείες "." ή κάτω παύλες "_"
        </mat-error>
        <mat-error *ngIf="form2.controls['userName'].hasError('nameTaken')">
          Το όνομα δεν είναι διαθέσιμο.
        </mat-error>
      </mat-form-field>

      <mat-form-field hideRequiredMarker>
        <mat-label>Πόλη/Νομός</mat-label>
        <span matPrefix>
          <mat-icon>location_on</mat-icon>
        </span>
        <mat-select formControlName="location">
          <!-- <mat-option *ngFor='let location of apiClient.locations' [value]="location.id">
            {{location.full}}
          </mat-option> -->
          <mat-option *ngFor='let location of apiClient.locations | keyvalue: apiClient.sortFunct' [value]="location.key">
            {{location.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <div class="button-container">
        <button class="stepper" mat-flat-button color="accent" (click)="form2.get('couple').markAsTouched(); stepperNext(stepper)">Συνέχεια</button>
      </div>
    </form>
  </mat-step>
  
  <!-- Step 3 -->
  <mat-step [stepControl]="form3" [editable]="true">
    <form [formGroup]="form3">
      <div class="partner">
        <h3 *ngIf="form2.controls.couple.value">Το <span class="gradient-text">πρώτο άτομο</span> του ζευγαριού είναι:</h3>
        <h3 *ngIf="!form2.controls.couple.value">Στοιχεία:</h3>
      </div>
      <mat-form-field hideRequiredMarker>
        <mat-label>Φύλο</mat-label>
        <span matPrefix>
          <mat-icon class="venus-mars" fontSet="fa" fontIcon="fa-venus-mars"></mat-icon>
        </span>
        <mat-select formControlName="sex">
          <mat-option *ngFor='let sex of apiClient.genders | keyvalue: apiClient.sortFunct' [value]="sex.key">
            {{ apiClient.genders[sex.key] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field hideRequiredMarker>
        <mat-label>Σεξουαλικός προσανατολισμός</mat-label>
        <span matPrefix>
          <mat-icon>fork_right</mat-icon>
        </span>
        <mat-select formControlName="orientation">
          <mat-option *ngFor='let or of apiClient.orientations | keyvalue: apiClient.sortFunct' [value]="or.key">
            {{ apiClient.orientations[or.key] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field hideRequiredMarker>
        <span matPrefix>
          <mat-icon>cake</mat-icon>
        </span>
        <input matInput [matDatepicker]="picker1" [min]="minDate" [max]="maxDate" formControlName="birthdate"
          placeholder="Ημερομηνία γέννησης" name="birthdate">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 touchUi startView="multi-year" [startAt]="startDate"></mat-datepicker>
        <mat-error *ngIf="form3.controls['birthdate'].hasError('matDatepickerMax')">Πρέπει να είστε πάνω απο 18 ετών</mat-error>
        <mat-error *ngIf="form3.controls['birthdate'].hasError('matDatepickerMin')">Mη επιτρεπτή ημερομηνία γέννησης</mat-error>
      </mat-form-field>

      <div class="button-container">
        <button class="stepper" mat-flat-button color="accent" (click)="stepperNext(stepper)">Συνέχεια</button>
      </div>
    </form>
  </mat-step>

  <!-- Step 4 -->
  <mat-step *ngIf="form2.controls.couple.value" [stepControl]="form4" [editable]="true">
    <form [formGroup]="form4">
      <div class="partner">
        <h3>Το <span class="gradient-text">δεύτερο άτομο</span> του ζευγαριού είναι:</h3>
      </div>
      <mat-form-field hideRequiredMarker>
        <mat-label>Φύλο</mat-label>
        <span matPrefix>
          <mat-icon class="venus-mars" fontSet="fa" fontIcon="fa-venus-mars"></mat-icon>
        </span>
        <mat-select formControlName="sex2">
          <mat-option *ngFor='let sex of apiClient.genders | keyvalue: apiClient.sortFunct' [value]="sex.key">
            {{ apiClient.genders[sex.key] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field hideRequiredMarker>
        <mat-label>Σεξουαλικός προσανατολισμός</mat-label>
        <span matPrefix>
          <mat-icon>fork_right</mat-icon>
        </span>
        <mat-select formControlName="orientation2">
          <mat-option *ngFor='let or of apiClient.orientations | keyvalue: apiClient.sortFunct' [value]="or.key">
            {{ apiClient.orientations[or.key] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field hideRequiredMarker>
        <span matPrefix>
          <mat-icon>cake</mat-icon>
        </span>
        <input matInput [matDatepicker]="picker1" [min]="minDate" [max]="maxDate" formControlName="birthdate2"
          placeholder="Ημερομηνία γέννησης">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 touchUi startView="multi-year" [startAt]="startDate"></mat-datepicker>
        <mat-error *ngIf="form4.controls['birthdate2'].hasError('matDatepickerMax')">Πρέπει να είστε πάνω απο 18 ετών</mat-error>
        <mat-error *ngIf="form4.controls['birthdate2'].hasError('matDatepickerMin')">Δυστυχώς πρέπει να είστε κάτω απο 100 ετών. Συγχαρητήρια όμως!</mat-error>
      </mat-form-field>

      <div class="button-container">
        <button class="stepper" mat-flat-button color="accent" (click)="stepperNext(stepper)">Συνέχεια</button>
      </div>
    </form>
  </mat-step>

  <!-- Step 5 -->
  <mat-step [stepControl]="form5" [editable]="true">
    <form [formGroup]="form5">
      <h3 class="gradient-text">Φωτογραφία προφίλ</h3>
      <p>Κοινοποιήστε την πρώτη σας ανάρτηση ώστε να επαληθευθεί το προφίλ σας.</p>
      <ul class="last-step">
        <li>Το πρόσωπό σας δεν χρειάζεται να είναι εμφανές.</li>
        <li>Φωτογραφίες απο το διαδίκτυο απορρίπτονται.</li>
        <li>Μία dick pic δεν είναι καλή πρώτη επιλογή.</li>
      </ul>
      <div>

        <mat-label>Επιλογή φωτογραφίας:</mat-label>

        <button class='imageBtn' color="accent" type="button" mat-stroked-button (click)="photoInput.click()">Επιλογή</button>

        <input hidden formControlName="photoInput" (change)="onFileSelected($event)" #photoInput type="file" id="file"
          accept="image/png,image/jpeg">

        <p class="error" *ngIf="form5.controls['photoInput'].hasError('required') && form5.controls['photoInput'].touched">
          Ξεχάσατε να ανεβάσετε μια φωτογραφία!
        </p>
        <p class="error" *ngIf="imageLarge">
          Η φωτογραφία πρέπει να είναι μικρότερη απο 5MB
        </p>
        <p class="error" *ngIf="imageWrongExt">
          Η φωτογραφία πρέπει να είναι σε .jpeg ή .png
        </p>
      </div>
      <app-upload-canvas></app-upload-canvas>

      <!-- <mat-form-field *ngIf="imgURI">
        <input matInput formControlName="title" type="text" placeholder="Κείμενο φωτογραφίας" autocomplete="off" />
        <mat-error>
          Η περιγραφή πρέπει να είναι το πολύ 140 χαρακτήρες.
        </mat-error>
      </mat-form-field> -->

      <mat-form-field hideRequiredMarker>
        <textarea #textarea matInput formControlName="title" type="text" placeholder="Κείμενο φωτογραφίας"
          autocomplete="off" (input)="lineBreakLimits($event)">
        </textarea>
        <mat-hint [style.color]="charCount > maxChars ? 'red' : 'inherit' " align="end">
          {{ charCount }}/{{ maxChars }}
        </mat-hint>
      </mat-form-field>

      <div class="checkboxes" *ngIf="imgURI">
        <mat-checkbox formControlName="tos" (click)="preview()">
          Επιβεβαιώνω οτι η φωτογραφία απεικονίζει εμένα ή οτι την έχω τραβήξει εγώ, και την κοινοποιώ με συναίνεση των ατόμων που απεικονίζονται, 
          όλοι εκ των οποίων ήταν τουλάχιστον 18 ετών κατά την λήψη της.
        </mat-checkbox>
        <mat-checkbox formControlName="avatar" (click)="avatarNotification()">Να γίνει φωτογραφία προφίλ</mat-checkbox>
        <div class="visibility">
          <div matPrefix>
            <mat-icon>visibility</mat-icon>
            <span>Ορατότητα:</span>
          </div>
          <mat-select value="public">
            <!-- <mat-select-trigger>{{ apiClient.visibility['public'] }}</mat-select-trigger> -->
            <mat-option *ngFor='let v of apiClient.visibility | keyvalue: apiClient.sortFunct' 
              value="public" [disabled]="v.key == 'public' ? false : true" (click)="visibilityNotification(v.key)">
              {{ apiClient.visibility[v.key] }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="padbot progress-bar">
        <div *ngIf="imgURI && uploadCanvas.uploader.progress as progress" class="padbot progress-bar">
          <mat-progress-bar color="accent" [value]="progress"></mat-progress-bar>
          {{ progress | number }}%
        </div>
        <div *ngIf="registrationMessage as msg" class="reg-message blink">
          {{ msg }}<span>.</span><span>.</span><span>.</span>
        </div>
      </div>

      <div class="button-container">
        <button class="stepper" mat-flat-button color="accent" [disabled]="!form5.valid || submissionLoading" (click)="onSubmit()">Εγγραφή</button>
      </div>
    </form>
  </mat-step>

</mat-horizontal-stepper>

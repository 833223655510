import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CoreService } from '../../core/core.service';
import { Post, postType, singlePostState, sortBy, User } from '../../interfaces/interfaces';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';
import { ApiClient } from '../../services/api-client.service';
import { first } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { OnDetach } from 'src/app/app-route-strategy';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss'],
})

export class SinglePostComponent implements OnInit, OnDetach {


  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // Menu closes on "back" navigation (otherwise it stays open)
    this.trigger ? this.trigger.closeMenu() : null
  }

  swiper: Swiper // initializes in ngAfterInit

  postRef: string   // from url
  posts: Array<Post>
  load_batch = 2
  
  postsSource: singlePostState["postsSource"]
  postType: postType
  sorting: sortBy
  query: string = null   // uid or hashtag or location
  state // navigation state
  statePushed = false
  height = this.core.windowHeight + 'px' // default value
  postIndex = this.router.getCurrentNavigation().extras.state?.index

  pageSize = 2
  pageIndex = 0
  endReached: boolean = false

  is404 = false

  constructor(public location: Location, private activatedRoute: ActivatedRoute, 
    public core: CoreService, private router: Router, 
    public apiClient: ApiClient, public auth: AuthenticationService) {

    this.height = window.innerHeight + "px"
    this.state = this.router.getCurrentNavigation().extras.state
    console.log(this.state)
  }

  ngOnInit() {
    if (this.state) {
      console.warn("State Pushed");
      this.posts = this.state.data
      this.postIndex = this.state.index
      this.postsSource = this.state.postsSource
      this.postType  = this.state.postType
      this.sorting = this.state.sorting
      this.query = this.state.query
      this.endReached = this.state.endReached
      this.pageIndex = Math.floor(this.posts.length / this.pageSize)

      console.log(this.posts);
    } else {
      this.postRef = this.activatedRoute.snapshot.paramMap.get("postRef")
      console.warn("NO State Pushed");
      console.log(this.postRef);
      this.apiClient.getPost(this.postRef)
        .subscribe({
          next: res => {
            this.posts = [res]
            this.postIndex = 0
            console.log(this.posts);
          },
          error: err => {
            console.error("404 - post does not exist");
            this.is404 = true
          }
      })
    }
    console.log(this.postIndex);
  }

  async ngAfterViewInit() {
    // rename swiper component
    this.swiper = this.usefulSwiper.swiper
    // fetch more posts if last post was clicked (and state was pushed - not routed from url)
    if (this.swiper.isEnd && this.state) {
      this.getData()
    }
    // swiper events
    this.swiper.on('slideChange', () => {
      const index = this.swiper.realIndex
      console.log(`Slide ${index + 1} of ${this.posts.length}`);
      this.onIndexChange(index)
      // GET new posts when reaching the post before the last one
      if (index + 2 >= this.posts.length) {
        console.log("Last slide reached - Loading more...");
        this.getData()
      }
    })
  }

  config: SwiperOptions = {
    // centeredSlides: true, // to give a glipse of prev/next photo
    runCallbacksOnInit: false,
    initialSlide: this.postIndex,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    keyboard: true,
    spaceBetween: 10,
    preloadImages: false,
    // cssMode: true,
    speed: 200,
    touchReleaseOnEdges: true,
    resistance: false,
    // effect: "flip",
    // edgeSwipeThreshold: 5,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1,
      loadOnTransitionStart: true
    },
    // virtual: {
    //   slides: this.posts
    // },
    // updateOnWindowResize: false, // only on mobile & block orientation change (more images are shown)
  };

  // To change Header info (avatar & name) and update post likes
  onIndexChange(event) {
    this.postIndex = event
    this.changeLocation(event)
  }

  // Resizes component for Mobile Bowser URL bar hide/show
  // otherwise the componet height (100vh) jumps and looks glitchy
  resetHeight() {
    this.height = this.core.windowHeight + 'px'
  }

  // Changes borwser URL to the currently viewd post
  changeLocation(idx) {
    let url = this.posts[idx]?.ref
    this.router.navigate(["/p/" + url], { replaceUrl: true });
  }

  backOrProfile() {
    const currentPost = this.posts[this.swiper.realIndex]
    return this.router.navigate(['/', '@' + currentPost.author.username])
  }

  // Get hot posts
  getData() {
    if (this.endReached) {
      return
    } else if (this.postsSource == "home") {
        this.apiClient.getPosts(this.pageSize, this.pageIndex)
        .subscribe(posts => {
          this.posts = this.apiClient.mergePosts(this.posts, posts)
          this.endReached = posts.data.length < this.pageSize
          console.log(posts);
          console.log(this.posts, "end: "+this.endReached)
        })
    } else if (this.postsSource == "feed") {
        this.apiClient.getFeed(this.pageSize, this.pageIndex, "post")
        .subscribe(posts => {
          this.posts = this.apiClient.mergePosts(this.posts, posts)
          this.endReached = posts.data.length < this.pageSize
          console.log(posts);
          console.log(this.posts, "end: "+this.endReached)
        })
    } else if (this.postsSource == "user") {
        this.apiClient.getUserPosts(this.query ,this.pageSize, this.pageIndex, this.postType)
        .subscribe((user: User) => {
          const posts = user[this.postType]
          this.endReached = posts.data.length < this.pageSize
          this.posts = this.apiClient.mergePosts(this.posts, posts)
          console.log(posts);
          console.log(this.posts);
          console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
        })
    } else if (this.postsSource == "search") {
        this.apiClient.search(this.query, "posts", undefined, this.pageSize, this.pageIndex)
        .subscribe(res => {
          const posts = res.posts
          this.posts = this.apiClient.mergePosts(this.posts, posts)
          this.endReached = posts.data.length < this.pageSize
          console.log(posts);
          console.log(this.posts, "end: "+this.endReached)
        })
    } else if (this.postsSource == "hashtag") {
        this.apiClient.getHashtag(this.query, this.pageSize, this.pageIndex, this.sorting)
        .subscribe(
          (tag) => {
            const posts = tag.posts
            this.endReached = posts.data.length < this.pageSize
            this.posts = this.apiClient.mergePosts(this.posts, posts)
            console.log(posts);
            console.log(this.posts);
            console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
          }
      )
    } else if (this.postsSource == "location") {
      this.apiClient.getLocation(this.query, this.pageSize, "posts", this.pageIndex, this.sorting)
        .subscribe(
          (location) => {
            const posts = location.posts
            this.endReached = posts.data.length < this.pageSize
            this.posts = this.apiClient.mergePosts(this.posts, posts)
            console.log(posts);
            console.log(this.posts);
            console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
          }
        )
    }
    this.pageIndex += 1
  }

  get lastPost(): object {
    return this.posts.slice(-1)[0]
  }

  pushState(post, request) {
    console.log(post, request);
    this.router.navigate([this.router.url, request],
      {
        // state: { post: post, request: request }
      }),
      { replaceUrl: true }
  }

  likeUnlike(ref, like) {
    if (this.auth.userObject.status != "verified") {
      this.core.snackBarNotification("Συνδεθείτε με τον λογαριασμό σας για να κάνετε like στην ανάρτηση")
      return
    }
      
    this.apiClient.likeUnlike(ref,like)
    .subscribe(res => {
      console.log(`Like post "${ref}": ${res.like}`);
      
      const postIndex = this.posts.findIndex(x => x.ref === ref);
      this.posts[postIndex].liked = like

      let count = this.posts[postIndex].likes
      this.posts[postIndex].likes = like ? count += 1 : count -= 1
    });
  }

  // On a fresh app load, navigationId == 1 (but a page refresh also counts) 
  // TODO: Decide to enable it or not after using the app
  checkBack() {
    const loc = this.location.getState()
    console.log(loc);
    if (loc['navigationId'] == 1 ) {
      this.router.navigate(['/'])
    } else {
      this.location.back()
    }
    
  }

  mentioned(post:Post) {
    if (this.auth.userObject) {
      const mentioned = post.mentions.find(o => o.name == this.auth.userObject.username)
      if (!post.author.self && mentioned) {
        return mentioned.status
      }
    }
    return undefined
  }

  approveMention(postRef: string, approved: boolean) {
    this.apiClient.approveMention(postRef, approved)
      .subscribe((res) => {
        const postIndex = this.posts.findIndex(x => x.ref === postRef);
        this.posts[postIndex].mentions.find(o => o.name == this.auth.userObject.username).status = approved ? "approved" : "rejected"
        this.core.snackBarNotification(`Η ανάρτηση ${approved ? "εμφανίζεται" : "δεν θα εμφανίζεται"} στο προφίλ σας.`)
      })
  }

  // HTML
  // (touchstart)="touchStart($event)"
  // (touchmove)="touchMove($event)"
  // (touchend)="touchEnd($event)"
  // [style.scale]="touchZoomScale"

  // [ngClass]="{'zoomHide': touchZoomInit}"

  // /HTML

  // touchZoomInit = false
//   touchZoomScale = 1

//   touchStart(e) {
//     this.touchZoomInit = true
//   }
//   touchMove(e) {
//     if (e.touches.length === 2) {
//       var dist = Math.hypot(
//         // e.touches[0].pageX,
//         // e.touches[0].pageY,
//         e.touches[0].pageX - e.touches[1].pageX,
//         e.touches[0].pageY - e.touches[1].pageY
//       );
//       console.log(dist);
//       this.touchZoomScale = 1 + dist/1000
//    } 
//  }
//   touchEnd(e) {
//     this.touchZoomInit = false
//     this.touchZoomScale = 1
//   }

}

import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationStart, RouteReuseStrategy } from '@angular/router';
import { CoreService } from '../../core/core.service';
import { User, Profile, Post, Hashtag, LocationData, sortBy } from '../../interfaces/interfaces'
import { Location } from '@angular/common';
import { OnAttach, OnDetach } from '../../app-route-strategy';
import { ApiClient } from '../../services/api-client.service';
import {AuthenticationService} from '../../services/authentication.service';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-hashtag-location',
  templateUrl: './hashtag-location.component.html',
  styleUrls: ['./hashtag-location.component.scss']
})
export class HashtagLocationComponent implements AfterViewInit, OnAttach, OnDetach {
  
  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
    this.setScrollListener()
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.changeDetectorRef.detectChanges()
  }
  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.scrollDisabled = true
    this.setScrollListener()
    this.router.routeReuseStrategy.shouldReuseRoute = this.mainRouteReuse
    this.drawer ? this.drawer.close().then(console.log) : null
    this.changeDetectorRef.detectChanges()
  }

  constructor(private activatedRoute: ActivatedRoute, public core: CoreService, private router: Router,
    public auth: AuthenticationService, public location: Location, private changeDetectorRef: ChangeDetectorRef,
    public apiClient: ApiClient) {       
      this.mainRouteReuse = this.router.routeReuseStrategy.shouldReuseRoute
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  @ViewChild('tabSection') tabSection: ElementRef;
  @ViewChild('drawer') drawer: MatDrawer;
  
  sticky = false
  requestParam: "hashtag" | "location" | any
  query: string
  tag: Hashtag = null
  loc: LocationData = null
  own: boolean = false
  following: boolean
  blocked: boolean = false
  tab2Visited = false

  mainRouteReuse: any

  scrollDisabled: boolean = false

  is404 = false

  activeTab: "topPosts" | "newPosts" | "users" | any = "topPosts"
  pageSize = 12
  tabConfing = {
    topPosts: {pageIndex: 0, endReached: false, tabScrollDisabled: false}, // only scrollDisabled is used form here
    newPosts: {pageIndex: 0, endReached: false, tabScrollDisabled: true}, // only scrollDisabled is used from here
    users: {pageIndex: 0, endReached: false, tabScrollDisabled: true},
  }

  onWindowScroll: any

  async ngAfterViewInit() {
    // We have to bind(this) on another function for Listener attach/detach to work
    this.onWindowScroll = this.windowScrollFunction.bind(this)

    // No need to unsubscribe from ActivatedRoute observables (from docs)    
    this.activatedRoute.params
    .subscribe(async (res:{ "hashtag":string, "location":string}) => {
      console.log(res);
      this.requestParam = Object.keys(res)[0]
      this.query = res[this.requestParam]
      this.getData()
      this.setScrollListener()
    })
  }

  getData() { 
    if (this.requestParam == "hashtag") {
      this.apiClient.getHashtag(this.query, this.pageSize)
      .subscribe({
        next: (tag) => {
          this.tag = tag
          console.log(tag);
        },
        error: (error) => {
          console.error(this.query, "does not exist, 404");
          this.is404 = true
        }}
      )
    } else if (this.requestParam == "location") {
      this.apiClient.getLocation(this.query, this.pageSize)
      .subscribe({
        next: (location) => {
          this.loc = location
          console.log(location);
        },
        error: error => {
          console.error(this.query, "does not exist, 404");
          this.is404 = true
        }
      })
    }
  }

  setScrollListener() {
    if (!this.scrollDisabled) {    
      window.addEventListener("scroll", this.onWindowScroll)
    } else {     
      window.removeEventListener("scroll", this.onWindowScroll)
    }
  }

  windowScrollFunction(e) {
    if (this.tabSection.nativeElement.getBoundingClientRect().top < 60) {
      this.gridHeaderSticky(true)
    } else {
      this.gridHeaderSticky(false)
    }
  }

  gridHeaderSticky(value:boolean) {
    let element = document.getElementsByTagName('mat-tab-header');
    if (value) {
      element[0].classList.add('sticky-tab-header');
      this.sticky = true
    } else {
      element[0].classList.remove('sticky-tab-header');
      this.sticky = false
    }
  }

  followUnfollow(username, i) {
    this.apiClient.followUnfollow(username)
      .subscribe((res) => {
        console.log(`Following: ${res.data.follow}`);
        if (res) {
          this.loc.users.data[i].followingThem = !this.loc.users.data[i].followingThem
        }
      })
  }

  // only location.users are handled in this component
  onScroll() {
    if (!this.tabConfing[this.activeTab].endReached) {
      this.tabConfing[this.activeTab].pageIndex += 1

      // only location.users
      this.apiClient.getLocation(this.query, this.pageSize, "users", this.tabConfing.users.pageIndex)
      .subscribe(
        (location) => {
          this.loc.users.data = [...this.loc.users.data, ...location.users.data]
          this.tabConfing.users.endReached = location.users.data.length < this.pageSize
          console.log(this.loc.users.data);
        }
      )
    }
  }

  tabChange(event: MatTabChangeEvent) {
    console.log(event);
    
    this.tab2Visited = true
    this.tabConfing[this.activeTab].tabScrollDisabled = true
    this.activeTab = event.tab.textLabel
    console.log(this.activeTab);
    this.tabConfing[this.activeTab].tabScrollDisabled = false
  }
  
  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.onWindowScroll)
  }

}

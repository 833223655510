import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot, UrlSegment, ActivatedRoute, RouterOutlet } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { ComponentRef, Directive, Injectable } from '@angular/core';

interface RouteStorageObject {
    snapshot: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
}

@Injectable()

export class CustomRouteReuseStategy implements RouteReuseStrategy {

    // Firing sequence
    // 1. 'shouldReuseRoute' fires (if 'FALSE', sequence continues)
    // 2. 'retrieve' is run (for new route - does not fetch)
    // 3. 'shouldDetach' (old route)
    // 4. 'store' (old route)
    // 5. 'shouldAttach' (new route)
    // 6. 'retrieve' is run (this one fetches page)
    
        // back-navigation monitoring
        back: boolean = false
        lastObject: RouteStorageObject

    constructor(location: LocationStrategy) {
        location.onPopState(() => {
            this.back = true
            // console.log('Back/Forward button pressed!', this.back);
            // console.log(window.location);
        });
    }

    // Object which will store RouteStorageObjects indexed by keys
    storedRoutes: { [key: string]: RouteStorageObject } = {};

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data.shouldReuse === true;
    }

    // Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const id = this.createIdentifier(route);
        if (route.data.shouldReuse && id.length > 0) {
            this.storedRoutes[id] = { handle, snapshot: route };
            // console.log('stored routes', Object.keys(this.storedRoutes));
            // console.log('stored routes FULL', this.storedRoutes);
        }
    }

    // Determines whether or not there is a stored route and, if there is, whether or not it should be 
    // rendered in place of requested route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const id = this.createIdentifier(route);
        const storedObject = this.storedRoutes[id];
        const canAttach = !!route.routeConfig && !!storedObject;
        // console.log("route.routeConfig", route.routeConfig);
        // console.log("id", id);
        // console.log("storedObject", storedObject);
        // console.log("lastObject", this.lastObject);
        // console.log("went back", this.back);
        // console.log("Skipped shouldAttach", !canAttach && !this.back);

        // This if/elif clause - along with "lastObject" - are needed after Angular 14 upgrade.
        // RouteReuseStrategy is now fired multiple times per navigation and only the last one is kept
        // but this.back = false for all instances after the first one.
        if (!storedObject) {
            // console.warn("Stored object null - NO ATTACH")
            return false
        } else if (this.lastObject === storedObject) {
            // console.warn("last = stored")
            return true
        }

        if (!canAttach || !this.back) {
            // console.log("shouldAttach skipped!");

            // reset back navigation gauge
            this.back = false
            return false;
        }

        this.lastObject = storedObject
        this.back = false
        const paramsMatch = this.compareObjects(route.params, storedObject.snapshot.params);
        const queryParamsMatch = this.compareObjects(route.queryParams, storedObject.snapshot.queryParams);

        // console.log('deciding to attach...', route, 'does it match?');
        // console.log('param comparison:', paramsMatch);
        // console.log('query param comparison', queryParamsMatch);
        // console.log(storedObject.snapshot, 'return: ', paramsMatch && queryParamsMatch);

        return paramsMatch && queryParamsMatch;
    }

    // Determines whether or not the current route should be reused
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    // Finds the locally stored instance of the requested route, if it exists, and returns it
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        // console.log("RETRIVE was run");
        const id = this.createIdentifier(route);
        if (!route.routeConfig || !this.storedRoutes[id]) return null;
        return this.storedRoutes[id].handle;
    }

    private createIdentifier(route: ActivatedRouteSnapshot) {
        // Build the complete path from the root to the input route
        const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
        const subpaths = ([] as UrlSegment[]).concat(...segments).map(segment => segment.path);
        // Result: ${route_depth}-${path}
        return segments.length + '-' + subpaths.join('/');
    }

    private compareObjects(base: any, compare: any): boolean {

        // loop through all properties
        for (const baseProperty in { ...base, ...compare }) {

            // determine if comparrison object has that property, if not: return false
            if (compare.hasOwnProperty(baseProperty)) {
                switch (typeof base[baseProperty]) {
                    // if one is object and other is not: return false
                    // if they are both objects, recursively call this comparison function
                    case 'object':
                        if (typeof compare[baseProperty] !== 'object' || !this.compareObjects(base[baseProperty], compare[baseProperty])) {
                            return false;
                        }
                        break;
                    // if one is function and other is not: return false
                    // if both are functions, compare function.toString() results
                    case 'function':
                        if (typeof compare[baseProperty] !== 'function' || base[baseProperty].toString() !== compare[baseProperty].toString()) {
                            return false;
                        }
                        break;
                    // otherwise, see if they are equal using coercive comparison
                    default:
                        // tslint:disable-next-line triple-equals
                        if (base[baseProperty] != compare[baseProperty]) {
                            return false;
                        }
                }
            } else {
                return false;
            }
        }

        // returns true only after false HAS NOT BEEN returned through all loops
        return true;
    }

    private backMonitoring() {

    }
}

// ----------------------------------------------------------
//  STOP listetning to onScroll events everywhere in the app
// ----------------------------------------------------------

export interface OnAttach {
    /**
     * A callback method that is invoked when the RouteReuseStrategy instructs
     * to re-attach a previously detached component / subtree
     */
    onAttach(activatedRoute: ActivatedRoute): void;
}
export interface OnDetach {
    /**
     * A callback method that is invoked when the RouteReuseStrategy instructs
     * to detach component / subtree
     */
    onDetach(activatedRoute: ActivatedRoute): void;
}
@Directive({
    selector: 'app-router-outlet',
})
export class AppRouterOutletDirective extends RouterOutlet {
    // attach() & detach() fire on all route changes, but onDetach() and
    // onAttach() fire only where {shouldReuse: true}
    detach(): ComponentRef<any> {
        // console.warn("dettttaaaaacchhhh");

        const instance: any = this.component; // main component is returned, not children
        // console.log(instance);

        if (instance && typeof instance.onDetach === 'function') {
            instance.onDetach();
        }
        return super.detach();
    }
    attach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute): void {
        // console.warn("attttaaaaacchhhh");
        // console.log(ref);

        super.attach(ref, activatedRoute);
        if (ref.instance && typeof ref.instance.onAttach === 'function') {
            ref.instance.onAttach(activatedRoute);
        }
    }
}
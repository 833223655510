import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CoreService } from '../../core/core.service';
import { ApiClient } from '../../services/api-client.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
// List Dialog
import { ListComponent } from '../list/list.component';
import { MatDialog } from '@angular/material/dialog';
import { User, Post } from '../../interfaces/interfaces';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() firstPosts: Post[] = []
  @Input() postsSource: "home" | "feed" | "search" | "private" = "home"
  @Input() scrollDisabled: boolean
  @Input() query: string
  @Input() pageSize: number = 10
  @Input() pageIndex: number = 0

  constructor(public core: CoreService, private route: ActivatedRoute, 
    private location: Location, public dialog: MatDialog, 
    private apiClient: ApiClient, public auth: AuthenticationService) {

  }

  public masonryOptions: NgxMasonryOptions = {
    gutter: 10,
    itemSelector: "#item",
    columnWidth: "#item",
    horizontalOrder: true,
    // fitWidth: true, // https://masonry.desandro.com/options.html#fitwidth
    percentPosition: true,
    resize: false, // soft keyboard opening collapses RouteReused layout if resize:true - refresh is needed in that case
    animations: {}
  };

  // selector: string = '.masonry'

  // @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  // @ViewChild(VirtualScrollerComponent, { static: false }) virtualScroller: VirtualScrollerComponent;

  postRef: string
  loading: boolean
  uid: string
  posts: Post[] = []
  fetch_post_below = 3 // fetch new posts when X remaining under the fold
  posts_len: number = this.fetch_post_below + 1 // avoids getting next batch onInit

  endReached = false

  ngOnInit() {
    // Get posts
    this.loading = true
    if (this.postsSource == "feed") {
      this.apiClient.getFeed(this.pageSize, this.pageIndex, "post")
        .subscribe({
          next: posts => this.updateArray(posts), 
          error: err => console.error(err)
        })
    } else if (this.postsSource == "search") {
      if (this.posts.length == 0) {
        this.updateArray(this.firstPosts)
      } else {
        this.apiClient.search(this.query, "posts", undefined, this.pageSize, this.pageIndex)
        .subscribe(res => {
          this.updateArray(res.posts)
        })
      }
    } else if (this.postsSource == "home") {
      // main page posts
      this.apiClient.getPosts(this.pageSize, this.pageIndex)
        .subscribe({
          next: posts => this.updateArray(posts), 
          error: err => console.error(err)
      })
    }
    // -----------------

    // postRef (will be) used to scroll automatically to the last post the user swiped to, before returning to the timeline
    this.postRef = this.route.snapshot.paramMap.get("postRef")
    // console.log(this.postRef);
    // console.log(this.getIndex(this.posts, this.postRef))
  }

  ngAfterViewInit() {
    if (this.postRef) {
      let index = this.getIndex(this.posts, this.postRef)
      console.log(index);
    }
  }

  get lastPost(): object {
    return this.posts.slice(-1)[0]
  }

  // Gets index of the URL's postRef, to scrollTo there
  getIndex(array, id) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i;
      }
    }
    return null;
  }

  onScroll() {
    console.log('scrolled!!');
    if (!this.endReached) {
      this.pageIndex += 1
      this.ngOnInit()
    }
  }

  updateArray(posts) {
    console.log(posts);
    console.log(posts.data.length);
    
    this.endReached = posts.data.length < this.pageSize
    if (posts.data.length) { // (itemsLoaded) in view does not fire for zero-size arrays
      this.posts = this.apiClient.mergePosts(this.posts, posts)
      // this.loading = false // handled by (itemsLoaded) in the view
    } else {
      this.loading = false
    }
    console.log(`Page index: ${this.pageIndex}, loaded: ${this.posts.length}, end: ${this.endReached}`)


  }
}

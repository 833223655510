<mat-tab-group style='min-height:300px' color="accent" mat-stretch-tabs mat-align-tabs="center" 
[disablePagination]="true" (selectedTabChange)="tabChange($event)">
    <mat-tab label="top">
        <ng-template mat-tab-label>
            <ng-container *ngIf="auth.userObject && auth.userObject.username;else visibilityOff">
                <mat-icon>whatshot_outlined</mat-icon>
            </ng-container>
            <ng-template #visibilityOff>
                <mat-icon class="material-icons-outlined">visibility_outlined</mat-icon>
            </ng-template>
        </ng-template>
        <div class="title">Κορυφαία hashtags</div>
        <app-horizontal tag="top" count="8"></app-horizontal>
        <div class="title">Κορυφαίες αναρτήσεις</div>
        <app-timeline [scrollDisabled]="scrollDisabled || tabConfing['top'].tabScrollDisabled"></app-timeline>
    </mat-tab>

    <ng-container *ngIf="auth.userObject && auth.userObject.username;else unverified">
        <mat-tab label="feed">
            <ng-template mat-tab-label>
                <mat-icon>people_outlined</mat-icon>
            </ng-template>
            <div class="title">Ακολουθείτε</div>
            <ng-container *ngIf="tabConfing['feed'].visited">
                <app-timeline postsSource="feed" [scrollDisabled]="scrollDisabled || tabConfing['feed'].tabScrollDisabled">
                </app-timeline>
            </ng-container>
        </mat-tab>
        <mat-tab label="fresh">
            <ng-template mat-tab-label>
                <mat-icon>schedule_outlined</mat-icon>
            </ng-template>
            <div class="title">Πρόσφατα</div>
            <ng-container *ngIf="tabConfing['fresh'].visited">
                <app-grid postsSource="fresh" pageSize="18" sorting="Freshness" [scrollDisabled]="scrollDisabled || tabConfing['fresh'].tabScrollDisabled">
                </app-grid>
            </ng-container>
        </mat-tab>
    </ng-container>
    <ng-template #unverified>
        <mat-tab label="private">
            <ng-template mat-tab-label>
                <mat-icon class="material-icons-outlined">visibility_off_outlined</mat-icon>
            </ng-template>
            <div class="title" style="text-align: center;">
                Αναρτήσεις που τα μέλη έχουν ορίσει ως ορατές μόνο σε άλλα μέλη.
                <br> 
                <p class="gradient-text" style="display: inline-block;" routerLink="/register">Έχετε πρόσκληση;</p>
            </div>
            <ng-container>
                <app-timeline postsSource="private" scrollDisabled="true">
                </app-timeline>
            </ng-container>
        </mat-tab>
    </ng-template>
</mat-tab-group>


<!-- <ng-template  #unverified>
    <app-guest-page></app-guest-page>
</ng-template> -->

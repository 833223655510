<header class="center-title">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <button mat-button mat-icon-button *ngIf="isLogin || isPasswordReset" (click)="changeType('login')">
        <mat-icon id="back">arrow_back_ios</mat-icon>
      </button>
      <h3 class="title" *ngIf="isLogin">Είσοδος</h3>
      <h3 class="title" *ngIf="isPasswordReset">Επαναφορά κωδικού</h3>
      <h3 class="title" *ngIf="pendingVerification">Κατάσταση Λογαριασμού</h3>
      <span class="spacer" *ngIf="isLogin || isPasswordReset"></span>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<div class="wrapper">
  <div *ngIf="pendingVerification">
    <div *ngIf="auth.firebaseUser | async as fu">
      <div *ngIf="!fu.emailVerified; then verifyMail; else pendingVerification"></div>
      <!-- <div *ngIf="!fu.emailVerified; then pendingVerification; else verifyMail"></div> -->

      <ng-template #verifyMail>
        <div class="verify-email">
          <mat-icon class="material-icons-outlined gradient-text">mail</mat-icon>
        </div>
        <h2>Επιβεβαιώστε την διεύθυνση email σας!</h2>
        <div class="resend-mail"><em>Δεν λάβατε email;</em> 
          <button mat-button style="text-decoration: underline;" [disabled]="loading" (click)="sendMailVerification(fu)">Επαναποστολή</button>
        </div>
      </ng-template>

      <ng-template #pendingVerification>
        <!-- <h2 *ngIf="!(auth.authState | async).userName">Tο προφίλ σας είναι υπο επεξεργασία.</h2> -->
        <div class="verify-email">
          <mat-icon class="material-icons-outlined gradient-text">schedule</mat-icon>
        </div>
        <h2>Η εγγραφή σας έχει σχεδόν ολοκληρωθεί!</h2>
        <p style="text-align: center;">Θα ενημερωθείτε εντός 24ων ωρών για την αποδοχή ή την απόρριψη του λογαριασμού σας, απο κάποιο μέλος της ομάδας μας.</p>
      </ng-template>

      <div class="pending-user">
        <p>Όνομα χρήστη: <span class="value">@{{fu.displayName}}</span></p>
        <p>Email: <span class="value">{{fu.email}}</span></p>
        <p>Email επιβεβαιωμένο: <span class="value" [ngClass]="{'accept': fu.emailVerified, 'warn': !fu.emailVerified}">{{fu.emailVerified ? 'Ναι' : 'Όχι'}}</span></p>
        <p>Φωτογραφία προφίλ:</p>
        <div style="text-align: center;"><img [src]="'/api/users/registration-avatar/' + fu.displayName + '/640'" width="200"></div>
        <!-- <pre>{{fu | json}}</pre> -->
      </div>
      <button class="logout" mat-raised-button (click)='auth.logout()'>
        Αποσύνδεση
      </button>
    </div>
  </div>

  <form *ngIf="!pendingVerification" [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-form-field [color]="email.valid && 'accent'">
      <!-- Dev environment shows user dropdown -->
      <ng-container *ngIf="!production">
        <mat-select placeholder="Email" (selectionChange)="selectedUser($event.value)">
          <mat-option *ngFor="let demoUser of demoUsers" [value]="demoUser">
            {{demoUser}}
          </mat-option>
        </mat-select>
      </ng-container>
      <ng-container *ngIf="production">
        <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off"/>
      </ng-container>

      <mat-error *ngIf="email.invalid && email.dirty">
        Το email σας φαίνεται να μην είναι έγκυρο.
      </mat-error>
    </mat-form-field>

    <mat-form-field [color]="email.valid && 'accent'" *ngIf="!isPasswordReset">
      <input matInput formControlName="password" [type]="showPass ? 'text' : 'password'" type="password"
             placeholder="Κωδικός πρόσβασης" autocomplete="off"/>
      <mat-icon matSuffix (click)="showPass = !showPass">{{showPass ? 'visibility' : 'visibility_off'}}</mat-icon>

      <mat-error *ngIf="password.invalid && password.dirty">
        Εισάγετε τον κωδικό σας
      </mat-error>
    </mat-form-field>

    <mat-error class="server-error">{{ serverMessage }}</mat-error>

    <button *ngIf="isPasswordReset" mat-stroked-button class="warn" type="submit" [disabled]="loading">
      Επαναφορά κωδικού
    </button>

    <button *ngIf="!isPasswordReset" mat-flat-button color="accent" type="submit"
            [disabled]="loading">
      Είσοδος
    </button>

  </form>

  <button mat-button class="forgot-pass" *ngIf="isLogin" (click)="changeType('reset')">
    Ξέχασα τον κωδικό μου
  </button>

  <div class="register" *ngIf="isLogin">
    Έχετε πρόσκληση; <br>
    <button mat-button color="accent" [routerLink]="'/register'">
      Εγγραφείτε εδώ
    </button>
  </div>

</div>

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  useEmulators: false,
  baseHref: 'localhost:4201',
  firebaseConfig: {
    apiKey: "AIzaSyBmu3oq4pYGUvbou_sF6JiB9KE7YtiQ7bw",
    authDomain: "gimni-ellada-dev.firebaseapp.com",
    databaseURL: "https://gimni-ellada-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gimni-ellada-dev",
    storageBucket: "gimni-ellada-dev.appspot.com",
    messagingSenderId: "947179219297",
    appId: "1:947179219297:web:eb77a13b38876d9d533d20",
    measurementId: "G-JJXSP3QDK1"
  }
};

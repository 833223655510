import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

@Component({
  selector: 'app-upload-canvas',
  templateUrl: './upload-canvas.component.html',
  styleUrls: ['./upload-canvas.component.scss']
})
export class UploadCanvasComponent implements OnInit {

  maxFileSizeMB = 25 // MB
  maxFileSize = this.maxFileSizeMB * 1024 * 1024 // bytes

  imgURI: string = ''
  uploadedFile: string

  constructor() { }

  public uploader: FileUploader = new FileUploader({ url: '/api/upload/image' });
  ngOnInit(): void {
    const options: FileUploaderOptions = {
      autoUpload: false,
      allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg'],
      maxFileSize: this.maxFileSize
    };
    this.uploader.setOptions(options);
    // Remove previous file from upload queue when selecting a new one
    this.uploader.onAfterAddingFile = (f) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }      
    }
  }

  /** Sets image to upload, resizes it and returns its URL */
  async setImage(event) {
   this.imgURI = await this.resize(event)
   return this.imgURI
  }

  /** Uploads setImage (single image) */
  uploadImage(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.uploader.onCompleteItem = (item, response, status) => {
        this.uploadedFile = response
        console.log(item, response, status);
        resolve(response)
        if (status != 200) {
          reject
        }
      };
      const blobImage = this.dataURItoBlob(this.imgURI)
      const fileImage = new File([blobImage], ".jpg");
      const fileItem = new FileItem(this.uploader, fileImage, {});
      this.uploader.queue.push(fileItem);
      fileItem.upload();
    })

  }

  // Template reference to the canvas element
  @ViewChild('myCanvas') myCanvas: ElementRef;

  // Canvas 2d context
  private context: CanvasRenderingContext2D;
  ngAfterViewInit() {
    this.context = (this.myCanvas.nativeElement as HTMLCanvasElement).getContext('2d');
  }

  // Create blob from canvas DataURL -> used for upload
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    var ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  private resize(event):Promise<string> {
    // img.onload is async and starts rinning when img.src is assigned
    return new Promise((resolve, reject) => {
      const file = event.target.files[0]
      const maxDimention = 1920
      var outputURI: string
      var img = new Image;
      img.onload = () => {
        var iw = img.width;
        var ih = img.height;
        if (iw > maxDimention || ih > maxDimention) {
          var scale = Math.min((maxDimention / iw), (maxDimention / ih));
          var iwScaled = iw * scale;
          var ihScaled = ih * scale;
          (this.myCanvas.nativeElement as HTMLCanvasElement).width = iwScaled;
          (this.myCanvas.nativeElement as HTMLCanvasElement).height = ihScaled;
          this.context.drawImage(img, 0, 0, iwScaled, ihScaled);
          outputURI = (this.myCanvas.nativeElement as HTMLCanvasElement).toDataURL('image/jpeg', 0.9)
          // this.newImageSmaller(file, outputURI)
          console.log('New size: ', Math.round((outputURI.length - 23) * 3 / 4) / 1000, 'kB');
        } else {
          (this.myCanvas.nativeElement as HTMLCanvasElement).width = iw;
          (this.myCanvas.nativeElement as HTMLCanvasElement).height = ih;
          this.context.drawImage(img, 0, 0, iw, ih);
          outputURI = (this.myCanvas.nativeElement as HTMLCanvasElement).toDataURL('image/jpeg', 0.9)
          console.log('New size: ', Math.round((outputURI.length - 23) * 3 / 4) / 1000, 'kB');
        }
        // console.log(outputURI);
        resolve(outputURI)
        img.onerror = reject
      }
      img.src = URL.createObjectURL(file);
    })
  }

  // OPTIONAL: upload original photo if resized one is larger. 
  // You'll  need to rewrite the upload fucntion to take as it takes URI as input, not image
  // newImageSmaller(originalFile, compressedURI) {
  //   const originalSize = originalFile.size / 1000
  //   const newSize = Math.round((compressedURI.length - 23) * 3 / 4) / 1000
  //   console.log(`Original size: ${originalSize} kB`);
  //   console.log(`Compressed size: ${newSize} kB`);
  //   console.log("newImageSmaller", newSize < originalSize);
    
  //   return newSize < originalSize
  // }

}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../authentication.service";

@Injectable()
export class AuthGuard implements CanActivate{

  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.auth.authState
        .pipe(
          map(user => {
            // User approved => redirect to profile / else visit route
            if (user && 'username' in user) return this.router.parseUrl(`/@${user.username}`)
            else return true
          })
        )
  }

}
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../authentication.service";

@Injectable()
export class AdminGuard implements CanActivate{

  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.auth.authState
        .pipe(
          map(user => {
            // User is admin => visit route / else redirect to 404
            if (user && user.role == "admin") return true
            else return this.router.parseUrl(`/404`)
          })
        )
  }

}
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiClient } from 'src/app/services/api-client.service';
import { searchResults, User } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-search-query',
  templateUrl: './search-query.component.html',
  styleUrls: ['./search-query.component.scss']
})

export class SearchQueryComponent implements AfterViewInit {

  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
    this.changeDetectorRef.detectChanges()
  }
  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.scrollDisabled = true
    this.changeDetectorRef.detectChanges()
  }
  
  scrollDisabled: boolean = false

  @ViewChild('search') searchElement: ElementRef;

  constructor(public router: Router, private changeDetectorRef:ChangeDetectorRef, 
    public location: Location, public apiClient: ApiClient) { }

  ngAfterViewInit(): void {
    this.searchQuery = history.state.data ? history.state.data.search : ''
    this.searchElement.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }

  resultsInitiator = {
    query: "",
    posts: {
      count: 0,
      data: []
    },
    users: {
      count: 0,
      data: []
    },
    hashtags: {
      count: 0,
      data: []
    },
    locations: {
        count: 0,
        data: []
    }
  }

  searchQuery: string = ""
  searchTimer: any // user for time counting
  results: searchResults = this.resultsInitiator

  loading:boolean = false

  liveSearch(input: string) {
    console.log('Input', input);
    // input ? this.subPage = 'liveSearch' : this.subPage = 'searchMain'

    // drop old items on each new search
    // this.results.length = 0
    this.loading = true

    clearTimeout(this.searchTimer)

    if (input.length >= 1) {
      this.searchTimer = setTimeout(() => {
        input = input.toLocaleLowerCase()
        this.apiClient.search(input)
        .subscribe(res => {
          this.results = res
          // console.log(this.results);
          this.location.replaceState(`/search/query?q=${input}`) // url tracked by analytics when replaceState is used
          this.loading = false
        })
      }, 500);
    } else {
      // stop loading on empty query
      // Object.keys(this.results).forEach(key => {
      //   delete this.results[key];
      // })
      this.results = this.resultsInitiator
      this.loading = false
    }
  }

  fullSearch() {
    if (this.searchQuery) {
      // query page stays in history only once (the first time)
      this.router.navigate([`/search/results`, this.searchQuery], {replaceUrl: true }, )
    }
  }

  clearSearch() {
    this.searchQuery = ''
    this.loading = false
  }

  back() {
    this.location.back()
  }

}
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import {from, interval, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {catchError, filter, first, map, mergeMap, takeUntil} from 'rxjs/operators';
import { User, authUser, LoginResult, qrData} from '../interfaces/interfaces';
import { ApiClient } from './api-client.service';
import { A2hsService } from './pwa-install.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private _authState: ReplaySubject<authUser | null>;
  private _firebaseUser: ReplaySubject<firebase.User | null>;
  private activityCountListener: Subscription
  public userObject:authUser
  public liveCount:number // only for count change detection in order to push to _authState

  public qrData: qrData

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private apiClient: ApiClient,
    private pwa: A2hsService
  ) {

    this._authState = new ReplaySubject<authUser | null>(1);
    this._firebaseUser = new ReplaySubject<firebase.User | null>(1);

    // If user was already logged in, preload 'user' from localStorage for faster navbar loading
    if (localStorage.getItem('user') != null) {
      const userObject = JSON.parse(localStorage.getItem('user'))
      // don't load from localStorage is user status is pending for approval or rejection
      // always load fresh in case of status update
      if (userObject.status != "pending") {
        this._authState.next(userObject);
      }
    }
    
    // Whenever a new item is emitted, update userObject and localStorage
    this._authState.subscribe(value => {
      if (value) {
        this.userObject = value
        localStorage.setItem('user', JSON.stringify(value));
      } else {
        localStorage.clear()
        this.userObject = {status: "guest"}
      }
    });

    // Observe current user in firebase (User or null)
    this.afAuth.authState
      .subscribe({
        next: user => {
          // console.info("AUTH STATE CHANGE DETECTED")
          console.info("firebaseUser", user)
          if (user) {
            this.setupUser(user)
            this._firebaseUser.next(user)
          } else {
            this.afAuth.signInAnonymously().then(res => {
              console.log("ANON login", res)
            })
            // authSate now changes and returns User to this listener
            // TODO: try user.delete() for anon firebase users. Actually, better remove anon users

            // If localStorage 'user' exists but user is not logged, delete it (to avoid errors)
            this._authState.next(null);
            if (this.activityCountListener) {
              this.activityCountListener.unsubscribe()
            }
          }
        },
        error: err => {console.error}
      })
  }

  public static tokenGetter(): any {
    if (localStorage.getItem('user') != null) {
      const user = <authUser>JSON.parse(localStorage.getItem('user'));
      return user.idToken;
    }
    return null;
  }

  login(email: any, password: any): Observable<LoginResult> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(
        first(),
        map(response => {
          this.router.navigate(['/']);
          return {
            success: true,
            errorMessage: null
          };
        }),
        catchError(error => {
          // let message = 'unknown error';
          // if (error.code == 'auth/user-disabled') {
          //   message = 'Λίγο έμεινε! Ο λογαριασμός σας βρίσκεται υπο εξέταση και θα ειδοποιηθειτε με email για το αποτέλεσμα.';
          // } else if (error.code == 'auth/user-not-found') {
          //   message = 'Δεν υπάρχει χρήστης με αυτά τα στοιχεία.';
          // } else if (error.code == 'auth/wrong-password') {
          //   message = 'Ο κωδικός δεν είναι σωστός. Σιγουρευτείτε οτι τα κεφαλαία και τα πεζά είναι σωστά.';
          // }
          const message = "Βεβαιωθείτε οτι το email και ο κωδικός που έχετε εισάγει είναι σωστά"
          return of({
            success: false,
            errorMessage: message
          });
        }
      )
    );
  }

  async logout(redirect:boolean = true) {
    this.flushUserLocally()
    await this.afAuth.signOut()
    if (redirect) this.router.navigate(['/']);
  }

  flushUserLocally() {
    this._authState.next(null)
    this._firebaseUser.next(null)
  }

  updateFirebaseUser() {
    this.afAuth.currentUser.then(user => {
      this._firebaseUser.next(user)
      // console.log(user);
    })
  }

  private setupUser(response: firebase.User) {
    response.getIdToken().then(idToken => {
      if (!response.isAnonymous) {
        console.log(`User displayName ${response.displayName}\n
        Last login ${(new Date(response.metadata.lastSignInTime)).toLocaleString()}`);


        // set new idToken OR in case it has been deleted by the user
        localStorage.setItem('user', JSON.stringify({idToken: idToken}))
        // commented out because expired tokens are taken directly from Interceptor

        // get user information from the Server
        this.apiClient.getMe()
          .pipe(first())
          .subscribe(user => {
            console.log("SERVER USER:", user);

            // let userObject:authUser
            if (user.profile) {
              this.userObject = {
                username: user.username,
                postCount: user.posts.count,
                sex: user.profile.sex,
                sex1: user.profile.sex1,
                sex2: user.profile.sex2,
                birthdate: user.profile.birthdate,
                birthdate2: user.profile.birthdate2,
                location: user.profile.location,
                orientation: user.profile.orientation,
                orientation2: user.profile.orientation2,
                avatar: user.profile.avatar,
                activitySubjectCountSince: user.activitySubjectCountSince,
                role: user.role,
                idToken: idToken,
                status: "verified",
                availableInvitations: user.availableInvitations
              }
            
              this._authState.next(this.userObject)

              // if user is admin, disable analytics
              if (user.role == "admin") {
                localStorage.setItem('umami.disabled', "true");
              }

              // Start listening for activity (every N=5 seconds)
              this.activityCountListener = interval(5 * 1000)
                .pipe(
                  mergeMap(() => this.apiClient.activityLiveCount()),
                  filter(res => !!res)
                )
                .subscribe((res) => {
                  // TODO: Update _authState (which also updates local storage)
                  if (this.liveCount != res.activitySubjectCountSince) {
                    console.log(new Date().toLocaleTimeString('en-GB'), res);
                    this.liveCount = res.activitySubjectCountSince
                    this.updateUserObject(res)
                  }
                  // Track session for logged users
                  this.trackSession()
                }
                  // (err => console.error)
                )}
            // User registration pending acceptance
            else {
              this._authState.next({
                idToken: idToken,
                status: "pending"
              })
            }
          })
      }
      // Anonymous user
      else {
        this.userObject = {
          idToken: idToken,
          status: "guest"
        }
        this._authState.next(this.userObject);
      }
    });
  }


  // authState is the logged-in User object, also stored in localStorage
  get authState(): Observable<authUser | null> {
    return this._authState.asObservable();
  }

  get firebaseUser(): Observable<firebase.User | null> {
    return this._firebaseUser.asObservable();
  }

  async refreshToken(request:string | object) {
    return await new Promise<string>(resolve =>
      this.firebaseUser
      .pipe(
        filter(res => !!res),
        first()
        ) // AWAITS for the first non-null value to be emmited
      .subscribe(async res => {
        console.log(res);
        
        // if firebaseuser null, wait for anon login
        const token = (await res.getIdTokenResult()).token
        console.log("TOKEN REFRESHED for:", request, '\nToken:', token.substring(0,10)+"...");

        resolve(token)
        // Push new token to _authState & localStorage
        if (token) {
          this.updateUserObject({idToken: token})
          // let userObject = <authUser>JSON.parse(localStorage.getItem('user'));
          // // userObject ? userObject.idToken = token : userObject = {idToken: token, status}
          // this.userObject["idToken"] = token // raplaced the above line
          // this._authState.next(userObject)
        }
      })
    )
  }

  // trackSession helper functions and variable
  prevSession: number
  trackingInterval = 1 * 60 * 60 * 1000; // 1 hour in milliseconds
  // Access localStorage only of prevSession variable does not exist locally
  get getPrevSession() {
    if (this.prevSession) return this.prevSession
    else return localStorage.getItem('session') ? parseInt(localStorage.getItem('session')) : null;
  }
  // Track logged user sessions for PWA/web
  trackSession() {
    const now = new Date().getTime();
    const timeBetween = now - this.getPrevSession;
    
    // Track session if interval has passed && tab/PWA is focused
    // A null prevSession returns a 50-year timeBetween
    if ( timeBetween > this.trackingInterval && document.visibilityState == "visible") {
      // @ts-ignore
      umami.track(`Session - @${this.userObject.username} on ${this.pwa.isStandalone ? "PWA" : "web"}`, {user: this.userObject.username, source: this.pwa.isStandalone ? "PWA" : "web"})
      this.prevSession = now
      localStorage.setItem('session', JSON.stringify(now));
      // console.log(`Session - @${this.userObject.username} on ${this.pwa.isStandalone ? "PWA" : "web"}, ${timeBetween/this.trackingInterval} hours ago.`);
    }
  }

  // Updates new values only to target object
  updateUserObject(userFields:authUser | object) {
    let userObject = <authUser>JSON.parse(localStorage.getItem('user'));
    userObject = Object.assign(userObject, userFields)
    this._authState.next(userObject)
  }

  forgotPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email)
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }

}

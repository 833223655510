import { Component, OnInit, Input, ViewChild, SecurityContext } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CoreService } from '../../core.service';
import { first } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiClient } from 'src/app/services/api-client.service';
import { Post, reportType } from 'src/app/interfaces/interfaces';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-more-menu',
  templateUrl: './more-menu.component.html',
  styleUrls: ['./more-menu.component.scss'],
  exportAs: 'moreMenu'
})
export class MoreMenuComponent {

  constructor(
    public auth: AuthenticationService, 
    private dialog: MatDialog, public core: CoreService, 
    private router: Router, public apiClient: ApiClient,
    private dom: DomSanitizer
  ) { }
  
  @Input() targetType: reportType;
  @Input() username: string;
  @Input() targetRef: string = null;
  @Input() self: boolean = false
  @Input() mentioned: Post["mentions"][0]["status"] = "pending"
  @Input() blocked: boolean = false;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  url: string = window.location.href

  get copy() {
    // this.url = window.location.href
    // snack returns too fast
    return setTimeout(() => {
      this.core.snackBarNotification('Ο σύνδεσμος αντιγράφηκε.')
    }, 200);
  }

  report() {
    if (this.auth.userObject.status == "guest") {
      // this.router.navigate(['/login'], { replaceUrl: true })
      // Show email in dialog
      const mailText = `<p>Για να αναφέρετε μη επιτρεπτό περιεχόμενο, στείλτε μας email στο:</p><h4>reports@naked-greece.com<h4p>`
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          type: 'info',
          message: this.dom.sanitize(SecurityContext.HTML, mailText)
        }
      })
      return
    }

    this.core.report(this.targetType, this.targetRef)
    
  }

  updatePost() {
    return this.core.updatePost(this.targetRef, this.targetRef == this.auth.userObject.avatar)
  }

  share() {
    console.log(this.targetType, this.username, this.targetRef, this.blocked);
    
    navigator.share({
      title: 'Naked Greece',
      // text: 'hey check out my Share button',
      url: this.url
    })
      .then(console.log)
      .catch(console.error);
  }

  async blockUnblock() {
    if (this.auth.userObject.status == "guest") {
      this.router.navigate(['/login'], { replaceUrl: true })
      return
    }
    // Confirmation Dialog
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { type: 'blockConfirmation', user: this.targetRef, block: !this.blocked }
    })
    dialogRef.afterClosed().subscribe(async result => {
      console.log(result);
      if (result == "ok") {
        this.apiClient.blockUnblock(this.targetRef)
          .subscribe(res => this.core.reloadCurrentRoute())
      }
    });
  }

  deletePost(postRef) {
    if (this.auth.userObject.postCount == 1) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { 
          type: 'info', 
          message: "Τα ενεργά προφίλ πρέπει να έχουν τουλάχιστον μια ανάρτηση. Μπορείτε να απενεργοποιήσετε το προφίλ σας απο στις ρυθμίσεις." 
        }
      })
    } else if (postRef == this.auth.userObject.avatar) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { 
          type: 'info', 
          message: "Αλλάξτε την φωτογραφία προφίλ σας προκειμένου να διαγράψετε αυτή την ανάρτηση."
        }
      })
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { type: 'deleteConfirmation' }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result == "delete") {
          this.apiClient.deletePost(postRef)
            .subscribe(
              res => {
                console.log(res);
                if (res) {
                  this.core.snackBarNotification("Η ανάρτησή σας διαγράφηκε.", ['/', '@' + this.username])
                } else {
                  this.core.snackBarNotification("Υπήρξε κάποιο σφάλμα, δοκιμάστε αργότερα.")
                }
              },
              // TODO: Handle the errors somewhere central. How many errors are there? Different dialogs for each.
              err => {
                console.log('HTTP Error', err)
                alert("Error! Check console for details")
              }
            )
        }
      });
    }
  }

  approveMention() {
    let approve: boolean = false
    if (this.mentioned == "rejected") {
      approve = true
    }
    this.apiClient.approveMention(this.targetRef, approve)
      .subscribe(() => {
        this.core.snackBarNotification("Η κατάσταση της φωτογραφίας ανανεώθηκε.")
        this.core.reloadCurrentRoute()
      })
  }

}

<app-page404 *ngIf="is404" style="position: absolute; width:100%; padding-top: 56px; z-index: 2;"></app-page404>

<header class="left-title" *ngFor="let post of posts | slice:postIndex:postIndex+1">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <!-- Start -->
      <!-- Back Button -->
      <button mat-button mat-icon-button (click)="location.back()">
        <mat-icon id="back">arrow_back_ios</mat-icon>
      </button>
      <!-- Profile Avatar + Name -->
      <div class="nav pointer"(click)="backOrProfile()">
        <!-- class="swiper-lazy" [attr.data-src]="post?.avatar" -->
        <div [ngClass]="['avatar', 'avatar-32', 'sex-' + post?.author.profile.sex]">
          <img *ngIf="post" [src]="apiClient.userAvatar(post.author.username, post.author.profile.avatar, 90)" (error)="core.onImgError($event)">
        </div>
        <h3 class="user">@{{post?.author.username}}</h3>
      </div>
      <span class="spacer"></span>
      <!-- End -->
      <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu.menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <app-more-menu #menu='moreMenu' targetType="post" [username]="post.author.username" [targetRef]="post.ref" 
        [self]="post.author.self" [mentioned]="mentioned(post)">
      </app-more-menu>
    </mat-toolbar-row>
    <mat-toolbar-row class="accept-mention" *ngIf="mentioned(post) == 'pending'">
      <span>Να εμφανίζεται η ανάρτηση στο προφίλ σας? </span>
      <div>
        <button mat-raised-button (click)="approveMention(post.ref, true)">
          Αποδοχή
        </button>
        <button mat-button (click)="approveMention(post.ref, false)">
          Απόρριψη
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
<swiper [config]="config" #usefulSwiper (window:resize)='resetHeight()' [style.height]="height">
  <div *ngIf="state" class="swiper-button-next"></div>
  <div *ngIf="state" class="swiper-button-prev"></div>
  <div class="swiper-wrapper">
    <!-- <section *ngFor="let post of posts" class="photo swiper-slide swiper-lazy"
            [style.background-image]="'url(' + post?.image + ')'"> -->
    <section *ngFor="let post of posts" class="swiper-slide">
      <!-- Loading spinner (move it between low-res and HD images to keep it spinning before HD download) -->
      <mat-spinner color="accent" [diameter]="30"></mat-spinner>
      <!-- Preloaded 320p image -->
      <section class="photo swiper-lazy" [attr.data-background]="post?.image[320]" 
      [ngStyle]="{'background-image': state ? 'initial' : 'url(' + post?.image[320] + ')'}"></section>
      <!-- Main HD image -->
      <section class="photo swiper-lazy" [attr.data-background]="post?.image[750]"
      [ngStyle]="{'background-image': state ? 'initial' : 'url(' + post?.image[750] + ')'}"></section>
      <!-- Blurry image background -->
      <div class="background-blur swiper-lazy" [attr.data-background]="post?.image[320]" 
      [ngStyle]="{'background-image': state ? 'initial' : 'url(' + post?.image[320] + ')'}">
      </div>

      <div class="controls">
        <div class="control">
          <button mat-icon-button [style.opacity]="post.author.self ? 0.3 : 'inherit'" 
            (click)="post.author.self ? pushState(post, 'likes') : likeUnlike(post.ref, !post.liked)">
            <mat-icon [class.loved]="post?.liked">{{post.liked ? "favorite" : "favorite_outlined"}}</mat-icon>
          </button>
          <span class="count pointer" (click)='pushState(post, "likes")'>{{post.likes | number: '1.'}}</span>
        </div>
        <div class="control" (click)='pushState(post, "comments")'>
          <button mat-icon-button>
            <mat-icon class="material-icons-outlined">insert_comment</mat-icon>
          </button>
          <span class="count">{{post.comments | number: '1.'}}</span>
        </div>
      </div>

      <div class="gradient-bottom">
        <div class="bottom">
          <div class="user-container pointer" [routerLink]="['/@' + post.author.username]">
            <a [ngClass]="['avatar', 'avatar-28', 'sex-' + post?.author.profile.sex]">
              <!-- [src] might be preloading all avatars on the array. Use it only if !state -->
              <img class="swiper-lazy" *ngIf="post" [attr.data-src]="apiClient.userAvatar(post.author.username, post.author.profile.avatar, 90)"
                [src]="apiClient.userAvatar(post.author.username, post.author.profile.avatar, 90)"
                (error)="core.onImgError($event)">
            </a>
            <a>
              <h3>@{{post?.author.username}}</h3>
            </a>
          </div>
          <div class="title">
            <ng-container *ngIf="auth.userObject.username; else hiddenTitle">
              <span hrefNavigation [innerHTML]="post?.title | linkedText"></span>
            </ng-container>
            <ng-template #hiddenTitle>
              <em>Τα κείμενα των φωτογραφιών είναι εμφανή μόνο στα μέλη 🔥</em>
            </ng-template>
            
            <span style="opacity: 0.5;font-size: 12px;">{{ !auth.userObject.username || post.title ? " · " : " " }}{{ post.created | timePassed }}</span>
          </div>
          <div class="latest-comment">
            <div *ngIf="auth.userObject.username && post.lastComment" class="comments" [routerLink]="['/p/' + post.ref + '/comments']" [fragment]="post.lastComment.ref">
              <mat-icon class="material-icons-outlined">mark_unread_chat_alt_outlined</mat-icon>
              <div class="text">
                <span class="name">@{{post.lastComment.author.username}}:</span>
                <span class="comment">{{post.lastComment.text}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</swiper>
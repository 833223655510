import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CoreService } from '../../../core/core.service';
import { Location } from '@angular/common';
import { searchResults, searchType, User } from '../../../interfaces/interfaces';
import { Router } from '@angular/router';
import { OnAttach, OnDetach } from 'src/app/app-route-strategy';
import { ActivatedRoute } from '@angular/router';
import { ApiClient } from 'src/app/services/api-client.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})

export class SearchResultsComponent implements OnInit, OnAttach, OnDetach {

  onAttach() {
    // Executes before restoring component. Restore Listeners and Scroll Subscriptions here
    // console.warn("OnAttach Executed");
    this.scrollDisabled = false
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.changeDetectorRef.detectChanges()
  }

  onDetach() {
    // Executes before detaching component. Stopping Listeners and scroll subscriptions here. You can also use activatedRoute to define additional conditions
    // console.warn("OnDetach Executed");
    this.scrollDisabled = true
    this.router.routeReuseStrategy.shouldReuseRoute = this.mainRouteReuse
    this.changeDetectorRef.detectChanges()
  }

  constructor(
    public core: CoreService, public location: Location, public router: Router,
    private changeDetectorRef: ChangeDetectorRef, private activatedRoute: ActivatedRoute,
    public apiClient: ApiClient
  ) {
    this.mainRouteReuse = this.router.routeReuseStrategy.shouldReuseRoute
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    // No need to unsubscribe from ActivatedRoute observables (from docs)
    this.activatedRoute.params
    .pipe(first())
    .subscribe(res => {
      console.log(res);
      // empty query reload redirects to /search
      if (!res.query) {
        this.router.navigateByUrl("/search")
      }
      this.searchQuery = res.query
      this.searchFor(this.searchQuery)
      // console.log(res);      
    })
  }

  mainRouteReuse: any

  scrollDisabled: boolean = false
  activeTab: searchType | any = "posts"

  pageSize = 12
  tabConfing = {
    posts: {pageIndex: 0, endReached: false, tabScrollDisabled: false},
    users: {pageIndex: 0, endReached: false, tabScrollDisabled: true},
    hashtags: {pageIndex: 0, endReached: false, tabScrollDisabled: true},
    locations: {pageIndex: 0, endReached: true, tabScrollDisabled: true} // endReached already true
  }
  
  results: searchResults 

  searchQuery: string
  loading = false

  searchFor(query: string, type: searchType = null) {
    this.loading = true
    query = query.toLocaleLowerCase()
    console.log(query, type);
    this.apiClient.search(query, type, undefined, this.pageSize, this.tabConfing[this.activeTab].pageIndex)
    .subscribe(res => {
      console.log(res);
      // first run -> populate results
      if (!this.results) {
        this.results = res
      } else {
        // loop keys and and append their respective new items
        for (var key in res) {
          // make sure that the key you get is an actual property of an object AND not "query" 
          if (res.hasOwnProperty(key) && key != "query") {
              console.log(res[key])
              this.results[key].data.push(...res[key].data)
              if (res[key].data.length < this.pageSize) {
                this.tabConfing[key].endReached = true
              }
          }
        }
      }
      console.log(this.results);
      this.loading = false
    })
  }

  queryPage() {
    this.router.navigate(['/search/query'], {state: {data: {search: this.searchQuery}}})
  }

  back() {
    this.location.back()
  }

  onScroll() {
    if (!this.tabConfing[this.activeTab].endReached) {
      this.tabConfing[this.activeTab].pageIndex += 1
      this.searchFor(this.searchQuery, this.activeTab)
    }
  }

  followUnfollow(username, index) {
    this.apiClient.followUnfollow(username)
      .subscribe((res) => {
        console.log(`Following @${username}: ${res.data.follow}`);
        if (res) {
          // Update follow button in view
          this.results.users.data[index].followingThem = res.data.follow
        }
      })
  }

  tabChange(event: MatTabChangeEvent) {
    this.tabConfing[this.activeTab].tabScrollDisabled = true
    this.activeTab = event.tab.textLabel
    console.log(this.activeTab);
    this.tabConfing[this.activeTab].tabScrollDisabled = false
  }

}

import { Injectable, SecurityContext } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollectionGroup, AngularFirestoreDocument, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { take, retry, map, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';
import { Location } from '@angular/common'
import { ApiClient } from '../services/api-client.service';
import { AuthenticationService } from '../services/authentication.service';

// Dialog - for confirmation
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component'
import { DomSanitizer } from '@angular/platform-browser';
import { ReportComponent } from '../components/report/report.component';
import { reportType } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  hasTouchScreen = false
  windowHeight: number

  regex = {
    // Can NOT start or end with "_" or ".", can't have 2 "_" in a row, 3-16 characters, numbers or _
    names: new RegExp("^[a-zA-Z0-9](?!.*[_]{2})(?!.*[\\.]{2})[a-zA-Z0-9_\\.]{1,16}[a-zA-Z0-9]$"),
    // Same as names but starts with @ and can match multiple items in a string (g)
    ats: new RegExp("@[a-zA-Z0-9](?!.*[_]{2})(?!.*[\\.]{2})[a-zA-Z0-9_\\.]{1,16}[a-zA-Z0-9]", "g"),
    // Same as ats but starts with # and limit is {3,20} characters
    hashtags: new RegExp("#[α-ωά-ώΑ-ΩΆ-Ώa-zA-Z0-9_]{3,30}", "g"),
    // hashtags regex starting with word boundary => RegExp("(?!\\b)#[α-ωά-ώΑ-ΩΆ-Ώa-zA-Z0-9_]{3,20}", "g"),
    // At least 8 characters, lowercase & numbers
    passwords: new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$", "g")
  }

  // Navbar settings
  navSettings = {
    title: null,
    topPadding: true,
    backBtn: false,
    // topBarDisplay: 'initial',
    // topBarHidden: 'visible',
    // navDisplay: true
    bottomNav: true
  }

  // navDisplayState = new BehaviorSubject<boolean>(navSettings)
  navDisplayState = new Subject<boolean>()

  constructor(
    private afs: AngularFirestore, private router: Router, private snackBar: MatSnackBar, public dialog: MatDialog,
    public location: Location, private apiClient: ApiClient, private auth: AuthenticationService, private dom: DomSanitizer
  ) {
    this.getTouchScreen()
    this.windowHeight = window.innerHeight
    console.log(this.windowHeight);
  }

  showNavBar(value:boolean) {
    this.navDisplayState.next(value)
  }

  //nanoid 1% collission => 50-3 600-4 4000-5 30000-6 300k-7 2.5M-8 => 2+log(likes+1) rounded up
  // const likeId = this.randomString(Math.ceil(2 + Math.log10(post.likes + 1)))
  randomString(string_length) {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz_-";
    var randomstring = '';
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring
  }

  // Check if device is mobile/tablet
  getTouchScreen() {
    if (!!navigator.maxTouchPoints) {
      this.hasTouchScreen = navigator.maxTouchPoints > 0;
      console.log("Device is mobile Android or iOS");
      // TODO: msMaxTouchPoints is for IE, I removed it, check if anything breaks
    // } else if (!!navigator.msMaxTouchPoints) {
    //   this.hasTouchScreen = navigator.msMaxTouchPoints > 0;
    //   console.log("Device is mobile Windows");
    } else {
      var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        this.hasTouchScreen = !!mQ.matches;
        // console.log("first");
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        this.hasTouchScreen = (
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
        // console.log("second");
      }
      console.log("Device !FALLBACK! - hasTouchScreen = ", this.hasTouchScreen);
    }
  }

  // Snackbar message
  snackBarNotification(text: string, redirectUrl: Array<any> = null, duration: number = 2000, replaceUrl=true) {
    this.snackBar.open(text, "X",
      {
        duration: duration,
        verticalPosition: 'top',
        panelClass: 'snackbar'
      });
    if (redirectUrl) {
      this.router.navigate(redirectUrl, { replaceUrl: replaceUrl })
    }
  }

  networkError: MatSnackBarRef<TextOnlySnackBar>
  networkErrorTimeout: any
  networkErrorShow() {
    if (!this.networkError) {
      this.networkError = this.snackBar.open(
        "Υπάρχει κάποιο πρόβλημα με το δίκτυο, δοκιμάστε αργότερα", 
        undefined,
        {
          verticalPosition: 'top',
          panelClass: 'snackbar'
        }
      )
    } else {
      clearTimeout(this.networkErrorTimeout)
    }
    this.networkErrorTimeout = setTimeout(() => {
      
      this.networkErrorDismiss()
    }, 6000);
  }
  // when network comes back, refresh component (not page)
  networkErrorDismiss() {
    this.networkError.dismiss()
    this.networkError = null
    this.reloadCurrentRoute()
  }

  reloadCurrentRoute(currentUrl:string = this.router.url) {;
    this.router.navigateByUrl('/new-post', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  // Current datetime that server can read
  get javaTimeDate() {
    return new Date().toISOString()
  }

  // Server readable DATE only (e.g. "1994-06-07")
  dateToLocaleDateString(date) {
    // "fr-CA" => YYYY-MM-DD
    return new Date(date).toLocaleDateString("fr-CA");
  }

  updateAvatar(postRef, newPost = false) {
    this.apiClient.setAvatar(postRef)
    .subscribe(() => {
      // update avatar locally
      this.auth.updateUserObject({avatar: postRef})
      this.auth.firebaseUser
        .pipe(first())
        .subscribe(user => user.updateProfile({photoURL: postRef}))

      if (newPost) {
        this.snackBarNotification("Η ανάρτησή σας κοινοποιήθηκε!", ['/p/', postRef])
      } else {
        this.snackBarNotification("Η φωτογραφία προφίλ σας, ανανεώθηκε!")
        setTimeout(()=>{
          window.location.href = `/@${this.auth.userObject.username}`
        }, 700);
      }
    })
  }

  changeAvatar() {
    const messageHtml = `
    <h3 class="gradient-text">Αλλαγή φωτογραφίας προφίλ</h3>
    <ol class="change-avatar">
    <li>Πλοηγηθείτε σε μια φωτογραφία σας</li>
    <li>Πατήστε το μενού</li>
    <li><i>"Να γίνει φωτογραφία προφίλ"</i></li>
    </ol>
    `
    const videoHtml = '<center><video height="200" autoplay loop><source src="https://i.imgur.com/4itGy2h.mp4" type="video/mp4"></video></center>'
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        type: 'info',
        message: this.dom.sanitize(SecurityContext.HTML, messageHtml+videoHtml)
      }
    })
  //   const dialogRef = this.dialog.open(GridComponent, {
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     height: '100%',
  //     width: '100%',
  //   })
  //   dialogRef.componentInstance.username = 'fire'
  //   dialogRef.componentInstance.firstPosts = []
  //   dialogRef.componentInstance.scrollDisabled = false
  }

  showTos() {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { type: 'tos' },
        backdropClass: 'darkBackdrop',
        panelClass: 'black-on-white',
        minWidth: 320
        // minWidth: '100vw', // FIXME: dialog not centered correctly if full screen
        // minHeight: '100vh',
        // height: '100%',
        // width: '100%',
      })
      dialogRef.afterClosed().subscribe(result => {
        resolve(result)
      });
    })
  }

  report( type:reportType, targetRef:string ) {
    this.dialog.open(ReportComponent, {
      data: {
        targetType: type,
        targetRef: targetRef
        // targetRef: type == "comment" ? `${this.router.url}/${targetRef}` : this.router.url
      },
      autoFocus: false,
      disableClose: true,
      minWidth: 280
    })
  }

  updatePost (postRef:string, isAvatar:boolean ) {
    this.apiClient.getPost(postRef).subscribe(post => {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          type: "updatePost",
          postRef: postRef,
          message: post.title,
          visibility: post.visibility,
          isAvatar: isAvatar,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: 280,
        // width: "100vw",
        // height: "80vh"
      })
    })
  }

  onImgError(event){
    event.target.style.visibility='hidden'
    event.target.parentElement.className += " img-placeholder"
    // event.target.src = './assets/img_placeholder.png'
    // more bindings https://developer.mozilla.org/en-US/docs/Web/API/Element
  }

}

<header>
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <!-- Start -->
      <div class="start">
        <button mat-button mat-icon-button (click)="location.back()">
          <mat-icon id="back">arrow_back_ios</mat-icon>
        </button>
      </div>
      <!-- Middle -->
      <img id="logo" src="assets/naked-greece-h.png" routerLink="/">
      <!-- End -->
      <div class="end"></div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<section>
  <h1>
    Η σελίδα αυτή δεν είναι διαθέσιμη.
  </h1>
  <p>
    H σελίδα που επισκεφτήκατε δεν υπάρχει, είναι προσβάσιμη απο συγκεκριμένα μέλη ή έχει αφαιρεθεί.
    <a [routerLink]="[ '/' ]" class="accent">Επιστροφή στο Naked Greece</a>
  </p>
</section>
<header class="center-title">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <!-- Back -->
      <!-- <button *ngIf="page != 'search'" mat-button mat-icon-button (click)="back()">
        <mat-icon id="back">arrow_back_ios</mat-icon>
      </button> -->
      <!-- input -->
      <!-- <mat-form-field id="search-bar" [floatLabel]="'never'">
        <mat-icon matPrefix class="prefix">search</mat-icon>
        <input matInput type="text" placeholder="Αναζήτηση" [(ngModel)]="search_term" 
        (ngModelChange)="liveSearch(search_term.trim())" autocomplete="off" (keydown.enter)="fullSearch()"
        (focus)="queryPage()">
        <button *ngIf="page == 'query'" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field> -->
      <!-- search button -->
      <!-- <button mat-button color="accent" *ngIf="page == 'query'" class="searchBtn" [disableRipple]="true" (click)="fullSearch()">Αναζήτηση</button> -->
    </mat-toolbar-row>
  </mat-toolbar>
</header>
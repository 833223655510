import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Hashtag, Post } from '../../interfaces/interfaces';
import { CoreService } from '../../core/core.service';
import { ApiClient } from '../../services/api-client.service';

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})
export class HorizontalComponent implements OnInit {

  @Input() tag: Hashtag | 'top' = 'top';
  @Input() count: number = 8;
  @ViewChild('horizontal') horizontal: ElementRef;

  scrolled = 'none';
  posts: Post[];
  tags: any //Hashtag[];

  constructor(public core: CoreService, public apiClient: ApiClient) {
  }

  ngOnInit() {
    if (this.tag == 'top') {
      this.apiClient.getPopularHashtags(20)
      .subscribe(tags => {
        this.tags = tags.data.map(tag => ({
          name: tag.text,
          count: tag.count,
          avatar: tag.avatar ? this.apiClient.imgToScrset(tag.avatar.split(":")[0], tag.avatar.split(":")[1]) : "null:null",
        }));
      });
    } else {
      // if this.tag = tag
      this.posts = this.tag.posts.data
    }
  }

  // Has user scrolled right?
  userScrolled() {
    setTimeout(() => {
      if (!this.horizontal) {
        return this.scrolled = 'none';
      }
      const element = this.horizontal.nativeElement.scrollLeft;
      console.log(element);
      if (element > 0) {
        return this.scrolled = 'initial';
      } else {
        return this.scrolled = 'none';
      }
    }, 500);
  }

  scrollTo(direction) {
    // console.log(this.horizontal);
    // console.log(element.scrollLeft);
    const element = this.horizontal.nativeElement;
    let distance = element.clientWidth; // 450 to make last photo first
    if (direction == 'left') {
      distance = -element.clientWidth; // negative => left
    }
    this.horizontal.nativeElement.scroll(
      {
        top: 0,
        left: element.scrollLeft + distance,
        behavior: 'smooth'
      }
    );
    this.userScrolled();
  }

  removePlaceholder(event) {
    const imageElement = event.target;
    imageElement.classList.remove('animated-placeholder');
  }

}

<header class="center-title">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <!-- Back -->
      <button mat-button mat-icon-button (click)="back()">
        <mat-icon id="back">arrow_back_ios</mat-icon>
      </button>
      <!-- <button class="search" mat-icon-button [disableRipple]="true" routerLink="/search/results">
          <mat-icon>search</mat-icon>
          <span>Αναζήτηση</span>
      </button> -->
      <!-- input -->
      <mat-form-field id="search-bar" [floatLabel]="'never'">
        <mat-icon matPrefix class="prefix">search</mat-icon>
        <input matInput #search type="search" placeholder="Αναζήτηση" [(ngModel)]="searchQuery" 
        (ngModelChange)="liveSearch(searchQuery.trim())" autocomplete="off" (keydown.enter)="fullSearch()">
        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!-- search button -->
      <button mat-button color="accent" class="searchBtn" [disableRipple]="true" (click)="fullSearch()">Αναζήτηση</button>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<section>
  <mat-list *ngIf="searchQuery || loading; else info">
    <mat-list-item class="pointer" (click)="fullSearch()">
      <mat-icon mat-list-icon id="search-icon">search</mat-icon>
      <div mat-line id="title">"{{searchQuery}}"</div>
      <div mat-line id="subtitle">
      </div>
    </mat-list-item>
    <!-- Users -->
    <mat-list-item *ngFor="let res of results.users.data.slice(0, 4)" class="pointer" [routerLink]="['/@' + res.username]">
        <div mat-list-icon [ngClass]="['avatar', 'avatar-54', 'sex-' + res.profile.sex]">
          <img src={{apiClient.userAvatar(res.username,res.profile.avatar,90)}}>
        </div>
        <div mat-line id="title">@{{res.username}}</div>
        <div mat-line id="subtitle">
          {{res.profile.birthdate | age}} 
          <span *ngIf="res.profile.birthdate2">| {{res.profile?.birthdate2 | age}}</span> 
          {{apiClient.locations[res.profile.location]}}
        </div>
    </mat-list-item>
    <!-- Hashtags -->
    <mat-list-item *ngFor="let res of results.hashtags.data.slice(0, 3)" class="pointer" [routerLink]="['/tag/' + res.text]">
      <div mat-list-icon class="hashtag-avatar avatar avatar-54">
        <img class="avatar-svg" src="./assets/hashtag.svg">
        
        <!-- <img class="avatar-svg" [src]="res.avatar" onError="this.onerror=null;this.src='./assets/hashtag.svg'"> -->
        
      </div>
      <div mat-line id="title">#{{res.text}}</div>
      <div mat-line id="subtitle">
        {{ res.count | number: '1.' }} αναρτήσεις
      </div>
    </mat-list-item>
    <!-- Locations -->
    <mat-list-item *ngFor="let res of results.locations.data.slice(0, 3)" class="pointer" [routerLink]="['/l/' + res.name]">
      <div mat-list-icon class="location-avatar avatar avatar-54">
        <img class="avatar-svg" src="./assets/location.svg">
      </div>
      <div mat-line id="title">{{apiClient.locations[res.name]}}</div>
      <div mat-line id="subtitle">
      </div>
    </mat-list-item>
    <button class="all-results" mat-button color="accent" [disableRipple]="true" (click)="fullSearch()">
      <!-- <mat-icon matPrefix class="prefix">search</mat-icon> -->
      Εμφάνιση όλων των αποτελεσμάτων
    </button>
  </mat-list>
  <ng-template #info>
    <p class="info">Αναζήτηση μέλους, ανάρτησης, τοποθεσίας ή #hashtag.</p>
  </ng-template>
  <mat-spinner *ngIf="loading" color="accent" [diameter]="30"></mat-spinner>
</section>
<!-- <app-search-bar></app-search-bar> -->

<!-- Profile Page - 3 cols -->
<div class="container">
  <mat-grid-list infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    [infiniteScrollDisabled]="scrollDisabled" (scrolled)="onScroll()" cols="3" rowHeight="3:4" gutterSize="3px">
    <mat-grid-tile class="pointer img-placeholder" *ngFor="let post of posts; index as idx" [routerLink]='["/p/", post.ref ]' [state]='{
      data: posts,
      index: idx,
      postsSource: postsSource,
      postType: postType,
      sorting: sorting,
      query: query,
      endReached: endReached
      }'>
      <img [src]="post.image[320]" class="animated-placeholder" (load)="removePlaceholder($event)" srcset="{{post.image[320]}} 320w, {{post.image[640]}} 640w," 
      sizes="33vw" onError="this.style.visibility='hidden'">
    </mat-grid-tile>
  </mat-grid-list>
</div>

<section *ngIf="loading" class="container">
  <mat-grid-list cols="3" rowHeight="3:4" gutterSize="3px">
      <mat-grid-tile *ngFor="let h of [1,2,3,4,5,6,7,8,9]" class="animated-placeholder"></mat-grid-tile>
  </mat-grid-list>
  <mat-spinner color="accent" [diameter]="30"></mat-spinner>
</section>

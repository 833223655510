import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AuthenticationService} from '../../../services/authentication.service';
import firebase from 'firebase/compat/app';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {CoreService} from 'src/app/core/core.service';
import {environment} from '../../../../environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;
  demoUsers = [
    'hotbabe@hotbabe.ho',
    'fire@fire.fi',
    'flame@flame.fl',
    'water@water.wa',
    "satan@satan.sa",
    'non.accepted@non.accepted.no'
  ];
  type: 'login' | 'reset' | 'pendingVerification' = 'login';
  loading = false;
  serverMessage: string;
  user$: Subscription;
  showPass: boolean = false;

  public production = false;

  constructor(
    public auth: AuthenticationService,
    private location: Location,
    private fb: UntypedFormBuilder,
    private core: CoreService
  ) {
    this.production = environment.production;
  }

  get isLogin() {
    return this.type === 'login';
  }

  get pendingVerification() {
    return this.type === 'pendingVerification';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  selectedUser(user) {
    this.form.setValue({email: user, password: user[0] + 1234567});
  }

  ngOnInit() {
    this.user$ = this.auth.authState
      .pipe(first())
      .subscribe(res => {
        this.type = res && res.status == 'pending' ? 'pendingVerification' : 'login';
      });

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  changeType(val) {
    if (this.isLogin && val === 'login') {
      this.location.back()
    }
    this.type = val;
    this.serverMessage = null;
  }

  onSubmit() {
    this.loading = true;

    const email = this.email.value;
    const password = this.password.value;

    if (this.isLogin) {
      this.auth.login(email, password).subscribe(
        res => {
          if (!res.success) this.serverMessage = res.errorMessage;
          this.loading = false;
        });
    }
    if (this.isPasswordReset) {
      this.auth.forgotPassword(email)
        .then(res => this.core.snackBarNotification(
          '✉️ Το email επαναφοράς έχει αποσταλεί. Θυμηθείτε να ελέγξετε και τον φάκελο ανεπιθύμητης αλληλογραφίας.',
          undefined,
          10000
        ))
        .catch(err => {
          this.serverMessage = 'Βεβαιωθείτε οτι η διεύθυνση email είναι σωστή';
          this.loading = false;
        });
    }

  }

  sendMailVerification(user: firebase.User) {
    this.loading = true;
    user.sendEmailVerification()
      .then(() => {
        this.core.snackBarNotification('Το email επαναφοράς έχει αποσταλεί. Θυμηθείτε να ελέγξατε και τον φάκελο ανεπιθύμητης αλληλογραφίας.', undefined, 10000);
        // @ts-ignore
        umami.track(`Re-send Mail Verification - ${user.displayName}`, {user: user.displayName});
      })
      .catch(err => {
        this.serverMessage = 'Βεβαιωθείτε οτι η διεύθυνση email είναι σωστή';
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 120000);
      });
  }

  ngOnDestroy(): void {
    this.user$.unsubscribe();
  }
}

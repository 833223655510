import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, mergeMap, retry, tap } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import { AuthenticationService } from '../services/authentication.service';
import { JwtHelperService } from './jwthelper.service';
import { JWT_OPTIONS } from './jwtoptions.token';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  tokenGetter: () => string | Promise<string>;
  headerName: string;
  authScheme: string;
  whitelistedDomains: Array<string | RegExp>;
  throwNoTokenError: boolean;
  skipWhenExpired: boolean;

  constructor(@Inject(JWT_OPTIONS) config: any,
    public jwtHelper: JwtHelperService, private authenticationService: AuthenticationService, private core: CoreService) {
    this.tokenGetter = config.tokenGetter;
    this.headerName = config.headerName || 'Authorization';
    this.authScheme =
      config.authScheme || config.authScheme === ''
        ? config.authScheme
        : 'Bearer ';
    this.whitelistedDomains = config.whitelistedDomains || [];
    this.throwNoTokenError = config.throwNoTokenError || false;
    this.skipWhenExpired = this.skipWhenExpired || true ;    
  }

  isWhitelistedDomain(request: HttpRequest<any>): boolean {
    let requestUrl: URL;
    try {
      requestUrl = new URL(request.url);
      return (
        this.whitelistedDomains.findIndex(
          domain =>
            typeof domain === 'string'
              ? domain === requestUrl.host
              : domain instanceof RegExp ? domain.test(requestUrl.host) : false
        ) > -1
      );
    } catch (err) {
      // if we're here, the request is made
      // to the same domain as the Angular app
      // so it's safe to proceed
      return true;
    }
  }

  handleInterception(token: string,
    request: HttpRequest<any>,
    next: HttpHandler) {
    let tokenIsExpired: boolean = false;

    if (!token && this.throwNoTokenError) {
      throw new Error('Could not get token from tokenGetter function.');
    }

    if (this.skipWhenExpired) {
      tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;
    }

    if (token && tokenIsExpired && this.skipWhenExpired) {
      request = request.clone();
    } else if (token && this.isWhitelistedDomain(request)) {
      request = request.clone({
        setHeaders: {
          [this.headerName]: `${this.authScheme}${token}`
        }
      });
    }
    return next.handle(request)
      .pipe(
        retry(1),
        catchError(error => {
          // Connection/network error handling (checked every 5" by apiClient.activityLiveCount())
          this.core.networkErrorShow()
          return EMPTY
        })
      );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: any = this.tokenGetter();

    console.warn(`Request: ${request.body.query.split("{")[1].trim()}, Token: ${token?token.substr(0,10):null}...`);

    // Update token if expired or deleted
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      if (token) {
        console.log(`Token expired at ${this.jwtHelper.getTokenExpirationDate(token).toLocaleString()}. Refreshing.`);
      } else {
        console.log("Token not found locally.");
      }
      token = this.authenticationService.refreshToken(request.body.query.split("{")[1])
    }

    if (token instanceof Promise) {
      return from(token).pipe(
        mergeMap((asyncToken: string) => {
          return this.handleInterception(asyncToken, request, next);
        })
      );
    } else {
      return this.handleInterception(token, request, next);
    }
  }
}

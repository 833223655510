import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {CoreService} from '../../core/core.service';
import {ApiClient} from '../../services/api-client.service';
import {UploadCanvasComponent} from 'src/app/components/upload-canvas/upload-canvas.component';
import {AuthenticationService} from '../../services/authentication.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

// // Error when the parent is invalid
// class CrossFieldErrorMatcher implements ErrorStateMatcher {
//   isErrorState(control, form): boolean {
//     // console.log(form.form.controls['passwordGroup']);
//     return control.touched && form.form.controls['passwordGroup'].invalid;
//   }
// }

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})
export class NewPostComponent implements OnInit {

  @ViewChild('textarea') textarea: ElementRef;
  charCount: number = 0;
  maxChars: number = 200;
  maxLines: number = 3;
  maxFileSizeMB = 25; // MB
  maxFileSize = this.maxFileSizeMB * 1024 * 1024; // bytes

  hashtagMentionConfig = {
    items: [],
    triggerChar: '#',
    returnTrigger: true,
    disableSearch: true,
    disableSort: true,
    labelKey: 'text',
  };

  userMentionConfig = {
    items: [],
    triggerChar: '@',
    returnTrigger: true,
    disableSearch: true,
    labelKey: 'username',
    disableSort: true,
  };

  mentionConfig = {
    mentions: [this.hashtagMentionConfig, this.userMentionConfig]
  };

  imageLarge = false;
  imageWrongExt = false;
  imgURI: string = '';

  form: UntypedFormGroup;
  // errorMatcher = new CrossFieldErrorMatcher();
  fc: any;

  loading = false;

  serverMessage: string;

  visibility = [
    {full: 'Ορατή σε όλους', id: 'public'},
    {full: 'Μόνο μέλη του Naked Greece', id: 'members'},
    {full: 'Σε μέλη που ακολουθώ και με ακολουθούν', id: 'friends'}
  ];
  @ViewChild(UploadCanvasComponent) uploadCanvas: UploadCanvasComponent;

  constructor(public auth: AuthenticationService, private fb: UntypedFormBuilder,
              public afs: AngularFirestore, public dialog: MatDialog, private core: CoreService,
              private apiClient: ApiClient) {
  }

  get title() {
    return this.form.get('title').value;
  }

  get avatar() {
    return this.form.get('avatar').value;
  }

  ngOnInit() {
    this.form = this.fb.group({
      photoInput: ['', [Validators.required]],
      title: ['', [Validators.maxLength(this.maxChars)]],
      tos: [false, [Validators.requiredTrue]],
      avatar: [false, [this.avatarVisibility.bind(this)]],
      visibility: ['public']
    });

    this.fc = this.form.controls;
  }

  avatarVisibility(isAvatar: AbstractControl) {
    if (isAvatar.value) {
      this.form.get('visibility').setValue('public');
      this.form.controls['visibility'].disable();
    } else if (isAvatar.touched) {
      this.form.controls['visibility'].enable();
    }
  }

  // When new image is selected from user this runs
  async onFileSelected(event) {
    // console.log(event);
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const ext = file.name.split('.').pop().toLowerCase();
      const validExt = ['jpeg', 'jpg', 'png'];
      console.log(file.name);
      console.log(`File size: ${file.size / 1000 + ' kB'}`);

      this.imageLarge = false;
      this.imageWrongExt = false;

      if (!validExt.includes(ext)) {
        this.imageWrongExt = true;
        this.form.controls['photoInput'].setErrors({'incorrect': true});
      } else if (file.size > this.maxFileSize) {
        this.imageLarge = true;
        this.form.controls['photoInput'].setErrors({'incorrect': true});
      }

      // Preview image
      if (file && validExt.includes(ext)) {
        this.imgURI = await this.uploadCanvas.setImage(event);
      } else {
        this.imgURI = '';
      }
    }
  }

  // Submit form
  async onSubmit() {
    // Invalid form not submitted
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    // Restrict number of # and @ to 5 each
    const tags = this.title.split('#').length - 1;
    const mentions = this.title.split('@').length - 1;
    if (tags > 5 || mentions > 5) {
      return this.core.snackBarNotification(
        'Μπορείτε να χρησιμοποιήσετε έως 5 hashtags και να κάνετε tag εως 5 μέλη.',
        null,
        7000
      ), this.loading = false;
    }

    this.loading = true;

    // Upload photo => then createPost details on server
    this.uploadCanvas.uploadImage()
      .then(attachment => {
        // Create post for the attachment (trim start/end whitespaces and \n)
        this.apiClient.createPost(this.title.trim(), attachment, this.form.get('visibility').value)
          .subscribe((post) => {
            console.log(post);
            const postRef = post.data.createPost.ref;
            if (this.avatar) {
              this.core.updateAvatar(postRef, true);
            } else {
              this.core.snackBarNotification('Η φωτογραφία σας ανέβηκε!', ['/p/', postRef]);
            }
            // this.loading = false
          });
      });
  }

  oldTitle = ''
  // Limit title max lines and consecutive newlines
  lineBreakLimits(event) {
    let title: string = this.title;
    // Escape error for accents on Mac
    if (title == this.oldTitle) {
      return
    }
    // Allow only one consecutive newline
    title = title.replace(/(\r\n|\r|\n)\1+/gm, '\n');
    // Limit text lines to maxLines (25)
    const lines: string[] = title.split(/\r\n|\r|\n/);

    // Update values
    const newTitle = lines.slice(0, this.maxLines).join("\n")
    this.form.get('title').setValue(newTitle);
    this.oldTitle = newTitle
    // Count characters
    this.charCount = title.length;
    // --- Adjust textarea height ---
    let element = this.textarea.nativeElement;
    element.style.height = 0;
    element.style.height = element.scrollHeight - 4 + 'px';
  }

  visibilityNotification() {
    if (this.avatar) {
      this.core.snackBarNotification(
        'Η φωτογραφια προφίλ σας δεν μπορεί να έχει περιορισμένη ορατότητα.',
        null,
        5000
      );
    }
  }

  search(term: string) {
    const trigger = term[0]
    this.getMentions(term).subscribe(terms=>{
      if (trigger === '@') {
        this.userMentionConfig.items = terms
      } else {
        this.hashtagMentionConfig.items = terms
      }      
      this.mentionConfig = {
        mentions: [this.hashtagMentionConfig, this.userMentionConfig]
      };
    })
  }

  getMentions(term:string) : Observable<any> {
    const trigger = term[0]
    const query = term.slice(1).toLowerCase()

    if (trigger == "@") {
      return this.apiClient.search(query, "users", undefined, 5, 0)
        .pipe(map(res => res.users.data))
    } else {
      return this.apiClient.search(query, "hashtags", undefined, 5, 0)
        .pipe(map(res => res.hashtags.data))
    }
  }
}

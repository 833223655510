<header class="left-title" [style.display]="page.topBarDisplay" [style.visibility]="page.topBarHidden">

  <mat-toolbar class="toolbar">
    <mat-toolbar-row>
      <!-- Start -->
      <!-- Back Button -->
      <div class="start">
        <button *ngIf="page.backBtn" mat-button mat-icon-button (click)="location.back()">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <button class="admin-button" *ngIf="page['adminTools'] && auth.userObject && auth.userObject.role == 'admin'" 
          mat-button mat-icon-button [matMenuTriggerFor]="adminMenu">
          <mat-icon class="material-icons-outlined">admin_panel_settings_outlined</mat-icon>
        </button>
        <mat-menu #adminMenu="matMenu" class="admin-menu">
          <button mat-menu-item routerLink="/umami">
            <mat-icon>insights</mat-icon>
            <span>Analytics</span>
          </button>
          <button mat-menu-item routerLink="/user-requests">
            <mat-icon class="material-icons-outlined">group_add</mat-icon>
            <span>User Requests</span>
          </button>
          <button mat-menu-item routerLink="/reports">
            <mat-icon class="material-icons-outlined">inbox</mat-icon>
            <span>Reports</span>
          </button>
        </mat-menu>
        <!-- Menu Button -->
        <!-- <button [ngStyle]="{'display': url=='/' ? 'initial' : 'none'}" mat-button mat-icon-button
          (click)="sidenav.toggle()">
          <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
          <mat-icon *ngIf="sidenav.opened">close</mat-icon>
        </button> -->
      </div>
      <!-- Middle -->
      <div class="mid">
      <!-- <div class="mid" (click)="test()"> -->
        <ng-container *ngIf="url == '/' || ['/#top', '/#feed', '/#fresh', '/#private'].includes(url); then logo; else title"></ng-container>
      </div>
      <!-- End -->
      <!-- Login OR ...More -->
      <div class="end">
        <ng-container *ngIf="user && user.status != 'guest'; then authenticated else guest"></ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-toolbar class="spacer-toolbar">
    <mat-toolbar-row>
    </mat-toolbar-row>
  </mat-toolbar>

</header>

<!-- TEMPLATES -->
<!-- Middle -->
<ng-template #logo>
  <img id="logo" src="assets/naked-greece-h.png">
</ng-template>

<ng-template #title>
  <h3 id="title">{{ page.title }}</h3>
</ng-template>

<!-- End -->
<ng-template #authenticated>
  <!-- add anything -->
</ng-template>

<ng-template #guest>
  <button *ngIf="!['/login', '/register'].includes(url)" mat-button [disableRipple]="true" routerLink='/login'>
    <!-- <mat-icon>key</mat-icon> -->
    <span class="gradient-text">Είσοδος</span>
  </button>
</ng-template>
<!-- /TEMPLATES -->

<!-- <mat-sidenav-container (window:resize)="resize($event)"> -->
<mat-sidenav-container>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>

<nav *ngIf="!keyboardOpen && page.navDisplay" mat-tab-nav-bar mat-align-tabs="center" [disablePagination]="true">
  <!-- <div class="tabs toolbar" [ngClass]="url.contains('/p/') ? 'transp-nav' : null"> -->
  <div class="tabs toolbar" [class.transp-nav]="url.includes('/p/')">
    <!-- Home -->
    <a mat-tab-link routerLink="/" [disableRipple]="true" (click)="scrollToTop('/')">
      <mat-icon routerLinkActive #home="routerLinkActive" [class.material-icons-outlined]="!home.isActive" 
        [routerLinkActiveOptions]="{exact: true}">
        {{home.isActive ? "home" : "home_outlined"}}
      </mat-icon>
    </a>
    <!-- Search -->
    <a mat-tab-link routerLink="/search" [disableRipple]="true">
      <mat-icon routerLinkActive #search="routerLinkActive" [class.stroke-1px]="search.isActive">
        search
      </mat-icon>
    </a>
    <!-- New Post -->
    <a mat-tab-link routerLink="/new-post" [disableRipple]="true">
      <mat-icon class="gradient-text">add_circle_outline</mat-icon>
    </a>
    <!-- Activity -->
    <a mat-tab-link (click)="activityVisit()" [disableRipple]="true">
      <mat-icon routerLinkActive #activity="routerLinkActive" class="material-icons-outlined" 
        [matBadge]="user ? user.activitySubjectCountSince : null" 
        [matBadgeHidden]="!user || !user.activitySubjectCountSince" matBadgeColor="warn">
        {{activity.isActive ? "favorite" : "favorite_outlined"}}
      </mat-icon>
    </a>
    <!-- Profile / Anon -->
    <a *ngIf="user && user.username" class="user" mat-tab-link [routerLink]="'/@' + user.username" [disableRipple]="true">
      <div [ngClass]="['avatar', 'avatar-26', 'sex-' + user.sex]">
        <img [src]="apiClient.userAvatar(user.username, user.avatar, 90)">
      </div>
    </a>
    <a *ngIf="!user || !user.username" mat-tab-link [routerLink]="'/login'" [disableRipple]="true">
      <mat-icon routerLinkActive #profile="routerLinkActive">{{profile.isActive ? "person" : "person_outlined"}}</mat-icon>
    </a>
  </div>
</nav>
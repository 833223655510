import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ANGULAR MATERIAL
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

// Infinite Scroll
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling'; // virtual scroll autosize
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Masonry
import { NgxMasonryModule } from 'ngx-masonry';

// Forms
import { ReactiveFormsModule } from '@angular/forms';

// Pages
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { UploadComponent } from '../components/upload/upload.component';

// Move to lazy module
// import { AngularCropperjsModule } from 'angular-cropperjs';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { MentionModule } from 'angular-mentions';

// Datepicker dd/mm/yyyy instead of mm/dd/yyyy
import {NativeDateAdapter,DateAdapter} from '@angular/material/core';
@Injectable()
export class MyDateAdapter extends NativeDateAdapter{
  parse(value: string) {
    let it=value.split('/');
    if (it.length==3)
    return new Date(+it[2],+it[1]-1,+it[0],12)
  }
  format(date: Date, displayFormat: Object) {
    return ('0'+date.getDate()).slice(-2)+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear()
  }
}

// QR Code Generator
import { NgxAngularQrcodeModule } from 'ngx-angular-qrcode';

const components = [
  NavBarComponent,
  // UploadComponent
];

const modules = [
  CommonModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatIconModule,
  MatTabsModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatSidenavModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatStepperModule,
  ClipboardModule,
  ReactiveFormsModule,
  ScrollingModule,
  // ExperimentalScrollingModule, // virtual scroll autosize
  InfiniteScrollModule,
  NgxMasonryModule,
  MatDialogModule,
  MatMenuModule,
  MatGridListModule,
  MatListModule,
  MatBadgeModule,
  MatSnackBarModule,
  MatProgressBarModule,
  // AngularCropperjsModule,
  NgxUsefulSwiperModule,
  // SwiperModule,
  MatButtonToggleModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MentionModule,
  NgxAngularQrcodeModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...components,
    ...modules
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'el-GR'
    },
    {provide: DateAdapter, useClass: MyDateAdapter}
  ]
})
export class CoreModule { }

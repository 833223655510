<header class="left-title">
  <mat-toolbar class="toolbar">
    <mat-toolbar-row *ngIf="user">
      <!-- Start -->
      <!-- Back Button -->
      <button mat-button mat-icon-button (click)="location.back()">
        <mat-icon id="back">arrow_back_ios</mat-icon>
      </button>
      <!-- Profile Avatar + Name -->
      <div class="nav">
        <div [ngClass]="['avatar', 'avatar-32', 'sex-' + user.profile.sex]">
          <img [src]="apiClient.userAvatar(user.username, user.profile.avatar, 90)"
            (error)="core.onImgError($event)">
          </div>
          <h3 class="user">@{{user?.username}}</h3>
      </div>
      <span class="spacer"></span>
      <!-- End -->
      <div>
        <ng-container *ngIf="user.self; then settingsMenu else profileMenu"></ng-container>
      </div>
      <ng-template #settingsMenu>
        <button mat-button mat-icon-button
        (click)="toggleDrawer()">
          <mat-icon *ngIf="!drawerOpen">menu</mat-icon>
          <mat-icon *ngIf="drawerOpen">close</mat-icon> 
        </button>
      </ng-template>

      <ng-template #profileMenu>
        <button mat-icon-button #menuTrigger="matMenuTrigger" class="pointer" [matMenuTriggerFor]="menu.menu" >
          <mat-icon>more_vert</mat-icon>
        </button>
        <app-more-menu #menu='moreMenu' targetType="user" [targetRef]="user.username" [blocked]="blocked" [self]="user.self">
        </app-more-menu>
      </ng-template>
    </mat-toolbar-row>
  </mat-toolbar>
</header>


<mat-drawer-container *ngIf="user">
  <mat-drawer #drawer mode="push" position="end">
    <!-- <h3 class="user">@{{user.username}}</h3>
    <hr> -->
    <ng-container *ngIf="navMenu == 'options';else moreOptions">
      <mat-nav-list>
        <a mat-list-item [routerLink]="'/edit'"> Επεξεργασία Προφίλ </a>
        <!-- <a mat-list-item (click)="createInvitation()">Προσκλήσεις μελών</a> -->
        <a mat-list-item [routerLink]="'/invitations'"> Προσκαλέστε φίλους ({{auth.userObject.availableInvitations | number: '1.'}}) </a>
        <a mat-list-item (click)="core.showTos()"> Όροι χρήσης </a>
        <a mat-list-item class="space-between" (click)="navMenu='moreOptions'"> 
          <span>Περισσότερα</span>
          <mat-icon>arrow_forward_ios</mat-icon>
        </a>
        <a mat-list-item (click)="core.report('bug/idea','bug/idea')">
          <div class="gradient-text" style="display: flex; align-items: center;">
            <mat-icon mat-list-icon class="material-icons-outlined">tips_and_updates_outlined</mat-icon>
            <span class="gradient-text" style="padding-left: 5px;">Πρόταση / Πρόβλημα</span> 
          </div>
        </a>
        <button *ngIf="a2hs.browserPromptBtn()" class="download-app" mat-flat-button color="accent"  (click)="a2hs.addToHomeScreen()">
          <mat-icon>get_app</mat-icon> Εγκατάσταση App 
        </button>
        <a mat-list-item class="logout-button" (click)="auth.logout()">
          <mat-icon mat-list-icon>exit_to_app</mat-icon>
          <span>Έξοδος</span>
        </a>
      </mat-nav-list>
    </ng-container>

    <ng-template #moreOptions>    
      <mat-nav-list>
        <a mat-list-item (click)="navMenu = 'options'">
          <mat-icon id="back">arrow_back_ios</mat-icon>
          <span>Πίσω</span>
        </a>
        <!-- <a mat-list-item class="disabled"> Σας έχουν αρέσει (0) </a> -->
        <a mat-list-item [routerLink]="'/blocked'"> Έχετε μπλοκάρει </a>
        <a mat-list-item [routerLink]="'/change-pass'"> Αλλαγή κωδικού πρόσβασης </a>
        <!-- <a mat-list-item class="logout-button" [routerLink]="'/pwa'"> -->
          <!-- <mat-icon mat-list-icon class="material-icons-outlined">app_shortcut</mat-icon>
          <span>PWA</span> -->
        <!-- </a> -->
        <p class="version" [routerLink]="'/pwa'">version {{currentApplicationVersion}}</p>
      </mat-nav-list>
    </ng-template>

  </mat-drawer>

  <!-- <div class="background-blur" [style.background-image]="'url(' + apiClient.userAvatar(user.username, user.profile.avatar, 150) + ')'"></div> -->

  <main>
    <div class="top">
      <div [ngClass]="['avatar', 'avatar-100', 'sex-' + user.profile.sex]" (click)="user.self ? core.changeAvatar() : null">
        <img [src]="apiClient.userAvatar(user.username, user.profile.avatar, 150)" (error)="core.onImgError($event)">
      </div>
      <section class="details">
        <mat-list role="list">
          <mat-list-item *ngIf="user.profile.sex == 'c'" role="listitem">
            <div class="couple-icon">
              <mat-icon>radio_button_unchecked</mat-icon>
              <mat-icon>radio_button_unchecked</mat-icon>
            </div>
            <div class="gradient-text">
              <span>Ζευγάρι</span>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <mat-icon fontSet="fa" fontIcon="fa-venus-mars"></mat-icon>
            <span>
              <span>{{ apiClient.genders[sex(1)] }} {{ age(1) | age }}</span>
              <span *ngIf="user.profile.sex == 'c'">
                <span class="gradient-text spaces-around">&</span>
                <span class="inline-block">{{ apiClient.genders[sex(2)] }} {{age(2) | age}}</span>
              </span>
            </span>
          </mat-list-item>
          <mat-list-item role="listitem">
            <mat-icon class="orientation-icon">fork_right</mat-icon>
            <span>
              <span>{{ apiClient.orientations[orientation(1)] }}</span>
              <span *ngIf="user.profile.sex == 'c'">
                <span class="gradient-text spaces-around">&</span>
                <span class="inline-block">{{ apiClient.orientations[orientation(2)] }}</span>
              </span>
            </span>
          </mat-list-item>
          <mat-list-item role="listitem" [routerLink]="[ '/l', user.profile.location ]">
            <mat-icon fontSet="fa" fontIcon="fa-map-marker-alt"></mat-icon>
            {{ apiClient.locations[user.profile.location] }}
          </mat-list-item>
        </mat-list>
      </section>
    </div>

    <section class="stats">
      <div class="stat" (click)='pushState(user.username, "followers")'>
        <div class="num">{{user?.followers.count | number: '1.'}}</div>
        <div class="type">Ακόλουθοι</div>
      </div>
      <div class="stat" (click)='pushState(user.username, "following")'>
        <div class="num">{{user?.following.count | number: '1.'}}</div>
        <div class="type">Ακολουθεί</div>
      </div>
      <!-- <div class="stat">
        <div class="num">{{user?.posts.count | number: '1.'}}</div>
        <div class="type">Αναρτήσεις</div>
      </div> -->
      <button *ngIf="!(user.followingThem) && !user.self" [disabled]="!user.posts.data" mat-flat-button color="accent"
        (click)="followUnfollow(user.username)">{{user.followingMe ? "Ακολ/\στε Πίσω" : "Ακολουθήστε"}}</button>
      <button *ngIf="user.followingThem && !user.self" [disabled]="!user.posts.data" mat-stroked-button color="accent"
        (click)="followUnfollow(user.username)">{{user.followingMe ? "Φίλοι!" : "Ακολουθείτε"}}</button>
      <button *ngIf="user.self" mat-stroked-button color="basic" [routerLink]="'/edit'">Επεξεργασία</button>
    </section>

    <h3 class="user">@{{user?.username}}</h3>

    <section #info class="info" (click)="info.style.maxHeight = 'unset'">
      <div #bio class="bio" [ngClass]="{'info-fade': bioLong && (info.style.maxHeight != 'unset')}">
        <ng-container *ngIf="auth.userObject.username; else hiddenBio">
          {{ user.profile.info }}
        </ng-container>
        <ng-template #hiddenBio>
          <p style="font-style: italic;">Οι περιγραφές των μελών είναι εμφανείς μόνο σε μέλη του Naked Greece.</p>
        </ng-template>
      </div>
      <button mat-flat-button color="accent" *ngIf="user.self && !user.profile.info" [routerLink]="'/edit'">Προσθέστε μια περιγραφή</button>
    </section>
    
    <div id="more" class="pointer" *ngIf="bioLong && (info.style.maxHeight != 'unset')"
      (click)="info.style.maxHeight = 'unset'">
      Περισσότερα
      <mat-icon>expand_more</mat-icon>
    </div>
  </main>
  <section #tabSection class="posts">
    <mat-tab-group color="accent" *ngIf="user && user.posts.data" [style.min-height.px]="core.windowHeight - 110" [disablePagination]="true" [disableRipple]="true" (selectedIndexChange)="tabChange($event)">
      <mat-tab >
        <ng-template mat-tab-label>
          <!-- <mat-icon [matBadge]="user.posts.count" matBadgeColor="primary">grid_on_outlined</mat-icon> -->
          <mat-icon>grid_on_outlined</mat-icon>
          <span class="icon-text-space">{{user.posts.count | number: '1.'}}</span>
        </ng-template>
        <div class="grid-spacer" [ngClass]="{'grid-spacer-sticky': sticky}"></div>
        <app-grid postsSource="user" [query]="user.username" [firstPosts]="user.posts.data" postType="posts"
        [pageSize]="pageSize" [scrollDisabled]="scrollDisabled || mainScrollDisabled">
        </app-grid>
      </mat-tab>
      <mat-tab *ngIf="user.taggedPosts.count > 0">
        <ng-template mat-tab-label>
          <mat-icon>portrait_outlined</mat-icon>
          <span class="icon-text-space">{{user.taggedPosts.count | number: '1.'}}</span>
        </ng-template>
        <div class="grid-spacer" [ngClass]="{'grid-spacer-sticky': sticky}"></div>
        <app-grid *ngIf="taggedVisited" postsSource="user" [query]="user.username" [firstPosts]="user.taggedPosts.data" postType="taggedPosts"
          [pageSize]="pageSize" [scrollDisabled]="scrollDisabled || taggedScrollDisabled">
        </app-grid>
      </mat-tab>
    </mat-tab-group>
  </section>

  <div *ngIf="user && !user.posts.data" class="deactivated">
    <div>
      <mat-icon>bedtime_outlined</mat-icon>
      Το προφίλ είναι απενεργοποιημένο.
    </div>
  </div>

</mat-drawer-container>

<app-page404 *ngIf="is404"></app-page404>

<mat-spinner *ngIf="loading" color="accent" [diameter]="30"></mat-spinner>
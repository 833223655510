import { Component } from '@angular/core';
import { A2hsService } from '../../services/pwa-install.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss']
})
export class PwaComponent {

  constructor(public a2hs: A2hsService) { }

  currentApplicationVersion = environment.appVersion;

}
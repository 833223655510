import { Post, postType, sortBy, User } from '../../interfaces/interfaces';
import { ApiClient } from '../../services/api-client.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

export class GridComponent implements OnInit{

  // @Input() username: string
  @Input() postType: postType = "posts"
  @Input() pageSize: number
  @Input() firstPosts: Post[]
  @Input() scrollDisabled: boolean
  @Input() sorting: sortBy
  @Input() postsSource: "user" | "search" | "hashtag" | "location" | "fresh"
  @Input() query: string

  loading: boolean
  posts: Post[] = []
  innerHeight: any;
  endReached: boolean = false
  pageIndex = 0

  constructor(private apiClient: ApiClient) {
    this.innerHeight = (window.screen.height) + "px";
    console.log(this.innerHeight);
  }

  ngOnInit(): void {
    console.log(this.postType, "grid loading for --", this.postsSource, this.query, "--")
  
    if (this.firstPosts) {
      this.posts = this.firstPosts
      this.pageIndex = 1
      if (this.firstPosts.length < this.pageSize) {
        this.endReached = true
      }
    } else {
      // Get page 0 if there are no incoming fistPosts
      this.onScroll()
    }
  }

  onScroll() {
    console.log('scrolled!!', "loaded", this.posts.length, "| endReached", this.endReached);
    if (!this.endReached) {
      this.loading = true
      if (this.postsSource == "user") {
        // user posts
        this.apiClient.getUserPosts(this.query ,this.pageSize, this.pageIndex, this.postType)
        .subscribe((user: User) => {
          const posts = user[this.postType]
          this.endReached = posts.data.length < this.pageSize
          this.posts = this.apiClient.mergePosts(this.posts, posts)
          // this.pageIndex += 1
          console.log(posts);
          console.log(this.posts);
          console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
  
          this.loading = false
        })
      } else if (this.postsSource == "fresh") {
        // hashtag posts
        this.apiClient.getPosts(this.pageSize, this.pageIndex, this.sorting)
        .subscribe(
          (posts) => {
            this.endReached = posts.data.length < this.pageSize
            this.posts = this.apiClient.mergePosts(this.posts, posts)
            // this.pageIndex += 1
            console.log(posts);
            console.log(this.posts);
            console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
    
            this.loading = false
          }
        )
      } else if (this.postsSource == "hashtag") {
        // hashtag posts
        this.apiClient.getHashtag(this.query, this.pageSize, this.pageIndex, this.sorting)
        .subscribe(
          (tag) => {
            const posts = tag.posts
            this.endReached = posts.data.length < this.pageSize
            this.posts = this.apiClient.mergePosts(this.posts, posts)
            // this.pageIndex += 1
            console.log(posts);
            console.log(this.posts);
            console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
    
            this.loading = false
          }
        )
      } else if (this.postsSource == "location") {
        // only location.posts
        this.apiClient.getLocation(this.query, this.pageSize, "posts", this.pageIndex, this.sorting)
        .subscribe(
          (location) => {
            const posts = location.posts
            this.endReached = posts.data.length < this.pageSize
            this.posts = this.apiClient.mergePosts(this.posts, posts)
            // this.pageIndex += 1
            console.log(posts);
            console.log(this.posts);
            console.log(`Page index ${this.pageIndex}`, "end: "+this.endReached)
    
            this.loading = false
          }
        )
      }
      this.pageIndex += 1
    }
  }

  removePlaceholder(event) {
    const imageElement = event.target;
    imageElement.classList.remove('animated-placeholder');
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { CoreService } from 'src/app/core/core.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-mail-action-handler',
  templateUrl: './mail-action-handler.component.html',
  styleUrls: ['./mail-action-handler.component.scss']
})
export class MailActionHandlerComponent implements OnInit {

  constructor(public activatedRoute: ActivatedRoute, private afAuth: AngularFireAuth, 
    private fb:UntypedFormBuilder, private core: CoreService, private auth: AuthenticationService) {
    // @ts-ignore
    this.params = this.activatedRoute.snapshot.queryParams
  }

  params: {
    mode: "resetPassword" | "recoverEmail" | "verifyEmail",
    oobCode: string,
    apiKey: string,
    continueUrl?: string,
    lang: string
  }

  form: UntypedFormGroup;
  expiredOrUsed: boolean = null
  loading = true
  formLoading = false

  mail:string
  serverError: string

  ngOnInit(): void {
    console.log(this.params);
    // this.afAuth.checkActionCode(this.params.oobCode).then(console.log)
    if (this.params.mode == "resetPassword") {
      this.afAuth.verifyPasswordResetCode(this.params.oobCode)
      .then((mail) => {
        this.expiredOrUsed = false
        this.mail = mail
      })
      .catch(() => this.expiredOrUsed = true)
      .finally(() => this.loading = false)
    } else if (this.params.mode == "recoverEmail") {
      this.handleRecoverEmail()
    } else if (this.params.mode == "verifyEmail") {
      this.handleVerifyEmail()
    } else {
      this.loading = false
    }

    this.form = this.fb.group({
        password: ['', [
          Validators.minLength(8), 
          Validators.required,
        ]]
    });
  }

  handleResetPassword() {
    this.formLoading = true
    const pass = this.form.get('password').value
    console.log(pass);
    this.afAuth.confirmPasswordReset(this.params.oobCode, pass)
    .then(() => {
      this.core.snackBarNotification("Ο κωδικός σας άλλαξε επιτυχώς!")
      this.auth.login(this.mail, pass)
    })
    .catch(() => this.serverError = "Υπήρξε κάποιο πρόβλημα, δοκιμάστε να ανανεώσετε την σελίδα.")
  }

  // Recover form email change that was not initialized by the user
  handleRecoverEmail() {
    // check code
    this.afAuth.checkActionCode(this.params.oobCode)
    .then((info) => {
      this.mail = info.data.email
      // revert account to old email
      return this.afAuth.applyActionCode(this.params.oobCode)
    })
    .then(() => {
      // Account email reverted to restoredEmail, display confirmation message
      this.expiredOrUsed = false
    })
    .catch(() => this.expiredOrUsed = true)
    .finally(() => this.loading = false)
  }

  disabledButton = false
  changePass() {
    this.disabledButton = true
    this.afAuth.sendPasswordResetEmail(this.mail)
    .then(() => this.core.snackBarNotification("Το email αλλαγής κωδικού, σας έχει αποσταλεί. Ελέγξτε το ηλεκτρονικό σας ταχυδρομείο.", undefined, 10000))
    .catch(() => this.serverError = "Υπήρξε κάποιο πρόβλημα, δοκιμάστε αργότερα.")
  }

  handleVerifyEmail() {
    this.afAuth.applyActionCode(this.params.oobCode)
    .then(() => {
      this.expiredOrUsed = false
      this.auth.updateFirebaseUser() // update authState when email is verified to update profile page live
    })
    .catch(() => this.expiredOrUsed = true)
    .finally(() => this.loading = false)
  }

}

<header id="header" class="toolbar">
    <button mat-button mat-icon-button (click)="location.back()">
        <mat-icon *ngIf="request != 'activity'" id="back">arrow_back_ios</mat-icon>
    </button>
    <h3>{{apiClient.pages[request]}}</h3>
    <button mat-button mat-icon-button disableRipple="true"></button>
</header>
<!-- 
<ng-template #guest>
  <button *ngIf="!['/login', '/register'].includes(url)" mat-button [disableRipple]="true" routerLink='/login'>
    <span class="gradient-text">Είσοδος</span>
  </button>
</ng-template> -->

<div class="header-placeholder"></div>

<!-- <section *ngIf="self && self.status == 'verified'"> -->
<ng-container *ngIf="auth.authState | async as self">    
    <ng-container *ngIf="self.status == 'verified';else unverified">
        <section>
            
            <div class="empty" *ngIf="response && !response.length && !loading">
                <mat-icon class="material-icons-outlined">format_list_bulleted_outlined</mat-icon>
                <p>
                    Δεν υπάρχει κάτι εδώ,
                    <br>προς το παρόν.
                </p>
            </div>

            <!-- Comments -->
            <div *ngIf="request == 'comments' && response.length" class="list" [style.min-height.px]="core.windowHeight - 150"
                infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                [infiniteScrollDisabled]="scrollDisabled" (scrolled)="onScroll()">
                <!-- <div class="background-blur" [style.background-image]="'url(' + params.postRef + ')'"></div> -->
                <div *ngFor="let res of response; let i = index">
                    <div class="item" [ngClass]="request" [class.active-comment]="replyingTo && replyingTo.ref == res.ref" [id]="res.ref"
                        [style.align-items]="request == 'comments' ? 'start' : 'center'">
                        <!-- AVATAR -->
                        <div class="pointer" [ngClass]="['avatar', 'avatar-36', 'sex-' + res.author.profile.sex]">
                            <img [src]="apiClient.userAvatar(res.author.username, res.author.profile.avatar, 90)" 
                            [routerLink]="['/@' + res.author.username]" (error)="core.onImgError($event)">
                        </div>
                        <div class="content">
                            <!-- Username -->
                            <div class="username pointer" [routerLink]="['/@' + res.author.username]">
                                @{{res.author.username}}
                            </div>
                            <!-- Comment - full text -->
                            <div *ngIf="['comments', 'ats'].includes(request)" id="comment-text" hrefNavigation [innerHTML]="res.text | linkedText"></div>
                            <div *ngIf="request == 'comments'" class="options">
                                <div class="time pointer" [title]="res.created | date:'medium'">{{ res.created | timePassed:timeTrigger }}
                                </div>
                                <span class="pointer" [matMenuTriggerFor]="menu">επιλογές</span>
                                <mat-menu #menu="matMenu" class="pointer">
                                    <button *ngIf="res.author.self || res.post.author.self || auth.userObject.role == 'admin'" (click)="deleteComment(res.ref, i)"
                                        mat-menu-item>
                                        <mat-icon class="material-icons-outlined warn">delete_forever_outlined</mat-icon>
                                        <span>Διαγραφή</span>
                                    </button>
                                    <!-- <button *ngIf="!res.author.self" mat-menu-item>
                                        <mat-icon>block</mat-icon>
                                        <span>block</span>
                                    </button> -->
                                    <button *ngIf="!res.author.self" mat-menu-item class="warn"
                                        (click)="core.report('comment',res.ref)">
                                        <mat-icon class="material-icons-outlined">flag</mat-icon>
                                        <span>Αναφορά</span>
                                    </button>
                                </mat-menu>
                                <span class="pointer" (click)="reply(res)">απάντηση</span>
                            </div>
                        </div>
                        <!-- Comment like -->
                        <button mat-icon-button class="like" [style.visibility]="res.author.self ? 'hidden' : 'visible'" 
                            (click)="likeUnlike(res.ref, !res.liked, i)">
                            <mat-icon [class.loved]="res?.liked">{{res.liked ? "favorite" : "favorite_border"}}</mat-icon>
                        </button>
                    </div>
                    <!-- Nested comments -->
                    <div *ngIf="res.comments && res.comments.count" class="list nested">
                        <div class="item" *ngFor="let nested of res.comments.data; let n = index" [id]="nested.ref"
                        [ngClass]="request" [class.active-comment]="replyingTo && replyingTo.ref == nested.ref"
                        style="align-items: start;">
                            <div class="pointer" [ngClass]="['avatar', 'avatar-36', 'sex-' + nested.author.profile.sex]">
                                <img [src]="apiClient.userAvatar(nested.author.username, nested.author.profile.avatar, 90)" 
                                [routerLink]="['/@' + nested.author.username]" (error)="core.onImgError($event)">
                            </div>
                            <div class="content">
                                <!-- Username -->
                                <div class="username pointer" [routerLink]="['/@' + nested.author.username]">
                                    @{{nested.author.username}}
                                </div>
                                <!-- Comment - full text -->
                                <div *ngIf="['comments', 'ats'].includes(request)" id="comment-text" hrefNavigation [innerHTML]="nested.text | linkedText"></div>
                                <div *ngIf="request == 'comments'" class="options">
                                    <div class="time pointer" [title]="nested.created | date:'medium'">{{ nested.created | timePassed:timeTrigger }}
                                    </div>
                                    <span class="pointer" [matMenuTriggerFor]="menu">επιλογές</span>
                                    <mat-menu #menu="matMenu" class="pointer">
                                        <button *ngIf="nested.author.self || nested.post.author.self || auth.userObject.role == 'admin'" (click)="deleteComment(nested.ref, i, n)"
                                            mat-menu-item>
                                            <mat-icon class="material-icons-outlined">delete_forever_outlined</mat-icon>
                                            <span>Διαγραφή</span>
                                        </button>
                                        <!-- <button *ngIf="!nested.author.self" mat-menu-item>
                                            <mat-icon>block</mat-icon>
                                            <span>block</span>
                                        </button> -->
                                        <button *ngIf="!nested.author.self" mat-menu-item class="warn"
                                            (click)="core.report('comment', nested.ref)">
                                            <mat-icon class="material-icons-outlined">flag</mat-icon>
                                            <span>Αναφορά</span>
                                        </button>
                                    </mat-menu>
                                    <span class="pointer" (click)="reply(nested)">απάντηση</span>
                                </div>
                            </div>
                            <!-- Comment Like -->
                            <button mat-icon-button class="like" [style.visibility]="nested.author.self ? 'hidden' : 'visible'" 
                                (click)="likeUnlike(nested.ref, !nested.liked, i, n)">
                                <mat-icon [class.loved]="nested?.liked">{{nested.liked ? "favorite" : "favorite_border"}}</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div class="replying-to" *ngIf="replyingTo">
                    <span>Απάντηση στον/στην @{{replyingTo.author.username}}</span>
                    <button mat-button mat-icon-button (click)="cancelReply()">
                        <mat-icon id="back">close</mat-icon>
                    </button>
                </div>
                <div *ngIf="request == 'comments' || replyingTo" class="comment-container">
                    <div [ngClass]="['avatar', 'avatar-30', 'sex-' + self.sex]">
                        <img [src]="apiClient.userAvatar(self.username, self.avatar, 90)" 
                        [routerLink]="['/@' + self.username]" (error)="core.onImgError($event)">
                    </div>
                    <form (submit)="comment()">
                        <mat-form-field floatLabel='never' class="comment-input toolbar">
                            <textarea matInput #commentInput type="text" name="commentText" [(ngModel)]="commentText" 
                                placeholder="Σχολιάστε..." [mentionConfig]="mentionConfig" (searchTerm)="search($event)"
                                autocomplete="off" (input)="lineBreakLimits($event)" [mentionListTemplate]="mentionTemplate">
                            </textarea>
                            <!-- <textarea matInput cdkTextareaAutosize #commentInput rows="0"
                            cdkAutosizeMinRows="0" cdkAutosizeMaxRows="3">
                            </textarea> -->
                            <button mat-button [color]="commentText ? 'accent' : 'base'" [disabled]="!commentText || uploadingComment" matSuffix
                                mat-icon-button aria-label="Send" type="submit">
                                <mat-icon style="font-size: 20px">send</mat-icon>
                            </button>
                        </mat-form-field>
                    </form>
                </div>
                <!-- <div *ngFor="let item of httpItems | async">{{item.username}}</div> -->
            </footer>

            <!-- Follows, Likes and Blocked (User list) -->
            <div *ngIf="['followers', 'following', 'likes', 'blocked'].includes(request) && response.length" class="list">
                <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                [infiniteScrollDisabled]="scrollDisabled" (scrolled)="onScroll()" [style.min-height.px]="core.windowHeight - 150">
                    <div class="item pointer" [ngClass]="request" id="item-{{i}}" *ngFor="let res of response; let i = index"
                        [style.align-items]="request == 'comments' ? 'start' : 'center'">
                        <!-- AVATAR -->
                        <div class="item-container">
                            <div [ngClass]="['avatar', 'avatar-44', 'sex-' + res.user.profile.sex]">
                                <img [src]="apiClient.userAvatar(res.user.username, res.user.profile.avatar, 90)" 
                                [routerLink]="['/@' + res.user.username]" (error)="core.onImgError($event)">
                            </div>
                            <div class="content">
                                <!-- Username -->
                                <h3 class="username" [routerLink]="['/@' + res.user.username]">
                                    @{{res.user.username}}
                                </h3>
                            </div>
                        </div>
                        <div *ngIf="!res.user.self" class="follow-button">
                            <button *ngIf="!(res.user.followingThem)" mat-flat-button color="accent" [disabled] = "request == 'blocked'"
                            (click)="followUnfollow(res.user.username, i)">{{res.user.followingMe ? "Ακολ/\στε Πίσω" : "Ακολουθήστε"}}</button>
                            <button *ngIf="res.user.followingThem" mat-stroked-button color="accent" [disabled] = "request == 'blocked'"
                            (click)="followUnfollow(res.user.username, i)">{{res.user.followingMe ? "Φίλοι!" : "Ακολουθείτε"}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Activity -->
            <div *ngIf="request == 'activity' && response.length" class="list" infiniteScroll [infiniteScrollDistance]="2" [style.min-height.px]="core.windowHeight - 150"
            [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="scrollDisabled" [immediateCheck]="true"
            (scrolled)="onScroll()" >
                <div class="item pointer" [ngClass]="request" id="item-{{i}}" *ngFor="let res of response; let i = index"
                    [style.align-items]="request == 'comments' ? 'start' : 'center'">
                        <!-- AVATAR -->
                        <div class="item-container" [class.active-comment]="(res.action.includes('comment') || res.action.includes('reply') || res.action.includes('mention')) && replyingTo && replyingTo.ref == res.comment.ref">
                            <div [ngClass]="['avatar', 'avatar-44', 'sex-' + res.user.profile.sex]">
                                <img [src]="apiClient.userAvatar(res.user.username, res.user.profile.avatar, 90)" 
                                [routerLink]="['/@' + res.user.username]" (error)="core.onImgError($event)">
                            </div>
                            <div class="content">
                                <!-- Username -->
                                <div class="username">
                                    <span class="subtitle" hrefNavigation [innerHTML]="wording(res.user.username, res.action) | linkedText:'no-decoration'" 
                                    (click)="res.action == 'like' ? router.navigate(['/p/', res.post.ref, 'likes']) : null"></span>
                                    <span *ngIf="res.action == 'like'" class="time" style="opacity: 0.6;" [title]="res.date | date:'medium'">&nbsp; {{ res.date | timePassed:timeTrigger }}</span>
                                </div>
                                <!-- Activity - comment / comment mention / reply / commentLike-->
                                <div *ngIf="['comment', 'mention', 'reply', 'commentLike'].includes(res.action) && res.comment" class="subtitle" >
                                    <span *ngIf="res.comment.text" [ngClass]="{'replied-to': res.action == 'commentLike'}" [routerLink]="['/p/' + res.post?.ref + '/comments']" [fragment]="res.comment.ref">
                                        {{ res.action == 'commentLike' ? "|" : "" }} {{ res.comment.text }}
                                    </span>
                                    <div *ngIf="res.comment && res.comment.replyTo && res.comment.replyTo.author.self" 
                                        class="replied-to" [routerLink]="['/p/' + res.post?.ref + '/comments']" [fragment]="res.comment.ref">
                                        │ {{ res.comment.replyTo.text }}
                                    </div>
                                    <div *ngIf="res.action != 'commentLike';else comment_like" class="options">
                                        <div class="time" [title]="res.comment.created | date:'medium'">{{ res.comment.created | timePassed:timeTrigger }}</div>
                                        <span class="like" (click)="likeUnlike(res.comment.ref, !res.comment.liked, i, null, true)">
                                            <mat-icon [class.loved]="res?.comment.liked">{{res.comment.liked ? "favorite" : "favorite_border"}}</mat-icon>
                                        </span>
                                        <span (click)="reply(res.comment, i)">απάντηση</span>
                                    </div>

                                    <!-- Comment Like options-->
                                    <ng-template #comment_like>
                                        <div class="options">
                                            <div class="time" [title]="res.date | date:'medium'">{{ res.date | timePassed:timeTrigger }}</div>
                                        </div>
                                    </ng-template>
                                    
                                </div>
                                <!-- Activity - mention post (button is below)-->
                                <div *ngIf="res.action == 'postMention'" class="subtitle" [routerLink]="['/p/' + res.post?.ref]">
                                    <span>{{ res.post.title }}</span>
                                    <div class="options">
                                        <div class="time" [title]="res.post?.created | date:'medium'">{{ res.post.created | timePassed:timeTrigger }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- Activity feed - post image preview -->
                            <div *ngIf="res.action != 'follow'" class="right-col">
                                <img class="photo" [src]="apiClient.refToImage(res.post?.ref, 90, res.post?.token)"
                                    [routerLink]="['/p/' + res.post?.ref]" (error)="core.onImgError($event)">
                            </div>
                            <!-- <ng-container *ngTemplateOutlet="follow-buttons; context: {res: res}"></ng-container> -->
                            <div *ngIf="res.action == 'follow' && !res.user.self" class="follow-button right-col">
                                <button *ngIf="!(res.user.followingThem)" mat-flat-button color="accent"
                                (click)="followUnfollow(res.user.username, i)">{{res.user.followingMe ? "Ακολ/\στε Πίσω" : "Ακολουθήστε"}}</button>
                                <button *ngIf="res.user.followingThem" mat-stroked-button color="accent"
                                (click)="followUnfollow(res.user.username, i)">{{res.user.followingMe ? "Φίλοι!" : "Ακολουθείτε"}}</button>
                            </div>
                        </div>
                        <!-- Activity - mention post BUTTON -->
                        <button style="margin-top: 5px;" *ngIf="res.action == 'postMention' && res.post.mentionsPending.includes(auth.userObject.username)" mat-stroked-button [routerLink]="['/p/' + res.post?.ref]">
                            <span class="gradient-text">Εμφάνιση/απόκρυψη απο το προφίλ σας</span>
                        </button>

                        <!-- Activity - comment Reply -->
                        <div *ngIf="res.comment && res.comment.comments as nested" class="item-container nested reply" [class.active-comment]="activeComment == nested.data.ref" 
                        [id]="nested.data.ref" [routerLink]="['/p/' + res.post.ref + '/comments']" [fragment]="nested.data.ref">
                            <div [ngClass]="['avatar', 'avatar-30', 'sex-' + res.comment.comments.data.author.profile.sex]">
                                <img [src]="apiClient.userAvatar(res.comment.comments.data.author.username, res.comment.comments.data.author.profile.avatar, 90)" 
                                [routerLink]="['/@' + res.comment.comments.data.author.username]" (error)="core.onImgError($event)">
                            </div>
                            <div class="content">
                                <div class="subtitle">
                                    <span>{{nested.data.text}}</span>
                                    <div class="options">
                                        <div class="time" [title]="nested.data.created | date:'medium'">{{ nested.data.created | timePassed:timeTrigger }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>



            <ng-template #mentionTemplate let-item="item">
                <div *ngIf="item.username">
                    <div [ngClass]="['avatar avatar-26', 'sex-' + item.profile.sex]">
                    <img [src]="apiClient.userAvatar(item.username, item.avatar, 90)">
                    </div>
                    &nbsp;
                    <span>@{{ item.username }}</span>
                </div>
                <div *ngIf="item.text">
                <div class="avatar avatar-26 hashtag-avatar">
                    <img class="avatar-svg" src="./assets/hashtag.svg">
                </div>
                &nbsp;
                <div class="flex-apart">
                    <span>#{{ item.text }}</span>
                    <span class="mention-tag-count">{{ item.count | number: '1.' }}</span>
                </div>
                </div>
            </ng-template>
        </section>
    </ng-container>
    
</ng-container>

<ng-template #unverified>
    <app-guest-page></app-guest-page>
</ng-template>

<mat-spinner *ngIf="loading" color="accent" [diameter]="30"></mat-spinner>

<div class="footer-placeholder"></div>

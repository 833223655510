import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core.service';
import { Subscription, bindCallback, of, Observable } from 'rxjs';
import { Location } from '@angular/common'
import { authUser, User } from 'src/app/interfaces/interfaces';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { ApiClient } from 'src/app/services/api-client.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  activeLinkIndex = 0;
  activity$: Subscription
  newActivity: number = 0

  user: authUser = null

  keyboardOpen = false

  url = ''
  defaultPage = {
    title: null,
    backBtn: false,
    topBarDisplay: 'initial',
    topBarHidden: 'visible',
    navDisplay: true
  }
  page = this.defaultPage

  pageControls: Object = {
    '/': {
      title: null,
      backBtn: false,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: true,
      adminTools: true
    },
    '/search': {
      title: "Αναζήτηση",
      backBtn: false,
      topBarDisplay: 'initial',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    '/search/query': {
      title: "Αναζήτηση",
      backBtn: false,
      topBarDisplay: 'initial',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    '/search/results': {
      title: "Αποτελέσματα",
      backBtn: false,
      topBarDisplay: 'none',
      topBarHidden: 'hidden',
      navDisplay: false
    },
    '/new-post': {
      title: "Νέα Ανάρτηση",
      backBtn: false,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: true
    },
    '/invitations': {
      title: "Προσκλήσεις",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: true
    },
    '/qr': {
      title: "Προσκλήσεις",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'hidden',
      navDisplay: false
    },
    '/blocked': {
      title: "Μπροκαρισμένα μέλη",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    '/activity': {
      title: "Δραστηριότητα",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    '/login': {
      title: "Είσοδος",
      backBtn: false,
      topBarDisplay: 'initial',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    '/register': {
      title: "Εγγραφή",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'hidden',
      navDisplay: false
    },
    '/edit': {
      title: "edit",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    '/pwa': {
      title: "PWA status",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: true
    },
    '/user-requests': {
      title: "User Requests",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: true
    },
    '/reports': {
      title: "Reports",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: true
    },
    // PROFILE
    '/profile': {
      title: "profile",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'hidden',
      navDisplay: true
    },
    // SINGLE POST / timeline?
    '/p/': {
      title: "post",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'visible',
      navDisplay: true
    },
    'comments': {
      title: "Σχόλια",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'visible',
      navDisplay: false
    },
    'follows': {
      title: "Ακόλουθοι",
      backBtn: true,
      topBarDisplay: 'none',
      topBarHidden: 'visible',
      navDisplay: false
    },
    'change-pass': {
      title: "Αλλαγή κωδικού πρόσβασης",
      backBtn: true,
      topBarDisplay: 'initial',
      topBarHidden: 'visible',
      navDisplay: false
    },

  }

  constructor(
    public core: CoreService, public apiClient:ApiClient, public auth:AuthenticationService,
    public location: Location, private router: Router, public dialog: MatDialog) {
    // monitor urls
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.url = event.url;
        this.getPage(this.url)
        // console.log(this.url);
        // console.log(event.state.root.firstChild.params); // can also access "fragment" (#) instead of "params"
      }
    })

    // Listen to authState user (for activity counts & avatar)
    this.activity$ = this.auth.authState
    .subscribe(user => {
      this.user = user
      console.log(user);
    })
  }

  ngOnInit(): void {
    // Show/hide bottom nav bar
    this.core.navDisplayState
    .subscribe(navDisplayState => {
      this.page.navDisplay = navDisplayState
    })

    // Show firstVisit dialog
    if(localStorage.getItem('firstVisitDone') != 'true' && !localStorage.getItem('user')) {
      setTimeout(() => {
        this.firstVisitDialog()
      }, 1000);
    }

    // // Listen for virtual keyboard
    // window.addEventListener('resize', (e) => {
    //   console.log(window.innerHeight, window.visualViewport.height);
    //   const heighDifference = window.innerHeight - window.visualViewport.height
    //   // If window height is at least 200px bigger than the viewport height, keyboard is open
    //   if (heighDifference > 200) {
    //     console.log('keyboard open');
    //     window.scrollBy({
    //       top: -heighDifference,
    //       behavior: "smooth",
    //     });
    //     this.keyboardOpen = true
    //   }
    //   else {
    //     console.log('keyboard closed');
    //     this.keyboardOpen = false
    //   }
    // })
  }

  getPage(url: string) {
     // {...} creates a copy of the value so the original "pageControls" object is not updated
    if (Object.keys(this.pageControls).includes(url)) {
      this.page = {...this.pageControls[url]}
    }
    else if (url.includes("comments")) {
      this.page = this.pageControls['comments']
    }
    else if (url.includes("/follow") || url.includes("/likes")) {
      this.page = this.pageControls['follows']
    }
    else if (url.includes("/p/")) {
      this.page = this.pageControls['/p/']
    }
    else if (url.includes("change-pass")) {
      this.page = this.pageControls['change-pass']
    }
    else if (url.includes("/register")) {
      this.page = this.pageControls['/register']
    }
    else if (url.includes("/invitations")) {
      this.page = this.pageControls['/invitations']
    }
    else if (url.includes("/qr")) {
      this.page = this.pageControls['/qr']
    }
    else if (url.includes("/blocked")) {
      this.page = this.pageControls['/blocked']
    }
    else if (['#top', '#feed', '#fresh', '#private'].some(v => url.includes(v))) {
      this.page = this.pageControls['/']
    }
    else {
      this.page = this.pageControls['/profile']
    }
    // console.log("Navbar:", this.page.title); // if you don't use this.defaultPage - remove it and point it to this.page
  }

  test() {
    console.log(this.pageControls);
    
  }

  // When clicking "HOME" button from home url '/', scroll to top
  scrollToTop(path) {
    if (this.url == path && path == '/') {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
  }

  firstVisitDialog() {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { type: 'firstVisit' },
        backdropClass: 'darkBackdrop',
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(result => {
        localStorage.setItem('firstVisitDone', 'true');
        resolve(result)
      });
    })
  }

  activityVisit() {
    this.core.reloadCurrentRoute("/activity")
    this.user.activitySubjectCountSince = 0
  }

  ngOnDestroy(): void {
    this.activity$.unsubscribe()
  }
}